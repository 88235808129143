import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid, IconButton, Tooltip } from "@component/UIComponents"
import { useInventoryTurnoverRate } from "./hooks/useInventoryTurnoverRate"
import { currencyFormat } from "@util/currencyFormat"
import Img from "@component/Img"
import CompareIcon from '@style/images/compare.png'
import CompareProductByDates from "./components/CompareProductByDates"
import useQueryParams from "@hook/useQueryParams"
import Filters from "./components/Filters"
import ComponentTitle from "@component/ComponentTitle"

const InventoryTurnoverRate = () => {
    const { TurnoverRate, isLoadingTurnoverRate=false } = useInventoryTurnoverRate()
    const { setQueryParams } = useQueryParams()
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Tasa de rotacion" />
            <Grid container >
                <Grid xs={12} >
                    <SuperDataGrid
                        toolbar={() => <Filters />}
                        loading={isLoadingTurnoverRate}
                        overlayText={'Estamos calculando la tasa de rotación del inventario, este proceso puede demorar unos segundos.'}
                        filterBy={(param) => `${(param?.name??"")}`}
                        columns={[
                            {
                                minWidth: 250,
                                field: "name",
                                headerName: "Producto",
                                renderCell: ({ row }) => {
                                    const { name="" } = (row??{})
                                    const value = name
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "InitialStock",
                                headerName: "Inv. Inicial",
                                renderCell: ({ row }) => {
                                    const { InitialStock=0 } = (row??{})
                                    const value = currencyFormat(InitialStock,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "TotalUnitsPurchased",
                                headerName: "Compras",
                                renderCell: ({ row }) => {
                                    const { TotalUnitsPurchased=0 } = (row??{})
                                    const value = currencyFormat(TotalUnitsPurchased,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "_1",
                                headerName: "Total inicial",
                                renderCell: ({ row }) => {
                                    const { TotalUnitsPurchased=0, InitialStock=0 } = (row??{})
                                    const value = currencyFormat((TotalUnitsPurchased+InitialStock),{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "TotalQuantitySold",
                                headerName: "Venta",
                                renderCell: ({ row }) => {
                                    const { TotalQuantitySold=0 } = (row??{})
                                    const value = currencyFormat(TotalQuantitySold,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "EndStock",
                                headerName: "Queda",
                                renderCell: ({ row }) => {
                                    const { EndStock=0 } = (row??{})
                                    const value = currencyFormat(EndStock,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "SalesRate",
                                headerName: "% Venta",
                                renderCell: ({ row }) => {
                                    const { SalesRate=0 } = (row??{})
                                    const value = `${SalesRate}%`
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "RetentionRate",
                                headerName: "% Queda",
                                renderCell: ({ row }) => {
                                    const { RetentionRate=0 } = (row??{})
                                    const value = `${RetentionRate}%`
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 50,
                                maxWidth: 50,
                                field: "__options__",
                                headerName: " ",
                                renderCell: ({ row }) => {
                                    const { _id=null, name="" } = (row??{})
                                    return <>
                                        <IconButton size="small" color="primary" onClick={() => setQueryParams({ ProductID: _id, name })} >
                                            <Tooltip arrow title="Comparar con otras fechas" >
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                                    <Img src={CompareIcon} style={{ width: 25 }} />
                                                </Box>
                                            </Tooltip>
                                        </IconButton>
                                    </>
                                }
                            },
                        ]}
                        rows={TurnoverRate}
                    />
                </Grid>
            </Grid>
            <CompareProductByDates />
        </>
    )
}

export default InventoryTurnoverRate