import Form from '@component/Form'
import NumericFormat from '@component/NumericFormat'
import PatternFormat from '@component/PatternFormat'
import { Box, CloseRoundedIcon, Button, Dialog, DialogActions, DialogContent, FormHelperText, Grid, InputMask, TextField } from '@component/UIComponents'
import CitiesFinder from '@feature/Business/components/CitiesFinder'
import { useCustomerFormData, useRegisterCustomer } from './hooks/useCustomerForm'
import { useEffect } from 'react'
import BusinessTypeFinder from '../components/BusinessTypeFinder'
import BranchFinder from '../components/BranchFinder'
import NeighborhoodFinder from '../components/NeighborhoodFinder'
import ZoneFinder from '../components/ZoneFinder'
import { Text } from '@component/Title'
import ActionInformation from '@component/ActionInformation'
import BusinessCodeValid from '@component/BusinessCodeValid'
import CustomerTypeFinder from '../components/CustomerTypeFinder'

const CustomerForm = ({ open=false, onClose=()=>null, defaultValues=undefined, actionWhenClosingFormWithSuccessfulRegistration=()=>null }) => {
    const { values, setFieldValue, validateValues, resetValues } = useCustomerFormData({ defaultValues })
    const { businessCode="", address="", branch=null, businessName="", businessType=null, city=null, identificationNumber="", name="", neighborhood=null, phoneNumber="", zone=null, customerType=null, daysOfAbsence=0, purchasePeriod="", email="" } = values
    const { isLoading, registerCustomer } = useRegisterCustomer()

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        registerCustomer(values, {
            clearForm: () => resetValues(),
            closeForm: (newCustomer) => {
                actionWhenClosingFormWithSuccessfulRegistration(newCustomer)
                onClose()
            }
        })
    }

    useEffect(() => {
        if( !Boolean(open) ){
            resetValues()
        }
    }, [open, resetValues])

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} transitionDuration={0} fullWidth open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Text variant='form-title' >
                            { Boolean(defaultValues?._id) ?
                                `Editar cliente ${(defaultValues?.name??"")}`
                            :
                                "Crear cliente"
                            }
                        </Text>
                        <Button
                        onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField value={(name??"")} fullWidth label="Nombre" onChange={({ target }) => setFieldValue("name", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(businessName??"")} fullWidth label="Razón social" onChange={({ target }) => setFieldValue("businessName", (target?.value??""))} />
                            </Grid>
                            <Grid xs={12} >
                                <NumericFormat suffix="" prefix="" thousandSeparator="" value={(identificationNumber??"")} fullWidth label="Numero de identificación" onChange={({ floatValue }) => setFieldValue("identificationNumber", (floatValue??0))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField type='email' value={(email??"")} fullWidth label="Correo electrónico" onChange={({ target }) => setFieldValue("email", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <PatternFormat value={(phoneNumber??"")} format='+57 ### ### ####' fullWidth label="Numero de teléfono" onChange={(value) => setFieldValue("phoneNumber", (value??""))} />
                            </Grid>
                            <Grid xs={4} >
                                <CustomerTypeFinder value={(customerType??null)} fullWidth onChange={(_, params) => setFieldValue("customerType", (params??null))} />
                            </Grid>
                            <Grid xs={4} >
                                <BusinessTypeFinder value={(businessType??null)} fullWidth onChange={(_, params) => setFieldValue("businessType", (params??null))} />
                            </Grid>
                            <Grid xs={4} >
                                <BranchFinder value={(branch??null)} fullWidth onChange={(_, params) => setFieldValue("branch", (params??null))} />
                            </Grid>
                            <Grid xs={4} >
                                <CitiesFinder value={(city??null)} fullWidth onChange={(_, params) => setFieldValue("city", (params??null))} />
                            </Grid>
                            <Grid xs={4} >
                                <NeighborhoodFinder value={(neighborhood??null)} fullWidth onChange={(_, params) => setFieldValue("neighborhood", (params??null))} />
                            </Grid>
                            <Grid xs={4} >
                                <ZoneFinder value={(zone??null)} fullWidth onChange={(_, params) => setFieldValue("zone", (params??null))} />
                            </Grid>
                            <Grid xs={12} >
                                <TextField multiline rows={2} value={(address??null)} fullWidth label="Dirección" onChange={({ target }) => setFieldValue("address", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat disabled suffix="" prefix="" thousandSeparator=" " value={(daysOfAbsence??"")} fullWidth label="Dias de ausencia" />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat automaticErase suffix="" prefix="" thousandSeparator=" " value={(purchasePeriod??"")} fullWidth label="Periodo de compra" onChange={({ floatValue }) => setFieldValue("purchasePeriod", (floatValue??0))} />
                            </Grid>
                            <Grid xs={12} >
                                <FormHelperText> Ajustes en la app: </FormHelperText>
                            </Grid>
                            <Grid xs={12} >
                                <Box display={'flex'} alignItems={'center'} gap={2} >
                                    <InputMask mask={"*** - ***"} maskChar="#" value={businessCode} onChange={({ currentTarget }) => setFieldValue("businessCode",(currentTarget?.value??"").replace(/[^\w\d]+/g, ''))} >
                                        {(inputProps) => (
                                            <TextField
                                                fullWidth
                                                {...inputProps}
                                                label="Código del negocio"
                                                inputProps={{
                                                    style: {
                                                        fontWeight: "bold"
                                                    }
                                                }}
                                            />
                                        )}
                                    </InputMask>
                                    <BusinessCodeValid businessCode={businessCode} />
                                    <ActionInformation
                                        useAdornment={false}
                                        information={`Código del negocio: se encuentra en el menu del usuario y es un código de 6 dígitos con formato alfanumérico que identifica a cada negocio en la aplicación, si este cliente hace uso del sistema podrá compartir su código y usted lo podrá ingresar en este campo, de esta forma podrá compartir sus facturas de ventas directamente en el sistema con este cliente. (Las facturas de ventas que se podrán compartir serán solo las que se le facturen a este cliente)`}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} > Guardar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default CustomerForm