import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid } from "@component/UIComponents"
import { useGetCreditNotesQuery } from "@query/Sales"
import { currencyFormat } from "@util/currencyFormat"
import { useMemo } from "react"
import TotalizeReport from "./components/TotalizeReport"
import ViewCreditNotes from "./components/ViewCreditNotes"

const CreditNotes = () => {
    const { data } = useGetCreditNotesQuery()
    const creditNotes = useMemo(() => (data?.payload??[]), [data])
    const totalizeReport = useMemo(() => creditNotes.reduce((acc, item) => (acc+(item?.total??0)), 0) , [creditNotes])

    return(
        <Box mt={1} >
            <Grid container spacing={2} >
                <Grid xs={12} >
                    <TotalizeReport value={totalizeReport} />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        columns={[
                            { flex: 1, maxWidth: 300, field: "name", headerName: "Cliente", renderCell: ({ row }) => `${(row?.name??"VARIOS")}` },
                            { minWidth: 120, maxWidth: 120, field: "total", headerName: "Total", renderCell: ({ row }) => `${currencyFormat((row?.total??0))}` },
                            { minWidth: 120, maxWidth: 120, align: "center", field: "counts", headerName: "Cantidad", renderCell: ({ row }) => `${(row?.counts??0)}` },
                            {
                                minWidth: 60,
                                maxWidth: 60,
                                field: " ",
                                headerName: " ",
                                align: "center",
                                renderCell: ({ row }) => {
                                    return(
                                        <ViewCreditNotes creditNotes={(row?.results??[])} customerName={(row?.name??"VARIOS")} />
                                    )
                                }
                            },
                        ]}
                        rows={creditNotes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CreditNotes