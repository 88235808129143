import { Button } from '@component/UIComponents';

const TransformOptions = (optionsActions) => {
    const newOptions = optionsActions.map(item => ({
        button:
        
        <Button 
        startIcon={item.icon} 
        onClick={item.onClick} 
        sx={{color: 'black'}}
        >
            {item.title}
        </Button>
        
    }));
    return newOptions
};

export default TransformOptions;