const FormatPaymentMethod = (values={}) =>{
    const Observation = sessionStorage.getItem('observation')
    const payment = JSON.parse(sessionStorage.getItem('payment'))
    let {payments} = values
    const newValues = {
        ...values,
        payments: [...payments, payment],
        observation: Observation
    }
    sessionStorage.removeItem('observation');
    sessionStorage.removeItem('payment');
    return newValues
}

export default FormatPaymentMethod