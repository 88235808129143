import { DatePicker, Stack } from "@component/UIComponents"
import dayjs from "dayjs"

const FilterByDate = ({ InitialDate=null, FinalDate=null, onChange=()=>null }) => {
    return(
        <Stack direction={'row'} gap={1} justifyContent={'center'} alignItems={'center'} >
            <DatePicker
                label="Desde"
                disableFuture
                format="DD-MM-YYYY"
                onChange={(date) => onChange("InitialDate", dayjs(date).format("MM-DD-YYYY"))}
                slotProps={{
                    textField: {
                        size: "small"
                    }
                }}
                value={dayjs(InitialDate)}
            />
            <DatePicker
                label="Hasta"
                disableFuture
                format="DD-MM-YYYY"
                onChange={(date) => onChange("FinalDate", dayjs(date).format("MM-DD-YYYY"))}
                slotProps={{
                    textField: {
                        size: "small"
                    }
                }}
                value={dayjs(FinalDate)}
            />
        </Stack>
    )
}

export default FilterByDate