import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Tags } from './tags';
import app from '../app.conf.json' 

const environment = app.environment

export const domain = (environment === "production") ? "https://apiv3.facill.co" : (environment === "development") ? `http://192.168.1.78:3010` : "";
const prefix = `/api`;
export const endpoint = `${domain}${prefix}`;

export const mutationConfig = {
    url: '',
    method: 'POST',
    body: {},
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: endpoint,
        timeout: 60000,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('access_token');
            if (Boolean(token)) headers.set('Authorization', `token ${token}`);
            return headers;
        },
    }),
    tagTypes: Tags,
    endpoints: () => ({}),
});