import { Box } from '@component/UIComponents'
import dayjs from 'dayjs'
import DialogInvoice from '@feature/Reports/Invoice/components/DialogInvoice'
import { useState } from 'react'
import ListItems from './ListOfPendingInvoices/components/ListItems'
import { useGetMySalesPointOfSaleQuery } from '@query/billing'
import { useMemo } from 'react'
import { usePrintInvoice } from '../hooks/usePrintInvoice'

const MySalesToday = ({ isEditable }) => {
    const { data } = useGetMySalesPointOfSaleQuery({
        InitDate: dayjs().startOf("day").toDate(),
        EndDate: dayjs().endOf("day").toDate(),
    })
    const MySales = useMemo(() => (data?.payload??[]), [data])
    const [invoice, setInvoice] = useState(null)
    const { PrintInvoice } = usePrintInvoice()
    const maxHeightContainerList = useMemo(() => {
        if (isEditable === true) {
            return `calc(100vh - 490px)`
        } else {
            return `calc(100vh - 370px)`
        }
    }, [isEditable])

    return(
        <>
            <Box mt={1} >
                <ListItems
                    maxHeightContainerList={maxHeightContainerList}
                    list={MySales}
                    onSelectBill={(BillID) => setInvoice(BillID)}
                    onPrint={PrintInvoice}
                    hideButtons={{
                        pay: true,
                        show_bill: true,
                    }}
                />
            </Box>
            <DialogInvoice open={Boolean(invoice)} saleRef={invoice} onClose={() => setInvoice(null)} />
        </>
    )
}

export default MySalesToday