import useQueryParams from "@hook/useQueryParams"
import { useInventoryTurnoverRateQuery } from "@query/Products"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo } from "react"

export const useInventoryTurnoverRate = () => {
    const { getQueryParams, setQueryParams } = useQueryParams()
    const { InitialDate=null, FinalDate=null } = useMemo(() => getQueryParams(["InitialDate", "FinalDate"]), [getQueryParams])
    const DatesAreCorrect = useMemo(() => (dayjs(InitialDate).isValid() && dayjs(FinalDate).isValid()) ,[InitialDate, FinalDate])
    const { data, isFetching } = useInventoryTurnoverRateQuery({ InitialDate, FinalDate }, { skip: !DatesAreCorrect })
    const TurnoverRate = useMemo(() => (data?.payload??[]), [data])

    const loadInitialFilters = useCallback(() => {
        const isValidInitialDate = dayjs(InitialDate).isValid()
        const isValidFinalDate = dayjs(FinalDate).isValid()
        if( !isValidInitialDate ) setQueryParams({ InitialDate: dayjs().format("MM-DD-YYYY") })
        if( !isValidFinalDate ) setQueryParams({ FinalDate: dayjs().format("MM-DD-YYYY") })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InitialDate, FinalDate])

    useEffect(() => {
        loadInitialFilters()
    }, [loadInitialFilters])

    return {
        TurnoverRate,
        isLoadingTurnoverRate: isFetching
    }
}