import { Box, Grid, Stack } from "@component/UIComponents"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"
import { useCustomerControllerOptions } from "../hooks/useCustomerTrackingController"
import ActionsList from "@component/ActionsList"
import dayjs from "dayjs"
import SuperDataGrid from "@component/SuperDataGrid"

const CustomerWatchList = ({ list=[], dates=[], onShowCustomerProductPurchaseHistory=()=>null, onEditCustomer=()=>null, onShowCustomerPurchaseInvoices=()=>null }) => {
    const { getDateFormats } = usePanel()
    const { datagridRowOptionsMenu } = useCustomerControllerOptions()
    const columns = [
        {minWidth: 130, maxWidth: 130, align: "center", field: "daysOfAbsence", headerName: "Dias de ausencia"},
        {maxWidth: 150, minWidth: 150, field: "sale", headerName: "Venta", renderCell: ({row}) => `${currencyFormat((row?.sale??0))}`},
        {maxWidth: 150, minWidth: 150, field: "profit", headerName: "$", renderCell: ({row}) => `${currencyFormat((row?.profit??0))}`},
        {maxWidth: 100, minWidth: 100, field: "percentage", headerName: "%", renderCell: ({row}) => `${(row?.percentage??0)}%`},
        {flex: 1, minWidth: 200, field: "date", headerName: "Fecha", renderCell: ({row}) => `${getDateFormats(((((row?.results??[])[0]?.createdAt??"")))).fullDate}`},
    ]

    return(
        <>
            <Grid container >
                { list.map((customer, index) => {
                    return(
                        <Grid key={index} xs={12} >
                            <Stack direction={'row'} justifyContent={'space-between'} borderBottom={1} sx={{ borderColor: (theme) => theme.palette.grey[300] }} >
                                <Box fontWeight={'bold'} display={'flex'} alignItems={'center'} >
                                    <ActionsList
                                        options={datagridRowOptionsMenu({
                                            onShowCustomerProductPurchaseHistory: () => onShowCustomerProductPurchaseHistory(customer),
                                            onShowCustomerPurchaseHistory: ()=> null,
                                            onEditCustomer: ()=> onEditCustomer(customer),
                                        })}
                                        variant="mobileMenu"
                                    />
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <Box lineHeight={1} >
                                            {(customer?.customer??"")}
                                        </Box>
                                        <Box fontSize={'.875rem'} sx={{ color: (theme) => theme.palette.grey[600] }} >
                                            { ( (customer[(dates?.[0]??"")]?.results?.[0]?.customer?.businessType?.name??"") ) }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box fontWeight={'bold'} >
                                    {`${currencyFormat((customer?.sale??0))} | ${currencyFormat((customer?.profit??0))} | ${(customer?.percentage??0)}%`}
                                </Box>
                            </Stack>
                            <SuperDataGrid
                                useSearchBar={false}
                                columns={columns}
                                rows={Object.keys(customer).filter(n => dayjs(n, "DD-MM-YYYY").isValid() ).map((x, index) => ({...customer[x], _id: (index+1)})).reverse()}
                            />
                        </Grid>
                    )
                }) }
            </Grid>
        </>
    )
}

export default CustomerWatchList