import { Box, Button, ClearIconRounded, Dialog, DialogContent, IconButton } from '@component/UIComponents'

const PopupAlert1 = ({ text="", textButton="", open=false, onClose=()=>null, onClick=()=>null }) => {

    const handleOnClick = () => {
        onClick()
        onClose()
    }

    return(
        <>
            <Dialog transitionDuration={0} fullWidth maxWidth="xs" open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: (theme) => theme.spacing(6) }, elevation: 0 }} >
                <DialogContent>
                    <Box>
                        <IconButton color='error' size='small' onClick={onClose} >
                            <ClearIconRounded sx={{ fontSize: "1.8rem" }} />
                        </IconButton>
                    </Box>
                    <Box textAlign={'center'} fontWeight={'400'} fontSize={'3rem'} mb={4} >
                        {text}
                    </Box>
                    <Box textAlign={'center'} mb={3} >
                        <Button variant='contained' onClick={handleOnClick} sx={{
                            borderRadius: (theme) => theme.spacing(4),
                            paddingX: (theme) => theme.spacing(4),
                            fontSize: "1.5rem",
                            bgcolor: "#D60000",
                            color: "#FFFFFF",
                            fontWeight: "400",
                            '&:hover': {
                                bgcolor: "#D60000",
                                color: "#FFFFFF",
                            }
                        }} disableElevation >
                            {textButton}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PopupAlert1