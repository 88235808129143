import { Box, Card, CardContent, Divider, IconButton, LocalAtmRoundedIcon, Popover, Stack, Tooltip } from "@component/UIComponents"
import { VisibilityRounded } from "@mui/icons-material"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useState } from "react"
import { useCreditNoteActions } from "./hooks/useCreditNoteActions"

const ViewCreditNotes = ({ creditNotes=[], customerName="" }) => {
    const { ReturnCreditNoteMoneyInCash } = useCreditNoteActions()
    const [anchorEl, setAnchorEl] = useState(null)
    return (
        <>
            <IconButton size="small" color="primary" onClick={(event) => setAnchorEl(event.currentTarget)} >
                <Tooltip title="Ver notas crédito" arrow placement="right" >
                    <VisibilityRounded sx={{ fontSize: '1.3rem' }} />
                </Tooltip>
            </IconButton>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
                slotProps={{
                    paper:{
                        elevation: 0,
                        variant: "outlined"
                    }
                }}
                anchorOrigin={{
                    vertical: 'right',
                    horizontal: 'right',
                }}
            >
                <Box px={2} py={1} >
                    <Box fontSize={'.875rem'} fontWeight={'bold'} mb={1} >
                        {customerName}
                        <Divider />
                    </Box>
                    <Box sx={{ maxHeight: 410, overflowY: "scroll", pr: 1 }} >
                        <Stack gap={1} >
                            { creditNotes.map((creditNote, index) => {
                                return(
                                    <Box key={index} minWidth={350} >
                                        <Card variant="outlined" >
                                            <CardContent sx={{ padding: ({ spacing }) => `${spacing(1)} !important` }} >
                                                <Stack sx={{
                                                    fontSize: ".875rem",
                                                    fontWeight: "bold",
                                                    lineHeight: "1.5",
                                                }} >
                                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                                        <Box>
                                                            #FAC {(creditNote?.invoiceId?.consecutive??0)}
                                                        </Box>
                                                        <Box>
                                                            { currencyFormat((creditNote?.total??0)) }
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                                        <Box>
                                                            Saldo
                                                        </Box>
                                                        <Box>
                                                            { currencyFormat((creditNote?.balance??0)) }
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                                        <Box>
                                                            Usado
                                                        </Box>
                                                        <Box>
                                                            { currencyFormat((creditNote?.total??0)-(creditNote?.balance??0)) }
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                                        <Box>
                                                            {(creditNote?.user?.name??"")} {(creditNote?.user?.lastName??"")}
                                                        </Box>
                                                        <Box>
                                                            { dayjs((creditNote?.createdAt??null)).format("DD-MM-YYYY HH:mm A") }
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'center'} gap={.5} >
                                                        <IconButton disabled={((creditNote?.balance??0) <= 0)} size="small" color="primary" onClick={() => ReturnCreditNoteMoneyInCash((creditNote?._id))} >
                                                            <Tooltip title="Devolver en efectivo" arrow >
                                                                <LocalAtmRoundedIcon sx={{ fontSize: '1.2rem' }} />
                                                            </Tooltip>
                                                        </IconButton>
                                                        {/* <IconButton size="small" color="primary" >
                                                            <Tooltip title="Ver factura" arrow >
                                                                <ReceiptLongRoundedIcon sx={{ fontSize: '1.2rem' }} />
                                                            </Tooltip>
                                                        </IconButton> */}
                                                    </Stack>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                )
                            }) }
                        </Stack>
                    </Box>
                </Box>
            </Popover>
        </>
    )
}

export default ViewCreditNotes