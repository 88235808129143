import { Tab, TabContext, TabList, TabPanel, styled } from '@component/UIComponents'

const TabListStyled = styled(TabList,{
    shouldForwardProp: (prop) => prop !== "hideTabs"
})(({ hideTabs }) => ({
    ...(hideTabs ? {
        display: 'none !important'
    } : {})
}))

const TabAppContext = ({ sx=undefined, hideTabs=false, tab=undefined, flag=false, onKeyDown=undefined, tabs=[], onChange=undefined, variant=undefined, centered=undefined }) => {
    return(
        <>
            <TabContext value={tab} >
                {flag ?
                <></>
               :
               <TabListStyled sx={sx} hideTabs={hideTabs} onChange={onChange} variant={variant} scrollButtons="auto" centered={centered} >
                    {tabs.map((item, index) => {
                        return(
                            <Tab disabled={(item?.disabled??false)} key={index} label={(item?.label??"")} icon={(item?.icon??undefined)} value={(item?.value??"")} />
                        )
                    })}
               </TabListStyled>
                }
                {tabs.map((item, index) => {
                    return(
                        <TabPanel onKeyDown={onKeyDown} key={index} value={(item?.value??"")}>
                            {item.component}
                        </TabPanel>
                    )
                })}
            </TabContext>
        </>
    )
}

export default TabAppContext