import { Grid } from "@component/UIComponents"
import { Box } from "@mui/material"
import ShowOrdersInSalesReport from "./components/ShowOrdersInSalesReport"

const ReportSettings = () => {
    return (
        <Box py={2} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <ShowOrdersInSalesReport />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ReportSettings