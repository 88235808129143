import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { useGenerateAdvanceFormData, useRegisterAdvanceToSupplier } from '../hooks/useProviders'
import { useEffect } from 'react'
import FormatPaymentMethod from "@component/FormatPaymentMethod"

const GenerateProviderAdvance = ({ provider=null, onClose=()=>null }) => {
    const { values, setFieldValue, validateValues, resetValues } = useGenerateAdvanceFormData()
    const { payments, providerId } = values
    const { registerAdvanceToSupplier, isLoading } = useRegisterAdvanceToSupplier()

    useEffect(() => {
        if( !Boolean(providerId) && Boolean(provider?._id) ){
            setFieldValue("providerId", provider?._id)
        }
    }, [provider, providerId, setFieldValue])

    const handleSubmit = () => {
        const newValues = FormatPaymentMethod(values)
        const { type } = validateValues(newValues)
        if( type === "errors" ) return
        registerAdvanceToSupplier(newValues, {
            clearForm: () => resetValues(),
            closeForm: () => onClose()
        })
    }

    return(
        <>
            <SelectionOfPaymentMethod
                onSubmit={handleSubmit}
                onClose={onClose}
                open={Boolean((provider?._id??false))}
                title={`Generar anticipo a ${(provider?.name??"")}`}
                onChange={(_, payment, newList) => setFieldValue("payments", newList)}
                payments={payments}
                onDeletePayment={(_, index, newList) => setFieldValue("payments", newList)}
                noPayoutLimit
                paymentMethodsAvailable={{
                    credit: false,
                    advance: false,
                }}
                hideTabs
                isLoading={isLoading}
            />
        </>
    )
}

export default GenerateProviderAdvance