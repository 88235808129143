import { useArchivePurchaseMutation, useArchivingPaymentsPurchasesMutation, useGetPurchaseQuery, useGetPurchasesQuery, usePayPurchasesMutation } from "@query/Purchases"
import { AddShoppingCartRoundedIcon, ArchiveRoundedIcon, ArticleRoundedIcon, EditCalendarRoundedIcon, EditIconRounded, FileDownloadIconRounded, LocalPrintshopRoundedIcon, MonetizationOnRoundedIcon, PaymentsRoundedIcon, RemoveRedEyeIconRounded } from '@component/UIComponents'
import { currencyFormat } from "@util/currencyFormat"
import { useURLParams } from "@hook/useURLParams"
import { useMemo, useState } from "react"
import { usePanel } from "@hook/usePanel"
import { populateArrayFromQuery, preventAction, transformStringIntoArray } from "@util/helpers"
import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { useCallback } from "react"
import { useEffect } from "react"
import { Toast } from "@util/swal"
import { useReactToPrint } from "react-to-print"
import { exportComponentAsPNG } from "react-component-export-image"
import { useNavigate } from "react-router-dom"
import { routes } from "@util/routes"
import { usePurchaseController } from "./usePurchaseController"
const { enterPurchase } = routes

export const useGetFilterPurchases = () => {
    const { getQueries } = useURLParams()
    const { getStartDateAndEndDate } = usePanel()
    const { startDate, endDate } = getStartDateAndEndDate

    let { groupBy, showOnly:purchaseStatus, others="", invoiceNumber=undefined, paymentTypes="credit", modal=null } = getQueries(['invoiceNumber', 'others', 'showOnly', 'groupBy', 'paymentTypes', 'modal'])
    others = transformStringIntoArray(others, "-")

    return {
        startDate,
        endDate,
        paymentTypes,
        purchaseStatus,
        invoiceNumber,
        omitDateRange: others.some((n) => n === "omitDateRange") ? true : undefined,
        groupBy,
        modal,
        viewArchived: others.some((n) => n === "viewArchived") ? true : undefined,
        useDueDate: others.some((n) => n === "filterByExpirationDate") ? true : undefined,
    }
}

export const useGetPurchases = (filters={}) => {
    const { getConfigurationFilters } = usePurchaseController()
    const { groupBy, see } = getConfigurationFilters
    const { groupArrangement } = usePanel()
    const { data, isFetching } = useGetPurchasesQuery(filters)
    let purchases = useMemo(() => ((data?.payload??[])), [data])

    let groupResults = useMemo(() => ([]), [])
    if( Boolean(see) ){
        const arrayFilter = populateArrayFromQuery([...(data?.payload??[])], {
            query: see,
            compare: (n) => (n?.provider?._id??""),
            separator: ","
        })
        purchases = arrayFilter
    }

    if( groupBy === "provider" ){
        groupResults = groupArrangement({
            results: purchases,
            groupOuting:(n) => (n[groupBy]?.name??""),
            firstExit: (n) => ({
                groupName: (n[groupBy]?.name??""),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
            }
        })
    }

    groupResults = useMemo(() => groupResults, [groupResults])
    purchases = useMemo(() => purchases, [purchases])
    return {
        purchases,
        isFetching,
        groupResults
    }
}

export const usePurchaseOptions = () => {
    let groupPurchases = [
        {name: "Proveedores", value: "provider"},
    ]
    groupPurchases = groupPurchases.map((n, index) => ({...n, _id: index}))
    
    let purchaseStatus = [
        {name: "Pendiente por pagar", value: "pendingPayable"},
        {name: "Pagada", value: "paid"},
    ]
    purchaseStatus = purchaseStatus.map((n, index) => ({...n, _id: index}))

    return {
        groupPurchases,
        purchaseStatus,
    }
}

export const usePurchaseMenu = (hookProps={}) => {
    const { invoiceRef=undefined } = hookProps
    const navigate = useNavigate()
    const { addQuery } = useURLParams()
    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });
    const downloadInvoiceImage = useCallback((props={}) => exportComponentAsPNG(invoiceRef, { fileName: `#C${(props?.consecutive??"")}` }), [invoiceRef])

    const actionsPurchase = (props={}) => {
        const { onGeneratePaymentSchedule=()=>null } = (props??{})
        return [
            {
                title: "Pagar facturas",
                onClick: () => addQuery({ modal: "paymentWindow" }),
                icon: <AddShoppingCartRoundedIcon />,
            },
            {
                title: "Aplicar descuento",
                onClick: () => null,
                icon: <MonetizationOnRoundedIcon />,
            },
            {
                title: "Generar programación de pago",
                onClick: () => onGeneratePaymentSchedule(),
                icon: <ArticleRoundedIcon />,
            },
        ]
    }

    const dataGridMenu = (props={}) => {
        const { _id=null, setInvoiceSelected=()=>null } = props
        return [
            {
                title: "Ver factura",
                onClick: () => setInvoiceSelected(_id),
                icon: <RemoveRedEyeIconRounded />
            },
            {
                title: "Editar",
                onClick: () => null,
                icon: <EditIconRounded />
            },
            {
                title: "Archivar",
                onClick: () => null,
                icon: <ArchiveRoundedIcon />
            },
        ]
    }

    const invoiceActions = (props, config={}) => {
        const { seeInvoice=true, print=false, download=false, archivedPurchase=()=>null, setInvoiceSelected=()=>null, viewInvoicePayments=()=>null, onUpdateDueDate=()=>null } = config
        return [
            ...(seeInvoice ? [
                {
                    title: "Ver factura",
                    onClick: () => setInvoiceSelected((props?._id??"")),
                    icon: <RemoveRedEyeIconRounded />
                },
            ] : []),
            {
                title: "Editar",
                onClick: () => navigate(enterPurchase((props?._id??" "))),
                icon: <EditIconRounded />
            },
            {
                title: Boolean(props?.isArchived??false) ? "Recuperar" : "Archivar",
                onClick: () => preventAction({
                    text:
                        Boolean(props?.isArchived??false) ?
                        `¿Desea recuperar la compra numero #FAC${(props?.consecutive??0)} a ${(props?.provider?.name??"")}?, Si la compra ya tiene pagos realizados estos serán deducidos de las cajas. Si la compra tiene flete y este contiene pago, el flete sera recuperado y el pago sera deducido de la caja.`
                        :
                        `¿Desea archivar la compra numero #FAC${(props?.consecutive??0)} a ${(props?.provider?.name??"")}?, Si la compra ya tiene pagos realizados estos serán reintegrados a las cajas. Si la compra tiene flete y este contiene pago, el flete sera archivado y el pago sera reintegrado a la caja.`,
                    onSubmit: () => archivedPurchase(props)
                }),
                icon: <ArchiveRoundedIcon />
            },
            {
                title: "Cambiar fecha de vencimiento",
                onClick: () => onUpdateDueDate((props?._id??"")),
                icon: <EditCalendarRoundedIcon />
            },
            ...(print ? [
                {
                    title: "Imprimir",
                    onClick: () => handlePrint(),
                    icon: <LocalPrintshopRoundedIcon />
                },
            ] : []),
            ...(download ? [
                {
                    title: "Descargar",
                    onClick: () => downloadInvoiceImage(props),
                    icon: <FileDownloadIconRounded />
                },
            ] : []),
            {
                title: "Pagos y/o abonos",
                onClick: () => viewInvoicePayments((props?._id??" ")),
                icon: <PaymentsRoundedIcon />,
            },
        ]
    }

    return {
        actionsPurchase,
        dataGridMenu,
        invoiceActions
    }
}

export const calculatePurchases = (list=[]) => {

    const subTotal = list.reduce((acc, params) => acc+(params?.subTotal??0),0)
    const tax = list.reduce((acc, params) => acc+(params?.tax??0),0)
    const total = list.reduce((acc, params) => acc+(params?.total??0),0)
    const retention = list.reduce((acc, params) => acc+(params?.retention??0),0)
    const totalPayments = list.reduce((acc, params) => acc+(params?.totalPayments??0),0)
    const balance = list.reduce((acc, params) => acc+(params?.balance??0),0)
    const subIVA = (subTotal+tax)

    const quickList = [
        {secondary: "Sub total+IVA", primary: currencyFormat(subIVA) },
        {secondary: "Retención en la fuente", primary: currencyFormat(retention) },
        {secondary: "Total en compras", primary: currencyFormat(total) },
        {secondary: "Total pendiente por pagar", primary: currencyFormat(balance) },
    ]

    const purchaseCalculate = (lst=[]) => {
        const subTotal = lst.reduce((acc, params) => acc+(params?.subTotal??0),0)
        const tax = lst.reduce((acc, params) => acc+(params?.tax??0),0)
        const total = lst.reduce((acc, params) => acc+(params?.total??0),0)
        const retention = lst.reduce((acc, params) => acc+(params?.retention??0),0)
        const totalPayments = lst.reduce((acc, params) => acc+(params?.totalPayments??0),0)
        const balance = lst.reduce((acc, params) => acc+(params?.balance??0),0)
        const subIVA = (subTotal+tax)
        const quickList = [
            {secondary: "Sub total+IVA", primary: currencyFormat(subIVA) },
            {secondary: "Retención en la fuente", primary: currencyFormat(retention) },
            {secondary: "Total en compras", primary: currencyFormat(total) },
            {secondary: "Total pendiente por pagar", primary: currencyFormat(balance) },
        ]
        return { subTotal, tax, total, retention, totalPayments, balance, subIVA, quickList }
    }

    return {
        subTotal,
        tax,
        total,
        retention,
        totalPayments,
        balance,
        subIVA,
        quickList,
        purchaseCalculate
    }

}

export const useSelectionPurchase = (params={}) => {
    const [populatedSelectedInvoices, setPopulatedSelectedInvoices] = useState([])
    const { groupArrangement } = usePanel()

    const selectionHandler = useCallback((api=null, index=-1) => {
        if( Boolean(api) ){
            let currentPopulatedSelectedInvoices = [...populatedSelectedInvoices]
            let selection = api.getSelectedRows()
            selection = [...selection]
            selection = selection.reduce((acc, val) => ([...acc, {...(val[1]??{})}]),[])
            currentPopulatedSelectedInvoices[index] = selection
            setPopulatedSelectedInvoices(currentPopulatedSelectedInvoices)
        }
    }, [populatedSelectedInvoices])

    const populatedSelectedInvoicesForProvider = useMemo(() => {
        let result = []
        let invoices = [...populatedSelectedInvoices]
        invoices = invoices?.reduce((acc, param) => ([...acc, ...(param??[])]), [])
        result = groupArrangement({
            results: invoices,
            groupOuting:(n) => (n?.provider?.name??""),
            firstExit: (n) => ({
                groupName: (n?.provider?.name??""),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
            }
        })
        return result
    }, [populatedSelectedInvoices, groupArrangement])

    return {
        selectionHandler,
        populatedSelectedInvoices,
        populatedSelectedInvoicesForProvider,
        setPopulatedSelectedInvoices,
    }
}

export const usePaymentPurchasesFormData = (params={}) => {
    const { defaultValues=null } = params
    const schema = yup.array().min(1, "Seleccione al menos una factura antes de enviar el pago.").of(yup.object().shape({
        providerId: yup.string().required("El identificador del proveedor no se cargo correctamente, cierre la ventana e intente nuevamente."),
        invoiceId: yup.string().required("El identificador de la factura no se cargo correctamente, cierre la ventana e intente nuevamente."),
        payments: yup.array(),
    })).required("Seleccione al menos una factura antes de enviar el pago.")

    let val = useMemo(() => ([]), [])

    const [values, setValue] = useState([...val])

    const loadDefaultValues = useCallback(() => {
        if( Boolean(defaultValues) ){
            setValue([...defaultValues])
        }else{
            setValue([...val])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues])

    const resetValues = () => setValue({...val})

    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    useEffect(() => {
        loadDefaultValues()
    }, [loadDefaultValues])

    const handlePaymentToInvoice = useCallback(({invoiceIndex=-1, payments=[]}) => {
        if( invoiceIndex >= 0 ){
            let currentValues = [...values]
            currentValues[invoiceIndex].payments = payments
            setValue(currentValues)
        }
    }, [values])

    return { values, setFieldValue, resetValues, validateValues, handlePaymentToInvoice }
}

export const useGetSupplierPurchases = (filters={}, config={}) => {
    const { data, isFetching } = useGetPurchasesQuery(filters, config)
    const purchases = (data?.payload??[])

    return {
        purchases,
        isFetching
    }
}

export const usePayPurchases = () => {
    const [payPurchasesMutation, { isLoading }] = usePayPurchasesMutation()

    const payPurchases = async (payload, callback={}) => {
        if( isLoading === false ){
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await payPurchasesMutation(payload).unwrap()
            if( resp?.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: "Los pagos se registraron con éxito."
                })
                clearForm()
                closeForm()
            }else{
                Toast.fire({
                    icon: 'error',
                    text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                })
            }
        }
    }

    return {
        payPurchases,
        isLoading
    }
}
export const useArchivePurchase = () => {
    const [archivePurchaseMutation, { isLoading }] = useArchivePurchaseMutation()

    const archivePurchase = async (payload, callback={}) => {
        if( isLoading === false ){
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await archivePurchaseMutation(payload).unwrap()
            if( resp?.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: Boolean((payload?.archived??false)) ?
                    ((payload?.length??0) >= 2) ?
                        "Las compras se archivaron exitosamente."
                        :
                        "La compra se archivo exitosamente."
                    :
                    ((payload?.length??0) >= 2) ?
                        "Las compras se recuperaron exitosamente."
                        :
                        "La compra se recupero exitosamente."
                })
                clearForm()
                closeForm()
            }else{
                Toast.fire({
                    icon: 'error',
                    text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                })
            }
        }
    }

    return {
        archivePurchase,
        isLoading
    }
}

export const useArchivingPaymentsPurchases = () => {
    const [archivingPaymentsPurchasesMutation, { isLoading }] = useArchivingPaymentsPurchasesMutation()

    const archivingPaymentsPurchases = async (payload, callback={}) => {
        if( isLoading === false ){
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await archivingPaymentsPurchasesMutation(payload).unwrap()
            if( resp?.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: ((payload?.length??0) >= 2) ?
                    "Los pagos se archivaron exitosamente."
                    :
                    "El pago se archivo exitosamente."
                })
                clearForm()
                closeForm()
            }else{
                Toast.fire({
                    icon: 'error',
                    text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                })
            }
        }
    }

    return {
        archivingPaymentsPurchases,
        isLoading
    }
}

export const useGetPurchase = (params={}, config={}) => {
    const { ref="" } = params
    const { data, isFetching } = useGetPurchaseQuery(params, { skip: !Boolean(ref), ...config })
    const purchase = Boolean(ref) ? (data?.payload[0]??null) : (data?.payload??null)

    return {
        purchase,
        isFetching
    }
}