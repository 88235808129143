import SuperDataGrid from "@component/SuperDataGrid"
import { Box, DatePicker, Grid } from "@component/UIComponents"
import { useGetSavedClosuresQuery } from "@query/Business"
import dayjs from "dayjs"
import { useMemo } from "react"
import { SelectPreviousClosure, UpdateDateOfSavedClosings } from "../slice/sliceClosingOfTheDay"
import { useDispatch, useSelector } from "react-redux"

const ListOfSavedClosures = () => {
    const { SavedClosures = {} } = useSelector(state => state.ClosingOfTheDay)
    const { Date = null } = (SavedClosures?.Filters ?? {})
    const { data } = useGetSavedClosuresQuery({ InitialDate: dayjs(Date).format("MM-DD-YYYY"), EndDate: dayjs(Date).format("MM-DD-YYYY"), })
    const closures = useMemo(() => (data?.payload ?? []), [data])
    const dispatch = useDispatch()

    return (
        <Box mt={2} >
            <Grid container >
                <Grid xs={12} >
                    <DatePicker
                        format="DD-MM-YYYY"
                        label="Cierres del"
                        value={dayjs(Date)}
                        onChange={(date) => dispatch(UpdateDateOfSavedClosings(date))}
                        disableFuture
                        slotProps={{
                            textField: {
                                size: "small"
                            }
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        useSearchBar={false}
                        columns={[
                            {
                                maxWidth: 550,
                                minWidth: 550,
                                field: "createdAt",
                                headerName: " ",
                                renderCell: ({ row }) => {
                                    return (
                                        <Box sx={{ cursor: "pointer" }} >
                                            Cierre del dia <b>{`${dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A")}`}</b> registrado por <b> {`${(row?.user?.name ?? "")} ${(row?.user?.lastName ?? "")}`.trim()} </b>
                                        </Box>
                                    )
                                }
                            },
                        ]}
                        rows={closures}
                        onRowClick={({ row }) => dispatch(SelectPreviousClosure((row?.data ?? [])))}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ListOfSavedClosures