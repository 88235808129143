import { Box, Grid } from "@component/UIComponents"
import ComponentTitle from "@component/ComponentTitle"
import InventoryClosingReport from "@feature/Inventory/components/InventoryClosingReport"
import TabAppContext from "@component/TabAppContext"
import ClosingSummary from "./components/ClosingSummary"
import ListOfSavedClosures from "./components/ListOfSavedClosures"
import { useDispatch, useSelector } from 'react-redux'
import { ChangeTabPosition } from "./slice/sliceClosingOfTheDay"

const ClosingOfTheDay = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.ClosingOfTheDay)
    const { TabControl={} } = (state??{})
    const { position="0" } = (TabControl??{})
    return (
        <>
            <ComponentTitle title="Cierre" activeNavigatorTabTitle />
            <Box display={'flex'} flexDirection={'column'} gap={1} >
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <InventoryClosingReport />
                    </Grid>
                    <Grid xs={12} >
                        <TabAppContext
                            // onChange={(_, tab) => navigate(accountsPayable(tab))}
                            onChange={(_, tab) => dispatch(ChangeTabPosition(tab)) }
                            tab={position}
                            tabs={[
                                {label: "Resumen del cierre", component: <ClosingSummary />, value: "0"},
                                {label: "Cierres del dia", component: <ListOfSavedClosures />, value: "1"},
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* <ComponentTitle title="Cierre" activeNavigatorTabTitle />
            { isMobile ? <MobileClosingOfTheDay /> : <DesktopClosingOfTheDay /> } */}
        </>
    )
}

export default ClosingOfTheDay