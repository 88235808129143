import Form from "@component/Form"
import { Box, Button, DatePicker, Divider, Grid } from "@component/UIComponents"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"

const InventoryClosingFilters = ({ onSubmit=()=>null }) => {
    const [filter, setFilter] = useState({
        InitialDate: dayjs().subtract(7, "day"),
        FinalDate: dayjs()
    })
    const { InitialDate=dayjs() } = (filter??{})

    const handleSubmit = useCallback(() => {
        const currentFilter = {...(filter??{})}
        const { FinalDate, InitialDate } = currentFilter
        if( typeof onSubmit === "function" ){
            onSubmit({
                InitialDate: dayjs(InitialDate).format("MM-DD-YYYY"),
                FinalDate: dayjs(FinalDate).format("MM-DD-YYYY"),
            })
        }
    }, [filter, onSubmit])

    const loadDefaultFilter = useCallback(() => {
        handleSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadDefaultFilter()
    }, [loadDefaultFilter])

    return(
        <Form onSubmit={handleSubmit} >
            <Box position={'relative'} >
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <Box fontSize={'.875rem'} fontWeight={'bold'} >
                            Filtrar cierre del inventario
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid xs={12} >
                        <DatePicker
                            onChange={(date) =>  setFilter((prevState) => ({...prevState, InitialDate: date, FinalDate: date})) }
                            label="Desde"
                            value={InitialDate}
                            format="DD-MM-YYYY"
                            minDate={dayjs("07-17-2024")}
                            maxDate={dayjs()}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    fullWidth: true
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box position={'fixed'} bottom={"5px"} width={'100%'} maxWidth={350-22} >
                    <Button type="submit" fullWidth variant="contained" disableElevation >
                        Filtrar
                    </Button>
                </Box>
            </Box>
        </Form>
    )
}

export default InventoryClosingFilters