import Form from '@component/Form'
import { Alert, Box, Button, DeleteIconRounded, Dialog, DialogActions, DialogContent, IconButton } from '@component/UIComponents'
import { useMassiveProductUpdate } from '../hooks/useMassiveProductUpdate'

const ConfirmationOfProductChanges = ({ open=false, onClose=()=>null, products=[], onRemoveEditedProduct=()=>null }) => {
    const { isLoadingMassiveProductUpdate, massiveProductUpdate } = useMassiveProductUpdate()

    const handleSubmit = async () => {
        await massiveProductUpdate({products})
        onClose()
    }

    return(
        <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} >
            <Form onSubmit={handleSubmit} >
                <DialogContent>
                    <Alert severity='info' sx={{ mb: 2 }} >
                        ¿Desea actualizar estos productos?
                    </Alert>
                    {products.map((product) => {
                        return(
                            <Box fontSize={'.875rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.2} display={'flex'} alignItems={'center'} gap={1} >
                                <IconButton size='small' color="error" onClick={() => onRemoveEditedProduct(product)} >
                                    <DeleteIconRounded />
                                </IconButton>
                                {(product.name??"")}
                            </Box>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button type='submit' loading={isLoadingMassiveProductUpdate} >
                        Guardar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default ConfirmationOfProductChanges