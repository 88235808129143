import { Typography } from '@component/UIComponents'
import NumericFormat from "@component/NumericFormat"
import { useGetCustomer } from "@feature/Customers/hooks/useCustomers"
import { useEffect, useRef } from 'react'
import { currencyFormat } from '@util/currencyFormat'

const Advance = ({ onChange=()=>null, value=0, customerId=null, maxVal=0 }) => {
    const { customer } = useGetCustomer({ refId: customerId })
    console.log("🚀 ~ Advance ~ customer:", customer)
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus()
        }, 100)
    }, [inputRef])
    const handleChange = (val) => {
        if( Boolean(onChange) ){
            onChange({ value: val, boxName: (customer?.name??"advanceCustomer"), refId: (customer?._id??null) })
        }
    }
    return(
        <>
            <Typography textTransform={'uppercase'} fontWeight={'bold'} > Anticipo disponible: { currencyFormat((customer?.balance??0)) } </Typography>
            <NumericFormat
                disabled={((customer?.balance??0) <= 0)}
                maxVal={maxVal}
                onChange={({ floatValue=0 }) => handleChange(floatValue)}
                ref={inputRef}
                value={value}
                fullWidth
                style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "2rem",
                }}
            />
        </>
    )
}

export default Advance