import { useCallback } from "react"

export const useCustomerTrackingOptions = () => {
    const customerTrackingGroupOptions = useCallback(() => {
        let r = [
            {name: "Tipo de cliente", value: "customerType"},
            {name: "Tipo de negocio", value: "businessType"},
            {name: "Zona", value: "zone"},
            {name: "Ciudad", value: "city"},
        ].map((n, index) => ({...n, _id: (index+1)}))
        return r
    }, [])

    return {
        customerTrackingGroupOptions
    }
}