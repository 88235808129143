import Products from './Products'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@util/routes'
import { useMemo } from 'react'
import TabAppContext from '@component/TabAppContext'
import Packaging from './Packaging'
import Categories from './Categories'
import BestSeller from './BestSeller'
import InventoryTurnoverRate from './InventoryTurnoverRate'
// import InventoryHistory from './InventoryHistory'
const { inventory } = routes

const Inventory = () => {
    const navigate = useNavigate()
    const { tab = "products" } = useParams()
    const handleChange = (event, newValue) => navigate(inventory(newValue))    
    const tabs = useMemo(() => {
        let resp = []
        resp.push(
            { label: "Productos", value: "products", component: <Products/> },
            // { label: "Historial de inventario", value: "inventoryHistory", component: <InventoryHistory /> },
            { label: "Categorías", value: "categories", component: <Categories /> },
            { label: "Embalajes", value: "packaging", component: <Packaging /> },
            { label: "Mas vendido", value: "bestSeller", component: <BestSeller /> },
            { label: "Tasa de rotación", value: "TurnoverRate", component: <InventoryTurnoverRate /> },
        )
        resp.map((n, index) => ({...n, _id: (index+1)}))
        return resp
    }, [])
    return (
        <>  
            <TabAppContext
                variant={"scrollable"}
                tab={tab}
                tabs={tabs}
                flag={true}
                onChange={handleChange}
            />
        </>
    )
}

export default Inventory