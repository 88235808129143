import SuperDataGrid from "@component/SuperDataGrid"
import { Grid, PaymentsRoundedIcon } from "@component/UIComponents"
import { useGetExpenses } from "./hooks/useGetExpenses"
import { currencyFormat } from "@util/currencyFormat"
import { useTranslation } from "react-i18next"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import Filters from "./components/Filters"
import { useExpensesController } from "./hooks/useExpensesController"
import { useMemo } from "react"
import EmptyList from "@component/EmptyList"
import ComponentTitle from "@component/ComponentTitle"

const Expenses = () => {
    const { filters, setFilterData, getFiltersQuery, totalizeExpenseTable, groupExpensesBy, obtainGroupBy, tableTitle } = useExpensesController()
    const { getDateFormats } = usePanel()
    const { expenses } = useGetExpenses(getFiltersQuery())
    const groupBy = useMemo(() => obtainGroupBy(), [obtainGroupBy])


    const currentExpense = useMemo(() => {
        let cExpenses = [...expenses]
        if( Boolean(groupBy) ){
            let n = groupExpensesBy({ group: (groupBy?.value??""), list: cExpenses })
            cExpenses = n
        }else{
            cExpenses = [
                { groupName: "Gastos varios", results: cExpenses }
            ]
        }

        return cExpenses
    }, [expenses, groupExpensesBy, groupBy])

    const { t } = useTranslation()
    const columns = [
        {
            maxWidth: 120,
            minWidth: 120,
            field: "user",
            headerName: "Responsable",
            renderCell: ({row}) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}`,
        },
        {
            maxWidth: 120,
            minWidth: 120,
            field: "amount",
            headerName: "Monto",
            renderCell: ({row}) => `${currencyFormat((row?.amount??0))}`,
        },
        {
            maxWidth: 120,
            minWidth: 120,
            field: "box",
            headerName: "Caja de salida",
            renderCell: ({row}) => `${t((row?.box??""))}`,
        },
        {
            maxWidth: 120,
            minWidth: 120,
            field: "concept",
            headerName: "Gasto",
            renderCell: ({row}) => `${t((row?.concept??""))}`,
        },
        {
            flex: 1,
            minWidth: 180,
            field: "note",
            headerName: "Descripción"
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "createdAt",
            headerName: "Fecha de registro",
            renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`,
        },
    ]

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Gastos Generales" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={[]}
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    component: () => <Filters state={filters} setState={setFilterData} />,
                                    filters,
                                }
                            }
                        }}
                    />
                </Grid>
                {currentExpense.length <= 0 ?
                    <>
                        <Grid xs={12} >
                            <EmptyList
                                icon={<PaymentsRoundedIcon />}
                                text="No hay gastos para mostrar"
                            />
                        </Grid>
                    </>
                    :
                    <>
                        { currentExpense.map((item, index) => {
                            return(
                                <Grid key={index} xs={12} >
                                    <SuperDataGrid
                                        title={tableTitle(item)}
                                        onQuickList={totalizeExpenseTable}
                                        columns={columns}
                                        rows={(item?.results??[])}
                                        filterBy={(val)=>`${(val?.user?.name??"")} ${(val?.user?.lastName??"")} ${(val?.amount??0)} ${t((val?.box??""))} ${t((val?.concept??""))} ${(val?.note??"")}`}
                                    />
                                </Grid>
                            )
                        }) }
                    </>
                }
            </Grid>
        </>
    )
}

export default Expenses