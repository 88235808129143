import Form from "@component/Form"
import PatternFormat from "@component/PatternFormat"
import TextField from "@component/TextField"
import { Box, Button, Grid, Stack } from "@component/UIComponents"
import { useFormTools } from "@hook/useFormTools"
import { routes } from "@util/routes"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useRegisterFormController } from "../hooks/useRegisterFormController"
import { useEffect } from "react"

const RegisterForm = ({ isNew=false, defaultValues=null, getFormInformation=()=>null }) => {
    const { values, setFieldValue, submitValues } = useRegisterFormController({
        defaultValues,
        onSubmit: (val) => {
            let currentVal = {...val, new: isNew}
            getFormInformation(currentVal)
        }
    })
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { login } = routes
    const { currentType, handleChangeInputType } = useFormTools({ defaultType: "password" })
    const { name, lastName, email, numberPhone, password, repeatPassword } = values
    useEffect(() => {
        const elem = document.getElementsByClassName("MuiStepper-root")
        if( Boolean(elem[0]) ){
            elem[0].style.display = "none"
        }
    }, [])
    return(
        <>
            <Form onSubmit={submitValues} >
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <Box fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} mb={2} > Información de propietario </Box>
                    </Grid>
                    <Grid xs={6} >
                        <TextField
                            value={name}
                            label={t('names')}
                            fullWidth
                            InputFontWeight={500}
                            onChange={({target}) => setFieldValue("name", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <TextField
                            value={lastName}
                            label={t('lastNames')}
                            fullWidth
                            InputFontWeight={500}
                            onChange={({target}) => setFieldValue("lastName", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            value={email}
                            type={'email'}
                            label={t('email')}
                            fullWidth
                            InputFontWeight={500}
                            onChange={({target}) => setFieldValue("email", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <PatternFormat
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={numberPhone}
                            format="(+57) - ### ### ## ##"
                            label={t('phoneNumber')}
                            fullWidth
                            InputFontWeight={500}
                            onChange={(value) => setFieldValue("numberPhone", (value??""))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <TextField
                            value={password}
                            showPassword
                            label={t('password')}
                            fullWidth
                            type={currentType}
                            InputFontWeight={500}
                            onViewPassword={handleChangeInputType}
                            onChange={({target}) => setFieldValue("password", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <TextField
                            value={repeatPassword}
                            showPassword
                            label={t('repeatPassword')}
                            fullWidth
                            type={currentType}
                            InputFontWeight={500}
                            onViewPassword={handleChangeInputType}
                            onChange={({target}) => setFieldValue("repeatPassword", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} sx={{ mt: 3 }} >
                        <Stack justifyContent={'space-between'} direction={'row'} >
                            <Button onClick={() => navigate(login())} >
                                {t('goBack')}
                            </Button>
                            <Button type="submit" variant="contained" disableElevation>
                                {t('Siguiente')}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default RegisterForm