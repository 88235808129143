import FilterContainer from '@component/Filters'
import Form from '@component/Form'
import { Box, Button, DatePicker, Stack } from '@component/UIComponents'
import useQueryParams from '@hook/useQueryParams'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'

const Filters = () => {
    const { setQueryParams, getQueryParams } = useQueryParams()
    const queryParams = getQueryParams(["InitialDate", "FinalDate"])
    const [filters, setFilters] = useState({
        InitialDate: dayjs(queryParams?.InitialDate),
        FinalDate: dayjs(queryParams?.FinalDate),
    })

    const { FinalDate=null, InitialDate=null } = (filters??{})
    const handleSubmit = useCallback(() => {
        const { FinalDate=null, InitialDate=null } = (filters??{})
        setQueryParams({ InitialDate: dayjs(InitialDate).format("MM-DD-YYYY"), FinalDate: dayjs(FinalDate).format("MM-DD-YYYY") })
        const backdrop = document.getElementById("BackdropID")
        if( Boolean(backdrop) ) backdrop.click()
    }, [filters, setQueryParams])

    return(
        <>
            <FilterContainer>
                <Form onSubmit={handleSubmit} >
                    <Stack gap={1} direction={'row'} >
                        <DatePicker
                            format='DD-MM-YYYY'
                            label="Desde"
                            slotProps={{
                                textField: {
                                    size: "small"
                                }
                            }}
                            value={dayjs(InitialDate)}
                            onChange={(date) => setFilters(prevState => ({...prevState, InitialDate: dayjs(date)}))}
                        />
                        <DatePicker
                            format='DD-MM-YYYY'
                            label="Hasta"
                            slotProps={{
                                textField: {
                                    size: "small"
                                }
                            }}
                            value={dayjs(FinalDate)}
                            onChange={(date) => setFilters(prevState => ({...prevState, FinalDate: dayjs(date)}))}
                        />
                    </Stack>
                    <Box position={'fixed'} bottom={5} width={'100%'} maxWidth={330} >
                        <Button type='submit' fullWidth variant='contained' disableElevation >
                            Filtrar
                        </Button>
                    </Box>
                </Form>
            </FilterContainer>
        </>
    )
}

export default Filters