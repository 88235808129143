import ActionsList from "@component/ActionsList"
import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useGetSaleReportFilters, useGetSales, useSaleMenus } from "./hooks/useSales"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"
import Filters from "./components/Filters"
import { memo, useCallback, useMemo, useState } from "react"
import CustomerInvoices from "./components/CustomerInvoices"
import DialogInvoice from "../Invoice/components/DialogInvoice"
import HistoryOfSellingProductsToCustomers from "./components/HistoryOfSellingProductsToCustomers"
import ReportSummary from "./components/ReportSummary"
import ListRendering from "@component/ListRendering"
import { useValidatePermission } from "@hook/useValidatePermission"
import ComponentTitle from "@component/ComponentTitle"
import { useSaleControllerDataGrid, useSalesController } from "./hooks/useSalesController"
import { calculateProfitMarginSale } from "@util/helpers"
import { currencyFormat } from "@util/currencyFormat"

const Sales = () => {
    const { havePermission } = useValidatePermission()
    const [saleSummary, setSaleSummary] = useState(false)
    const [customerSelected, setCustomerSelected] = useState(null)
    const [invoiceSelected, setInvoiceSelected] = useState(null)
    const [customerInvoices, setCustomerInvoices] = useState([])
    const [activeCustomerTracking, setActiveCustomerTracking] = useState(false)
    const selectedInvoice = useCallback((invoiceId) => setInvoiceSelected(invoiceId), [])

    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const {list, ...filters} = useGetSaleReportFilters()
    const { primaryMenu } = useSaleMenus()
    const { isFetching, group, groupResults, results:sales } = useGetSales({ variant: "salesReport", activeCustomerTracking, ...filters })

    const { filters:saleFilters, setFiltersData, invoiceType, groupBy, groupSales, activeFilters } = useSalesController()
    const { columns:columnsDataGrid, rows, quickList, totalizeForDaysList } = useSaleControllerDataGrid({ activeCustomerTracking, sales, modeList: list })
    const columns = useMemo(() => columnsDataGrid({ selectedInvoice, setCustomerInvoices, setCustomerSelected }), [columnsDataGrid, selectedInvoice, setCustomerInvoices, setCustomerSelected])
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Reporte de venta" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryMenu({
                            listVariant: list,
                            isActiveCustomerTracking: activeCustomerTracking,
                            onCustomerTracking: () => setActiveCustomerTracking((prevState) => !prevState),
                            onShowSummaryOfTheDay: () => setSaleSummary(true),
                        })}
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    filters: saleFilters,
                                    component: () => <Filters filters={({...saleFilters, invoiceType, groupBy, groupSales})} setFiltersData={setFiltersData} />,
                                    filterWindow: "new",
                                }
                            },
                            portal: { active: true }
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    {
                        activeCustomerTracking ?
                        <SuperDataGrid
                            columnBuffer={columns.length*2}
                            fixedCells
                            downloadData={havePermission("download_sales_report")}
                            loading={isFetching}
                            columns={columns}
                            rows={rows}
                            filterBy={(params) => `${t((params?.customer?.name??"some"))} ${(params?.seller?.name??"")} ${(params?.consecutive??"")} ${(params?.total??0)} ${t((params?.typePayment??""))} ${getDateFormats((params?.createdAt??"")).mmddyyyyhhmm}`}
                        />
                        :
                        <>
                            { list === "sales" ?
                                <>
                                    <ListRendering
                                        elements={group}
                                        component={(list, index) => {
                                            let r = Boolean(activeFilters?.groupBy) ? list : rows
                                            return(
                                                <SuperDataGrid
                                                    portalId={ Boolean(activeFilters?.groupBy) ? undefined : "actionList-portal"}
                                                    quickListPosition="rightSearchBar"
                                                    title={(groupResults[index]?.groupName??undefined)}
                                                    onQuickList={havePermission("show_profit_information") ? quickList : () => []}
                                                    downloadData={havePermission("download_sales_report")}
                                                    loading={isFetching}
                                                    columns={columns}
                                                    rows={r}
                                                    filterBy={(params) => `${t((params?.customer?.name??"some"))} ${(params?.seller?.name??"")} ${(params?.consecutive??"")} ${(params?.total??0)} ${t((params?.typePayment??""))} ${getDateFormats((params?.createdAt??"")).mmddyyyyhhmm}`}
                                                />
                                            )
                                        }}
                                    />
                                </>
                                :
                                <>
                                    <SuperDataGrid
                                        onQuickList={(rows) => {
                                            return [{
                                                primary: `${currencyFormat((rows??[]).reduce((acc,n) => (n?.total??0)+acc,0))} | ${currencyFormat((rows??[]).reduce((acc,n) => (n?.totalProfitCurrency??0)+acc,0))} | ${calculateProfitMarginSale({ sale: (rows??[]).reduce((acc,n) => (n?.total??0)+acc,0), profit: (rows??[]).reduce((acc,n) => (n?.totalProfitCurrency??0)+acc,0) })}%`,
                                                secondary: "TOTAL"
                                            }]
                                        }}
                                        loading={isFetching}
                                        columns={columns}
                                        rows={totalizeForDaysList()}
                                    />
                                </>
                            }
                        </>
                    }
                </Grid>
            </Grid>
            <CustomerInvoices
                invoices={customerInvoices}
                open={(customerInvoices.length >= 1)}
                onClose={() => setCustomerInvoices([])}
                onSelectedInvoice={(invoiceId) => setInvoiceSelected(invoiceId)}
            />
            <DialogInvoice
                open={Boolean(invoiceSelected)}
                saleRef={invoiceSelected}
                onClose={() => setInvoiceSelected(null)}
            />
            <HistoryOfSellingProductsToCustomers
                open={Boolean(customerSelected)}
                onClose={() => setCustomerSelected(null)}
                filters={{
                    customerId: customerSelected,
                    startDate: (filters?.startDate??undefined),
                    endDate: (filters?.endDate??undefined),
                }}
            />
            <ReportSummary
                open={saleSummary}
                onClose={() => setSaleSummary(false)}
                filters={{
                    startDate: (filters?.startDate??undefined),
                    endDate: (filters?.endDate??undefined),
                }}
            />
        </>
    )
}

export default memo(Sales)