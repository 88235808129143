import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetNeighborhoods } from "../hooks/useCustomers"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const NeighborhoodFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { neighborhoods, isFetching } = useGetNeighborhoods()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: neighborhoods, queryValue, compare })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Barrio"
                options={neighborhoods}
            />
        </>
    )
}

export default NeighborhoodFinder