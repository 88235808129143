import { Dialog, DialogContent } from '@component/UIComponents'
const ReportSummary = ({ open=true, onClose=()=>null, filters={} }) => {
    return(
        <>
            <Dialog maxWidth="lg" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <DialogContent>
                    
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReportSummary