import { AddRoundedIcon, Box, Card, Divider, RemoveRoundedIcon, Stack, styled, Typography } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import dayjs from 'dayjs'
import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import '@style/printStyles.css'
import SkeletonInvoice from './components/SkeletonInvoice'
import Header from './components/Header'

const TypographyCustomerInformation = styled(Typography)(() => ({
    lineHeight: 1.2,
    fontSize: '.7rem',
}))

const TemporaryInvoice = forwardRef(({ highlightItems=[], variant="sale", loading=false, listOfProductsToCompare=[], sx={}, invoiceType="", consecutive=0, createdAt="", seller={}, business={}, customer={}, products=[], total=0, orderNumber=null }, ref) => {
    const { t } = useTranslation()
    const analyzeProductsBetweenTwoInvoices = useCallback((product) => {
        const newProduct = listOfProductsToCompare.some(n => ((n?.productId??null) === (product?.productId??null)))
        const removedProducts = listOfProductsToCompare.filter((n) => !products.some((x) => x.productId === n.productId))
        return {
            newProduct: (listOfProductsToCompare.length <= 0) ? true : newProduct,
            removedProducts
        }
    }, [listOfProductsToCompare, products])

    const ProductDetailLine = ({ product={}, isNewProduct=false, isProductRemoved=false }) => {
        return(
            <Box component={'span'} sx={{
                ...(isProductRemoved ? {
                    displayPrint: "none",
                } : {}),
            }} >
                <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}
                    sx={{
                        ...(highlightItems.some((id) => (id === (product?.productId??""))) ? {
                            backgroundColor: ({ palette }) => palette.grey[300]
                        } : {})
                    }}
                >
                    <Box display={'flex'} alignItems={'center'} displayPrint={"none"} >
                        {isNewProduct &&
                            <AddRoundedIcon sx={{ fontSize: '1.2rem', color: (theme) => theme.palette.success.main }} />
                        }
                        {isProductRemoved &&
                            <RemoveRoundedIcon sx={{ fontSize: '1.2rem', color: (theme) => theme.palette.error.main }} />
                        }
                    </Box>
                    <Box my={"2px"} display={'flex'} flexDirection={'column'} width={'100%'} lineHeight={1} >
                        <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} fontWeight='bold' > {product?.name??""} </Typography>
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <Box display={'flex'} >
                                <Typography className='accentuateBlackColor' mr={1} lineHeight={1.2} fontSize={".8rem"} > x{product?.quantity??0} </Typography>
                                <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} > { currencyFormat(product?.salePrice??0) } </Typography>
                            </Box>
                            <Stack direction={'row'} gap={1} >
                                <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} > {currencyFormat(product.salePrice*product.quantity)} </Typography>
                                -
                                <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} > 0.00% </Typography>
                            </Stack>
                        </Box>
                        <Box className='accentuateBlackColor' fontSize={'.875rem'} fontWeight={'400'} >
                            {(product?.observation??"")}
                        </Box>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        borderColor: "#00000078"
                    }}
                />
            </Box>
        )
    }

    return(
        <>
            <Stack width={1} spacing={1} >
                <Card ref={ref} elevation={0} variant='outlined' className='templateInvoice' sx={{ width: "100%", height: "max-content", padding: 1.5, ...sx }} >
                    {loading ?
                        <>
                            <SkeletonInvoice />
                        </>
                        :
                        <>
                            <Header business={business} invoiceType={invoiceType} variant={variant} orderNumber={orderNumber} />
                            <Box mt={1} display="flex" justifyContent={'space-between'} >
                                <TypographyCustomerInformation className='accentuateBlackColor' fontWeight={'500'} >
                                    <>
                                        {invoiceType === "order" ?
                                            <></>
                                            :
                                            <>
                                                {(invoiceType === "price") ? "#C" : "#R"} {consecutive}
                                            </>
                                        }
                                    </>
                                </TypographyCustomerInformation>
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('date')}:  {dayjs(createdAt).format("DD-MM-YYYY HH:mm:ss")} </TypographyCustomerInformation>
                            </Box>
                            <Divider />
                            <Box my={1} >
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('customer')}: {customer?.name??""} </TypographyCustomerInformation>
                                { Boolean(customer?.identificationNumber) &&
                                    <TypographyCustomerInformation className='accentuateBlackColor' >{t('C.C.')}: {customer?.identificationNumber??""} </TypographyCustomerInformation>
                                }
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('Razón social')}: {customer?.businessName??""} </TypographyCustomerInformation>
                                { Boolean(customer?.phoneNumber) &&
                                    <TypographyCustomerInformation className='accentuateBlackColor' >Tel: {customer?.phoneNumber??""} </TypographyCustomerInformation>
                                }
                                {Boolean(customer?.email) &&
                                    <TypographyCustomerInformation className='accentuateBlackColor' >Email: {customer?.email??""} </TypographyCustomerInformation>
                                }
                                { Boolean(customer?.city) &&
                                    <TypographyCustomerInformation className='accentuateBlackColor' >{t('city')}: {customer?.city?.name??""} </TypographyCustomerInformation>
                                }
                                { Boolean(customer?.neighborhood) &&
                                    <TypographyCustomerInformation className='accentuateBlackColor' >{t('Barrio')}: {customer?.neighborhood?.name??""} </TypographyCustomerInformation>
                                }
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('address')}: {customer?.address??""} </TypographyCustomerInformation>
                            </Box>
                            <Divider />
                            {(products !== undefined) &&
                                <>
                                    {products?.map((product, index) => {
                                        return(
                                            <Box key={index} >
                                                <ProductDetailLine product={product} isNewProduct={!analyzeProductsBetweenTwoInvoices(product).newProduct} />
                                            </Box>
                                        )
                                    })}
                                    {(analyzeProductsBetweenTwoInvoices()?.removedProducts??[])?.map((product, index) => {
                                        return(
                                            <Box key={index} >
                                                <ProductDetailLine product={product} isProductRemoved />
                                            </Box>
                                        )
                                    })}
                                </>
                            }
                            <Stack gap={1} my={1} >
                                <Typography className='accentuateBlackColor' textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('total')}: { currencyFormat(total??0) } </Typography>
                                <Typography className='accentuateBlackColor' textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('Tipo pago')}: { currencyFormat(0) } </Typography>
                                <Typography className='accentuateBlackColor' textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('Cambio')}: { currencyFormat(0) } </Typography>
                            </Stack>
                            <Stack gap={1} my={1} >
                                <Typography lineHeight={1.2} fontSize={".875rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('Base imponible')}: { currencyFormat(total??0) } </Typography>
                                <Typography lineHeight={1.2} fontSize={".875rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('Tipo IVA')}: { currencyFormat(0) } </Typography>
                            </Stack>
                        </>
                    }
                </Card>
            </Stack>
        </>
    )
})

export default TemporaryInvoice