export const getInvoicePaymentType = (sale=null) => {
    let r = "Crédito"
    if( Boolean(sale?._id) ){
        const totalInvoice = (sale?.total??0)
        let allPayments = (sale?.payments??[]).filter((payment) => ((payment?.amount??0) !== 0) )
        const totalPayments = allPayments.reduce((acc, result) => ((result?.type??"") === "entry") ? (acc+(result?.amount??0)) : (acc-(result?.amount??0)),0)
        let payments = (sale?.payments??[]).filter((payment) => ((payment?.amount??0) !== 0) && ((payment?.type??"") === "entry") )
        const typesOfPayments = new Set(payments.map(payment => payment.wallet));

        if( totalPayments < totalInvoice ){
            if( ((sale?.typePayment??"")) === "undefined" ){
                r = "Sin definir"
            }else{
                r = "Crédito"
            }
        }else{
            if( typesOfPayments.size === 1 ){
                if( typesOfPayments.has("cash") ){
                    r = "Efectivo"
                }else if( typesOfPayments.has("consignment") ){
                    r = (payments[0]?.boxName??"")
                }
            }else{
                r = "Parcial"
            }
        }
    }
    return r
}