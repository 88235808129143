import SuperDataGrid from '@component/SuperDataGrid'
import { DeleteIconRounded, Dialog, DialogContent, IconButton } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import { useCompareProductsByDates } from './hooks/useCompareProductsByDates'
import FilterByDate from './components/FilterByDate'
import Toolbar from './components/Toolbar'
import useQueryParams from '@hook/useQueryParams'
import { useMemo } from 'react'

const CompareProductByDates = () => {
    const { rows, UpdateComparisonDate, AddNewRow, RemoveRow, ShowComparison } = useCompareProductsByDates()
    const { getQueryParams, deleteQueryParams } = useQueryParams()
    const { name="" } = useMemo(() => getQueryParams(["name"]), [getQueryParams])
    return(
        <>
            <Dialog maxWidth="lg" open={ShowComparison} fullWidth onClose={() => deleteQueryParams(["name", "ProductID"])} PaperProps={{ elevation: 0 }} >
                <DialogContent>
                    <SuperDataGrid
                        title={name}
                        toolbar={() => <Toolbar AddNewRow={AddNewRow} /> }
                        columns={[
                            {
                                maxWidth: 30,
                                minWidth: 30,
                                field: "__remove__",
                                headerName: " ",
                                align: "center",
                                renderCell: ({ row }) => {
                                    const { index=null } = (row??{})
                                    if( index >= 2 ){
                                        return <>
                                            <IconButton color='error' size='small' onClick={() => RemoveRow((index-1))} >
                                                <DeleteIconRounded />
                                            </IconButton>
                                        </>
                                    }else{
                                        return <></>
                                    }
                                }
                            },
                            {
                                minWidth: 250,
                                field: "name",
                                headerName: "Producto",
                                renderCell: ({ row }) => {
                                    const { name="" } = (row??{})
                                    const value = name
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "InitialStock",
                                headerName: "Inv. Inicial",
                                renderCell: ({ row }) => {
                                    const { InitialStock=0 } = (row??{})
                                    const value = currencyFormat(InitialStock,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "TotalUnitsPurchased",
                                headerName: "Compras",
                                renderCell: ({ row }) => {
                                    const { TotalUnitsPurchased=0 } = (row??{})
                                    const value = currencyFormat(TotalUnitsPurchased,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "_1",
                                headerName: "Total inicial",
                                renderCell: ({ row }) => {
                                    const { TotalUnitsPurchased=0, InitialStock=0 } = (row??{})
                                    const value = currencyFormat((TotalUnitsPurchased+InitialStock),{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "TotalQuantitySold",
                                headerName: "Venta",
                                renderCell: ({ row }) => {
                                    const { TotalQuantitySold=0 } = (row??{})
                                    const value = currencyFormat(TotalQuantitySold,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "EndStock",
                                headerName: "Queda",
                                renderCell: ({ row }) => {
                                    const { EndStock=0 } = (row??{})
                                    const value = currencyFormat(EndStock,{ applyCurrencyFormat: false })
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "SalesRate",
                                headerName: "% Venta",
                                renderCell: ({ row }) => {
                                    const { SalesRate=0 } = (row??{})
                                    const value = `${SalesRate}%`
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 100,
                                maxWidth: 100,
                                field: "RetentionRate",
                                headerName: "% Queda",
                                renderCell: ({ row }) => {
                                    const { RetentionRate=0 } = (row??{})
                                    const value = `${RetentionRate}%`
                                    const Cell = () => <> {value} </>
                                    return <Cell />
                                }
                            },
                            {
                                minWidth: 350,
                                maxWidth: 350,
                                field: "__compare__",
                                headerName: "",
                                renderCell: ({ row }) => {
                                    const { __compare__={}, index=null } = (row??{})
                                    return(
                                        <FilterByDate {...(__compare__??{})} onChange={(field, value) => UpdateComparisonDate(field, value, index,)} />
                                    )
                                }
                            },
                        ]}
                        rows={rows}
                        rowHeight={55}
                        getRowId={(param) => (param?.index??null)}
                        useSearchBar={false}
                        
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CompareProductByDates