import { Box, Grid, Typography, CloseRoundedIcon, Button, Dialog, DialogActions, DialogContent } from '@component/UIComponents'
import FixedExpenseFinder from './FixedExpenseFinder'
import NumericFormat from '@component/NumericFormat'
import { useState } from 'react'
import { currencyFormat } from '@util/currencyFormat'
import { useMemo } from 'react'
import { Toast } from '@util/swal'
import { usePaymentFixedCostFormData, useRecordPaymentToFixedCost } from '../hooks/useFixedCosts'
import Form from '@component/Form'

const payFixedExpenseDefault = { fixedExpense: null, amount: 0 }

const PayFixedExpense = ({ maxPayment=0, boxName="", boxRef="", isBank="", open=false, onClose=()=>null }) => {
    const [payFixedExpenseList, setPayFixedExpenseList] = useState([{...payFixedExpenseDefault}])
    const handleChangePayFixedExpense = (field, value, index) => {
        let currentPayFixedExpenseList = [...payFixedExpenseList]

        if( Boolean(currentPayFixedExpenseList[index]) ){
            if( field === "fixedExpense" ){
                let totalizePayments = ((currentPayFixedExpenseList.reduce((acc, n) => (acc+(n?.amount??0)), 0))+(value?.balance??0))
                if( Boolean(maxPayment) && (totalizePayments > maxPayment) ){
                    Toast.fire({
                        icon: "warning",
                        text: `Has alcanzado el limite de pagos (${currencyFormat(maxPayment)})`
                    })
                    return
                }else{
                    currentPayFixedExpenseList[index][field] = value
                    currentPayFixedExpenseList[index].amount = (value?.balance??0)
                }
            }else{
                currentPayFixedExpenseList[index][field] = value
            }
        }
        let findNullFields = [...currentPayFixedExpenseList].map((n, index) => (!Boolean(n?.fixedExpense?._id??null) && !Boolean(n?.amount??0)) ? index : true )
        findNullFields.forEach((item) => {
            if( typeof item === "number" && item >= 0 ){
                delete currentPayFixedExpenseList[item]
            }
        })
        currentPayFixedExpenseList = currentPayFixedExpenseList.filter(n => Boolean(n))
        if( !Boolean(currentPayFixedExpenseList[index+1]) ){
            currentPayFixedExpenseList.push({...payFixedExpenseDefault})
        }

        setPayFixedExpenseList(currentPayFixedExpenseList)
    }
    const totalToPayment = useMemo(() => payFixedExpenseList.reduce((acc, n) => (acc+(n?.amount??0)), 0) , [payFixedExpenseList])
    const { validateValues } = usePaymentFixedCostFormData({ useSchemaArray: true })
    const { recordPaymentToFixedCost, isLoading } = useRecordPaymentToFixedCost()

    const handleSubmitPayFixedExpense = () => {
        const currentPayFixedExpenseList = [...payFixedExpenseList]
        let payments = currentPayFixedExpenseList.filter((n) => Boolean((n?.fixedExpense?._id??null)) && Boolean((n?.amount??0)) )
        payments = payments.map((n) => ({
            fixedCostId: (n?.fixedExpense?._id??null),
            payments: [{
                box: (isBank === "bank") ? "bank" : boxName,
                amount: (n?.amount??0),
                bank: (isBank === "bank") ? boxName : "",
                boxRef: boxRef,
            }]
        }))
        const { type } = validateValues( payments )
        if(type === "errors") return
        recordPaymentToFixedCost({ paymentsToFixedExpenses: payments }, {
            closeForm: () => {
                onClose()
                setPayFixedExpenseList([{...payFixedExpenseDefault}])
            }
        })
    }

    return(
        <>
            <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmitPayFixedExpense} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                            Pagar Gasto fijo
                        </Typography>
                        <Button
                        onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        {payFixedExpenseList.map((item, index) => {
                            return(
                                <Box key={index} display={'flex'} flexDirection={'row'} sx={{ gap: 1 }} mb={1} >
                                    <FixedExpenseFinder
                                        size="small"
                                        getOptionDisabled={(option) => payFixedExpenseList.some((n) => (n?.fixedExpense?._id??null) === (option?._id??null)) }
                                        value={(item?.fixedExpense??null)}
                                        fullWidth
                                        onChange={(_, value) => handleChangePayFixedExpense("fixedExpense", (value??null), index)}
                                    />
                                    <NumericFormat
                                        size="small"
                                        helperText={ Boolean(item?.fixedExpense?.balance) ? `El monto limite es de ${currencyFormat((item?.fixedExpense?.balance??0))}` : undefined}
                                        maxVal={(item?.fixedExpense?.balance??0)}
                                        value={(item?.amount??0)}
                                        label="Monto"
                                        fullWidth
                                        onChange={({ floatValue }) => handleChangePayFixedExpense("amount", (floatValue??0), index)}
                                        // onChange={({ floatValue }) => console.log(floatValue)}
                                    />
                                </Box>
                            )
                        })}
                    </DialogContent>
                    <DialogActions >
                        <Button type='submit' loading={isLoading} > Pagar - {currencyFormat(totalToPayment)} </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default PayFixedExpense