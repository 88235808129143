import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid } from "@component/UIComponents"
import { useProductHistory, useProductHistoryDataGrid } from "./hooks/useProductHistory"

const ProductHistory = () => {

    const { TotalizeRows, Product } = useProductHistory()
    const { columns=[] } = useProductHistoryDataGrid()
    const { name="" } = (Product??{})

    return(
        <Grid container >
            <Grid xs={12} >
                <SuperDataGrid
                    title={<Box component={'span'} fontSize={'1.2rem'} sx={{ WebkitTextStroke: .5 }} >
                        {name}
                    </Box>}
                    columns={columns}
                    rows={TotalizeRows()}
                />
            </Grid>
        </Grid>
    )
}

export default ProductHistory