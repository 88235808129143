import { Button, CloseRoundedIcon, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@component/UIComponents'
import Form from '@component/Form'
import { useEffect } from 'react'
import { usePackagesControllerForm } from '../hooks/usePackagesControllerForm'
import NumericFormat from '@component/NumericFormat'
import { useRegisterProductPackaging } from '../hooks/useRegisterProductPackaging'

const RegisterPackaging = ({ packaging=null, open=false, onClose=()=>null }) => {
    const { isLoadingRegisterProductPackaging, registerProductPackaging } = useRegisterProductPackaging()
    const { values, setFieldValue, resetValues, validateValues } = usePackagesControllerForm({ defaultValues: packaging })
    const { name="", quantity=1 } = values

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        registerProductPackaging(values, {
            closeForm: () => onClose()
        })
    }

    useEffect(() => {
        if( open === false ){
            resetValues()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return(
        <>
            <Dialog maxWidth="xs" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                            Crear Embalaje
                        </Typography>
                        <Button
                        onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField
                                    onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                                    placeholder="Embalaje"
                                    fullWidth
                                    value={name}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat
                                    label="Cantidad"
                                    prefix="x"
                                    value={quantity}
                                    onChange={({ floatValue }) => setFieldValue("quantity", (floatValue??0))}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button loading={isLoadingRegisterProductPackaging} type='submit' >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default RegisterPackaging