import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { useEffect } from 'react'
import { useGenerateAdvanceFormData, useRegisterAdvanceToCustomer } from "../hooks/useCustomers"
import FormatPaymentMethod from "@component/FormatPaymentMethod"

const ReturnAdvancePaymentFromCustomerToBox = ({ customer=null, onClose=()=>null }) => {
    const { values, setFieldValue, validateValues, resetValues } = useGenerateAdvanceFormData()
    const { payments, customerId } = values
    const { registerAdvanceToCustomer, isLoading } = useRegisterAdvanceToCustomer()

    useEffect(() => {
        if( !Boolean(customerId) && Boolean(customer?._id) ){
            setFieldValue("customerId", customer?._id)
            setFieldValue("concept", "returnedToBox")
        }
    }, [customer, customerId, setFieldValue])

    const handleSubmit = () => {
        const newValues = FormatPaymentMethod(values)
        const { type } = validateValues(newValues)
        if( type === "errors" ) return
        registerAdvanceToCustomer(newValues, {
            clearForm: () => resetValues(),
            closeForm: () => onClose()
        })
    }

    return(
        <>
            <SelectionOfPaymentMethod
                totalToPay={(customer?.balance??0)}
                maxAmount={(customer?.balance??0)}
                onSubmit={handleSubmit}
                onClose={onClose}
                open={Boolean((customer?._id??false))}
                title={`Retornar anticipo desde ${(customer?.name??"")}`}
                onChange={(_, payment, newList) => setFieldValue("payments", newList)}
                payments={payments}
                onDeletePayment={(_, index, newList) => setFieldValue("payments", newList)}
                paymentMethodsAvailable={{
                    credit: false,
                    advance: false,
                }}
                hideTabs
                isLoading={isLoading}
            />
        </>
    )
}

export default ReturnAdvancePaymentFromCustomerToBox