import { Box, CircularProgress } from "@mui/material"

const LoadingOverlay = ({ overlayText="Cargando información..." }) => {
    return(
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'} bgcolor={'#FFFFFF'} >
            <Box textAlign={'center'} >
                <CircularProgress />
                <Box fontWeight={'bold'} fontSize={'.875rem'} >
                    {overlayText}
                </Box>
            </Box>
        </Box>
    )
}
export default LoadingOverlay