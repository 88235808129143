import { ArrowBackIosRoundedIcon, ArrowForwardIosRoundedIcon, Box, Dialog, DialogContent, Grid, IconButton, Typography } from '@component/UIComponents'
import { useGetSale } from '@feature/Reports/Sales/hooks/useSales'
import Invoice from '..'
import { currencyFormat } from '@util/currencyFormat'
import { useMemo, useRef, useState } from 'react'
import { usePanel } from '@hook/usePanel'
import ListPaymentsToInvoices from '@component/ListPaymentsToInvoices'
import { useCallback } from 'react'
import { useEffect } from 'react'
import InvoiceActions from './InvoiceActions'
import { calculateDifferenceOfDays, getDetailedDueDate } from '@util/date'
import { useCalculateSales } from '@feature/Reports/Sales/hooks/useCalculateSales'
import CreditNotes from './CreditNotes'
import DeliveryInformation from '@feature/Deliveries/components/DeliveryInformation'
import TemporaryInvoice from '../TemporaryInvoice'
import ToggleInvoice from './ToggleInvoice'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '@util/db'

const DialogInvoice = ({ open=false, onClose=undefined, saleRef="", highlightItems=[] }) => {
    let { temporaryInvoice=false } = useLiveQuery(() => db.tmp.get(1)) || {}


    const [carouselInvoiceRef, setCarouselInvoiceRef] = useState(null)
    const [relationshipsPosition, setRelationshipsPosition] = useState(null)
    const [displayCreditNoteWindow, setDisplayCreditNoteWindow] = useState(false)

    const { getDateFormats } = usePanel()
    let { sale, isFetching, isFetchingPayments, creditNotes } = useGetSale({ ref: Boolean(carouselInvoiceRef) ? carouselInvoiceRef : saleRef })
    creditNotes = useMemo(() => (creditNotes?.[0]?.results??[]), [creditNotes])
    const relationships = useMemo(() => (sale?.relationships??[]),[sale])
    const currentSale = useMemo(() => sale, [sale])
    const invoiceRef = useRef()
    const daysToDefeat = calculateDifferenceOfDays((currentSale?.creditExpirationDate??""))
    let textForCreditExpiration = getDetailedDueDate((currentSale?.creditExpirationDate??""))
    const overdueCreditColorList = { defeated: (theme) => theme.palette.error.main, current: (theme) => theme.palette.success.main }
    const quantityPayments = (currentSale?.payments??[]).length
    const typePayment = (currentSale?.typePayment??"")
    const invoiceType = (currentSale?.invoiceType??"")
    const typePaymentTextConvert = invoiceType === "order" ? "Pedido" : ((typePayment === "partial") && (quantityPayments === 1) && (currentSale?.totalPayments??0) === (currentSale?.total??0)) ? "Venta al contado" : ((typePayment === "partial") && (quantityPayments >= 2) ) ? "Venta a pagos parciales" : "Venta a crédito"

    const { total } = useCalculateSales()

    const invoiceCarouselHandler = (action) => {
        const invoicePosition = relationships.findIndex((n) => (n === saleRef))

        if( action === "after" ){
            if( relationshipsPosition === null && invoicePosition >= 0 ){
                setRelationshipsPosition((invoicePosition+1))
            }else if( relationshipsPosition !== null && invoicePosition >= 0 ){
                setRelationshipsPosition((prevState) => {
                    if( prevState === (relationships.length-1) ){
                        return (relationships.length-1)
                    }else{
                        return (prevState+1)
                    }
                })
            }
        }else if( action === "before" ){
            setRelationshipsPosition((prevState) => {
                if( prevState === 0 || prevState === null || prevState === undefined ){
                    return 0
                }else{
                    return (prevState-1)
                }
            })
        }
    }

    const loadCarouselReference = useCallback(() => {
        if( Boolean(relationships[relationshipsPosition]) ){
            setCarouselInvoiceRef(relationships[relationshipsPosition])
        }else{
            setCarouselInvoiceRef(null)
        }
    }, [relationshipsPosition, relationships])

    useEffect(() => {
        loadCarouselReference()
    }, [loadCarouselReference])

    const blockPreviousInvoices = useMemo(() => {
        const currentRelationships = [...relationships]
        if( currentRelationships.length >= 2 ){
            let lastValidInvoice = currentRelationships[currentRelationships.length-1]
            if( Boolean(carouselInvoiceRef) && Boolean(saleRef) ){
                if( (lastValidInvoice !== carouselInvoiceRef) || (lastValidInvoice !== saleRef) ){
                    return true
                }
            }
        }
        return false
    }, [saleRef, relationships, carouselInvoiceRef])

    useEffect(() => {
        if( open === false ){
            setCarouselInvoiceRef(null)
            setRelationshipsPosition(null)
        }
    }, [open])

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} transitionDuration={0} fullWidth maxWidth="lg" open={open} onClose={onClose} >
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }} >
                            <InvoiceActions
                                loading={isFetching}
                                blockPreviousInvoices={blockPreviousInvoices}
                                invoice={currentSale}
                                invoiceRef={invoiceRef}
                                saleRef={saleRef}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={8} lg={8} >
                            <Box display={'flex'} alignItems={'center'} >
                                <Box>
                                    <IconButton disabled={((relationships?.length??0) <= 1) || relationshipsPosition === 0 } color='primary' onClick={() => invoiceCarouselHandler("before")} >
                                        <ArrowBackIosRoundedIcon />
                                    </IconButton>
                                </Box>
                                {temporaryInvoice ?
                                    <TemporaryInvoice
                                        highlightItems={highlightItems}
                                        loading={isFetching}
                                        ref={invoiceRef}
                                        {...currentSale}
                                    />
                                    :
                                    <Invoice
                                        highlightItems={highlightItems}
                                        loading={isFetching}
                                        ref={invoiceRef}
                                        {...currentSale}
                                    />
                                }
                                <Box>
                                    <IconButton disabled={((relationships?.length??0) <= 1) || !blockPreviousInvoices} color='primary' onClick={() => invoiceCarouselHandler("after")} >
                                        <ArrowForwardIosRoundedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        {((currentSale?.typePayment??"") === "undefined") && ((currentSale?.invoiceType??"") === "sale") ?
                            <>
                                <Grid xs={12} sm={12} md={4} lg={4} >
                                    <Typography fontSize={'.875rem'} textAlign={'justify'} fontWeight={'500'} > La factura se encuentra en un estado sin definir, defina el estado de la factura para acceder a toda la información y las opciones de la misma</Typography>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid xs={12} sm={12} md={4} lg={4} >
                                    <Box mb={1} >
                                        <ToggleInvoice />
                                    </Box>
                                    <Box mb={2} >
                                        <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Tipo de venta: </Typography>
                                        <Typography textTransform={'uppercase'} lineHeight={1} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                                            { typePaymentTextConvert }
                                        </Typography>
                                    </Box>
                                    { (creditNotes?.length??0) >= 1 &&
                                        <Box mb={2} >
                                            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Notas crédito: </Typography>
                                            <Typography onClick={()=>setDisplayCreditNoteWindow(true)} sx={{ color: (theme) => theme.palette.primary.main, textDecoration: 'underline', cursor: "pointer" }} fontWeight={'bold'} textTransform={'uppercase'} lineHeight={1} fontSize={'.875rem'} display={'inline-block'} > { (creditNotes?.length??0) } </Typography>
                                        </Box>
                                    }
                                    { (currentSale?.isCopy??false) &&
                                        <Box mb={2} >
                                            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Diferencia de la factura: </Typography>
                                            <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > Total: <Box component={'span'} sx={{ color: (theme) =>  theme.palette[total(currentSale).total < 0 ? "error" : "success"].main }} >{currencyFormat( total(currentSale).total )}</Box> </Typography>
                                            <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > Ganancia: <Box component={'span'} sx={{ color: (theme) => theme.palette[total(currentSale).total < 0 ? "error" : "success"].main }} >{currencyFormat( total(currentSale).totalProfitCurrency )}</Box> </Typography>
                                        </Box>
                                    }
                                    <DeliveryInformation invoiceRef={(currentSale?._id??null)} />
                                    {(currentSale?.typePayment??"") === "credit" &&
                                        <Box mb={2} >
                                            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Fecha de vencimiento del crédito: </Typography>
                                            <Typography lineHeight={1} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > { getDateFormats((currentSale?.creditExpirationDate??"")).fullDate } </Typography>
                                            { ((currentSale?.balance??0) >= 1) &&
                                                <Typography textTransform={'uppercase'} sx={{ color: overdueCreditColorList[(daysToDefeat >= 1 ? "current" : "defeated")] }} fontWeight={'bold'} lineHeight={1} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > { textForCreditExpiration } </Typography>
                                            }
                                        </Box>
                                    }

                                    <Box mb={2} >
                                        <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Observación: </Typography>
                                        <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > {(currentSale?.observation??"")} </Typography>
                                    </Box>
                                    <ListPaymentsToInvoices actionRemovePayments={!blockPreviousInvoices} refId={(currentSale?._id??"")} payments={(currentSale?.payments??[])} loading={isFetchingPayments} >
                                        { (currentSale?.totalPayments??0) === (currentSale?.total??0) ?
                                            <Typography textAlign={'center'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} sx={{ color: (theme) => theme.palette.success.main }} >Venta pagada</Typography>
                                            :
                                            <Typography textAlign={'center'} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} sx={{ color: (theme) => theme.palette.warning.main }} > Saldo pendiente: {currencyFormat((currentSale?.balance??0))} </Typography>
                                        }
                                    </ListPaymentsToInvoices>
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
            <CreditNotes
                open={displayCreditNoteWindow}
                onClose={() => setDisplayCreditNoteWindow(false)}
                creditNotes={creditNotes}
                invoice={currentSale}
            />
        </>
    )
}

export default DialogInvoice