import { Button, CurrencyExchangeRoundedIcon, Grid, PaymentsRoundedIcon, Typography } from "@component/UIComponents"
import { currencyFormat } from "@util/currencyFormat"

const useGetButtons = ({setOnOpenTransfer, onOpenTransfer, balance, setOnOpenExpense, onOpenExpense}) =>{
    let buttons = []

    const cajaPOS ={
        button:
        <Grid
            >
            <Typography
                variant="body1"
                fontWeight="bold"
                >
                {`Disponible en caja POS: ${currencyFormat(balance)}`}
            </Typography>
        </Grid>
        }

    const openTransfer ={
        button:
        <Button 
        startIcon={<CurrencyExchangeRoundedIcon />} 
        onClick={()=>{
            setOnOpenTransfer(!onOpenTransfer)
        }}
        sx={{color:'black'}}
        >
            Generar traslado de caja
        </Button>
    }

    const openVariableExpense ={
        button:
        <Button 
        startIcon={<PaymentsRoundedIcon />} 
        onClick={()=>{
            setOnOpenExpense(!onOpenExpense)
        }}
        sx={{color:'black'}}
        >
            Crear gasto variable
        </Button>
    }

    buttons.push(cajaPOS)
    buttons.push(openVariableExpense)
    buttons.push(openTransfer)
    return buttons
}

export default useGetButtons