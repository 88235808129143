import { Box, InputAdornment, SearchIconRounded, TextField, styled, Grid, useMediaQuery, useTheme } from "@component/UIComponents";

const SearchBarTextField = styled(TextField)(() => ({
    maxWidth: 200,
    backgroundColor: "#FFFFFF",
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    }
}));

const SearchBar = ({ value = "", setTextMatch = () => null }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {isMobile ? (
                <Grid container direction="column" spacing={1} width={'100%'}>
                    <SearchBarTextField
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIconRounded />
                                </InputAdornment>
                            )
                        }}
                        value={value}
                        style={{ width: '100%' }}
                        placeholder='Buscar...'
                        onChange={({ target }) => setTextMatch(target?.value ?? "")}
                    />
                    <Box id="portalAnchor" width={'100%'} />
                </Grid>
            ) : (
                <>
                    <SearchBarTextField
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIconRounded />
                                </InputAdornment>
                            )
                        }}
                        value={value}
                        style={{ width: '100%' }}
                        placeholder='Buscar...'
                        onChange={({ target }) => setTextMatch(target?.value ?? "")}
                    />
                    <Box id="portalAnchor" width={'100%'} />
                </>
            )}
        </>
    );
};

export default SearchBar;
