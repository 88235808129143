import Autocomplete from "@component/Autocomplete"
import { useGetProductsByType } from "../hooks/useProducts"

const ProductFinder = ({ placeholder="Buscar productos...", size=undefined, label=undefined, variant=undefined, disabled=undefined, inputVariant="standard", onChange=undefined, value=undefined, typeProduct=undefined, sx=undefined, inputRef=undefined }) => {
    const { results, isFetching } = useGetProductsByType({ variant })

    return(
        <>
            <Autocomplete
                size={size}
                sx={sx}
                ref={inputRef}
                label={label}
                disabled={disabled}
                value={value}
                loading={isFetching}
                options={results}
                variant={inputVariant}
                placeholder={placeholder}
                onChange={onChange}
            />
        </>
    )
}

export default ProductFinder