import useQueryParams from "@hook/useQueryParams"
import { Box } from "@mui/material"
import { useGetProductQuery, useGetProductTrackingQuery } from "@query/Products"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useCallback, useMemo } from "react"

export const useProductHistory = () => {
    const { getQueryParams } = useQueryParams()
    const queries = useMemo(() => getQueryParams(["InitialDate", "FinalDate", "ItemID"]), [getQueryParams])
    const { InitialDate, FinalDate, ItemID } = (queries??{})
    const { data } = useGetProductTrackingQuery({ InitialDate, FinalDate, ItemID, })
    const tracking = useMemo(() => (data?.payload??[]), [data])
    const GetProduct = useGetProductQuery({ productId: ItemID })
    const Product = useMemo(() => (GetProduct?.data?.payload??{}), [GetProduct])
    const TotalizeRows = useCallback(() => {
        let currentRows = [...(tracking??[])]
        if(currentRows.length >= 1) {
            const saleRows = currentRows.filter((item) => ((item?.type) === "sale") )
            const InitialStock = saleRows.reduce((acc, item) => (acc+(item?.InitialStock??0)),0)
            const sold = saleRows.reduce((acc, item) => (acc+(item?.sold??0)),0)
            const cost = saleRows.reduce((acc, item) => (acc+(item?.cost??0)),0)
            const sale = saleRows.reduce((acc, item) => (acc+(item?.sale??0)),0)
            const profit = (sale-cost)
            const margin = ((profit/sale)*100)
            currentRows.unshift({
                _id: "001",
                type: "TOTAL",
                InitialStock,
                sold,
                cost,
                sale,
                profit,
                margin,
                // EndStock: (currentRows[currentRows.length-1]?.EndStock??0),
                rowStyle: "totalize"
            })
        }
        return currentRows
    }, [tracking])
    return {
        TotalizeRows,
        Product,
    }
}

export const useProductHistoryDataGrid = () => {
    const columns = useMemo(() => {
        const styled = (rowStyle) => (rowStyle === "totalize") ? { fontWeight: "bold", WebkitTextStroke: .4, fontSize: ".900rem" } : {}
        const r = [
            {
                maxWidth: 80,
                field: "type",
                headerName: "Tipo",
                renderCell: ({ row }) => {
                    const { type="", rowStyle="" } = (row??{})
                    let value = (type === "sale") ? "Venta" : (type === "shopping") ? "Compra" : (type === "return") ? "Devolución" : type
                    return(
                        <Box component={'span'} sx={styled(rowStyle)} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "InitialStock",
                headerName: "Stock inicial",
                renderCell: ({ row }) => {
                    const { InitialStock=0 } = (row??{})
                    const value = (InitialStock > 0) ? `${currencyFormat(InitialStock, { applyCurrencyFormat: false })}` : ""
                    return (
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "sold",
                headerName: "Venta unidad",
                renderCell: ({ row }) => {
                    const { sold=0, rowStyle="", type="" } = (row??{})
                    const value = (sold > 0) ? `${currencyFormat(sold, { applyCurrencyFormat: false, })}` : ""
                    const extendStyled = (type === "sale") ? { color: ({ palette }) => palette.error.main } : (["shopping", "return"].includes(type)) ? { color: ({ palette }) => palette.success.main } : {}
                    return(
                        <Box component={'span'} sx={{...styled(rowStyle), ...extendStyled}} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "EndStock",
                headerName: "Queda",
                renderCell: ({ row }) => {
                    const { EndStock=0 } = (row??{})
                    const value = (EndStock > 0) ? `${currencyFormat(EndStock, { applyCurrencyFormat: false })}` : ""
                    return (
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 110,
                maxWidth: 110,
                field: "cost",
                headerName: "Costo",
                renderCell: ({ row }) => {
                    const { cost=0, rowStyle="" } = (row??{})
                    const value = (cost > 0) ? `${currencyFormat(cost)}` : ""
                    return(
                        <Box component={'span'} sx={styled(rowStyle)} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 110,
                maxWidth: 110,
                field: "sale",
                headerName: "Valor venta",
                renderCell: ({ row }) => {
                    const { sale=0, rowStyle="" } = (row??{})
                    const value = (sale > 0) ? `${currencyFormat(sale)}` : ""
                    return(
                        <Box component={'span'} sx={styled(rowStyle)} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "profit",
                headerName: "Gana",
                renderCell: ({ row }) => {
                    const { profit=0, rowStyle="" } = (row??{})
                    const value = (profit > 0) ? `${currencyFormat(profit)}` : ""
                    return(
                        <Box component={'span'} sx={styled(rowStyle)} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 80,
                maxWidth: 80,
                field: "margin",
                headerName: "Margen",
                renderCell: ({ row }) => {
                    const { margin=0, rowStyle="" } = (row??{})
                    const value = (margin > 0) ? `${currencyFormat(margin, { applyCurrencyFormat: false })}` : ""
                    return(
                        <Box component={'span'} sx={styled(rowStyle)} > {value} </Box>
                    )
                }
            },
            {
                minWidth: 130,
                maxWidth: 130,
                field: "TotalStock",
                headerName: "Total stock final",
                renderCell: ({ row }) => {
                    const { TotalStock=0 } = (row??{})
                    const value = (TotalStock > 0) ? `${currencyFormat(TotalStock)}` : ""
                    return (
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "CostUnitValue",
                headerName: "Costo unitario",
                renderCell: ({ row }) => {
                    const { CostUnitValue=0 } = (row??{})
                    const value = (CostUnitValue > 0) ? `${currencyFormat(CostUnitValue)}` : ""
                    return (
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "SaleUnitValue",
                headerName: "Venta valor unitario",
                renderCell: ({ row }) => {
                    const { SaleUnitValue=0 } = (row??{})
                    const value = (SaleUnitValue > 0) ? `${currencyFormat(SaleUnitValue)}` : ""
                    return (
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 100,
                maxWidth: 100,
                field: "consecutive",
                headerName: "Consecutivo",
                renderCell: ({ row }) => {
                    const { type="" } = (row??{})
                    const suffix = (["sale", "return"].includes(type)) ? "#FAC" : type === "shopping" ? "#C" : ""
                    const value = `${suffix} ${(row?.consecutive??"")}`
                    return(
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
            {
                minWidth: 160,
                maxWidth: 160,
                field: "createdAt",
                headerName: "Fecha",
                renderCell: ({ row }) => {
                    const { createdAt=null } = (row??{})
                    const value = dayjs(createdAt).isValid() ? dayjs(createdAt).format("DD-MM-YYYY HH:mm:ss A") : ""
                    return(
                        <Box component={'span'}> {value} </Box>
                    )
                }
            },
        ]
        return r
    }, [])
    return {
        columns
    }
}