
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { Button, CurrencyExchangeRoundedIcon, IconButton, ListItemIcon, MenuItem, Tooltip } from "@component/UIComponents"
import { useCashTransferFormData, useRegisterCashTransfer } from "@hook/useMovements"
import { useEffect, useCallback, useState } from "react"
import FormatPaymentMethod from "@component/FormatPaymentMethod"

const CashTransfer = ({ title="", name1="", name2="", boxBalance=0, boxRef=undefined, variantListButton="button", onOpen=false, handleOpen=()=>{} }) => {
    const [ showTransferWindowBetweenBoxes, setShowTransferWindowBetweenBoxes ] = useState(false)
    const { values:cashTransfer, setFieldValue:setFieldValueCashTransfer, validateValues:validateValuesCashTransfer } = useCashTransferFormData({ openModal: showTransferWindowBetweenBoxes })
    const { payments:paymentsCashTransfer, observation:observationCashTransfer, outputRef } = cashTransfer
    const { registerCashTransfer } = useRegisterCashTransfer()

    const handleSubmitCashTransfer = () => {
        const newCashTransfer = FormatPaymentMethod(cashTransfer)
        const { type } = validateValuesCashTransfer(newCashTransfer)
        if(type === "errors") return
        registerCashTransfer(newCashTransfer, {
            closeForm: () => {
                setShowTransferWindowBetweenBoxes(false)
                handleOpen(false)
            }
        })
    }

    const handleCreateVariableExpense = (value) => {
        setShowTransferWindowBetweenBoxes(true)
        setFieldValueCashTransfer("outputRef", boxRef)
    }

    const buttonText = "Generar traslado de caja"

    const loadOutPutRef = useCallback(() => {
        setFieldValueCashTransfer("outputRef", boxRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxRef])


    useEffect(() => {
        loadOutPutRef()
    }, [loadOutPutRef])

    return(
        <>
            {variantListButton === "button" &&
                <Button startIcon={<CurrencyExchangeRoundedIcon />} onClick={handleCreateVariableExpense} >
                    {buttonText}
                </Button>
            }
            {variantListButton === "iconButton" &&
                <Tooltip title={buttonText} arrow >
                    <IconButton onClick={handleCreateVariableExpense} >
                        <CurrencyExchangeRoundedIcon />
                    </IconButton>
                </Tooltip>
            }
            {variantListButton === "mobileMenu" &&
                <MenuItem onClick={handleCreateVariableExpense} >
                    <ListItemIcon>
                        <CurrencyExchangeRoundedIcon />
                    </ListItemIcon>
                    {buttonText}
                </MenuItem>
            }
            <SelectionOfPaymentMethod
                getOptionDisabled={false}
                skipOptions={Boolean(boxRef) ? [boxRef] : undefined}
                title={title}
                name1={name1}
                name2={name2}
                open={showTransferWindowBetweenBoxes || onOpen}
                onClose={() => {
                    setShowTransferWindowBetweenBoxes(false)
                    handleOpen(false)
                }}
                hideTabs
                noPayoutLimit
                maxAmount={boxBalance}
                paymentMethodsAvailable={{ advance: false, credit: false, cashier: true }}
                onChange={(n, x, list) => setFieldValueCashTransfer("payments", list)}
                onDeletePayment={(n, x, list) => setFieldValueCashTransfer("payments", list)}
                payments={paymentsCashTransfer}
                onSubmit={handleSubmitCashTransfer}
             />

        </>
    )
}

export default CashTransfer