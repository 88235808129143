import dayjs from "dayjs"
import { Swal, Toast } from "./swal"
import { calculateProfitMargin } from "./currencyFormat"
import ReactDOMserver from 'react-dom/server';
import i18n from "./translate";
import conf from '../app.conf.json'

export const roundDecimals = (number = 0) => Math.round(number)

export const elementFocus = (attr = "", element, config = {}) => {
    let { by = "id", elemIndex = 0 } = config
    if (Boolean(attr)) {
        let elem = null
        if (by === "id") {
            elem = document.getElementById(attr)
            if (Boolean(elem)) {
                elem.focus()
            }
        }
        if (by === "className") {
            elem = document.getElementsByClassName(attr)
            if (elem.length >= 1 && Boolean(elem[elemIndex])) {
                elem[elemIndex].focus()
            }
        }

    }
    if (Boolean(element)) {
        element.focus()
    }
}

export const getElement = (props = {}) => {
    const { action = "getElementById", attr = "" } = props
    let elem = document[action](attr)
    elem = Boolean(elem) ? elem : null
    return elem
}

const defaultProps = { by: "id", action: "click" }

export const actionOnElement = (element = "", props = defaultProps) => {
    const { action = "click", by = "id" } = props
    let elem = null
    elem = by === "id" ? document.getElementById(element) : null
    if (Boolean(elem)) {
        if (action === "click") elem.click()
    }
}

export const calculateTotalFreight = ({ quantity, freight }) => {
    return (quantity * freight)
}

export const calculateSubtotal = ({ quantity, purchaseCost, roundOff = false }) => {
    return roundOff ? roundDecimals((quantity * purchaseCost)) : (quantity * purchaseCost)
}

export const calculateWithholdingTax = ({ amount = 0, percentageToRetain = 0, roundOff = false }) => {
    return roundOff ? roundDecimals(((percentageToRetain / 100) * amount)) : (percentageToRetain / 100) * amount
}

export const calculateTax = ({ quantity, purchaseCost, tax, roundOff = false }) => {
    const total = calculateSubtotal({ purchaseCost, quantity })
    const currentTax = (tax / 100)
    return roundOff ? roundDecimals((total * currentTax)) : (total * currentTax)
}

export const calculateTotal = ({ quantity, purchaseCost, tax, roundOff = false }) => {
    const total = calculateSubtotal({ purchaseCost, quantity })
    const totalTax = calculateTax({ purchaseCost, quantity, tax })
    return roundOff ? roundDecimals((total + totalTax)) : (total + totalTax)
}

export const workingFortnightOfTheMonth = () => {
    const daysInMonth = dayjs().daysInMonth()
    if (daysInMonth === 29) {
        return 14.5
    } else if (daysInMonth === 28) {
        return 14
    } else if (daysInMonth === 30) {
        return 15
    } else {
        return 15.5
    }
}

export const preventAction = async ({ text = "", input = undefined, inputValue = undefined, inputPlaceholder = undefined, icon = "warning", component = undefined, onSubmit = undefined, confirmButtonText = "Si", cancelButtonText = "No" }) => {
    try {
        let content = {}
        if (Boolean(text)) {
            content = { text }
        }
        if (Boolean(component)) {
            const html = ReactDOMserver.renderToString(component)
            content = { html }
        }
        const swal = await Swal.fire({
            input,
            inputPlaceholder,
            inputValue,
            icon,
            ...content,
            confirmButtonText,
            cancelButtonText,
            showCancelButton: true,
        })

        if (swal.isConfirmed) {
            if (Boolean(onSubmit)) {
                onSubmit({ text: (swal?.value ?? "") })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const advanceFilters = (params = {}) => {
    const { seeOnlyMultiple = true, seeLabel = "", othersMoreOptions = [], seeOptions = [], groupByOptions = [], invoiceStatusOptions = [], showOnlyOptions = [], showOnlyMultiple = true, filters = {} } = params
    const { from = false, to = false, others = false, see = false, showOnly = false, invoiceNumber = false, customerIdentificationNumber = false, groupBy = false, invoiceStatus = false, } = filters
    return [
        {
            show: from,
            component: "datePicker",
            name: "sd",
            componentProps: {
                label: "Desde",
                format: "DD/MM/YYYY",
                slotProps: {
                    textField: {
                        fullWidth: true,
                        variant: "standard",
                        size: "small",
                        InputLabelProps: { shrink: true }
                    }
                }
            },
            gridProps: {
                xs: to ? 6 : 12
            }
        },
        {
            show: to,
            component: "datePicker",
            name: "ed",
            componentProps: {
                label: "Hasta",
                format: "DD/MM/YYYY",
                slotProps: {
                    textField: {
                        fullWidth: true,
                        variant: "standard",
                        size: "small",
                        InputLabelProps: { shrink: true }
                    }
                }
            },
            gridProps: {
                xs: to ? 6 : 12
            }
        },
        {
            show: invoiceNumber,
            component: "patternFormat",
            name: "invoiceNumber",
            componentProps: {
                fullWidth: true,
                label: "Numero de factura",
                variant: "standard",
                size: "small",
                format: "FAC-####################",
                InputLabelProps: { shrink: true }
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: customerIdentificationNumber,
            component: "numericFormat",
            name: "customerIdentificationNumber",
            componentProps: {
                fullWidth: true,
                label: "N/I Cliente",
                variant: "standard",
                size: "small",
                prefix: ""
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: groupBy,
            component: "autocomplete",
            name: "groupBy",
            componentProps: {
                fullWidth: true,
                label: "Agrupar por",
                variant: "standard",
                size: "small",
                options: groupByOptions
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: see,
            component: "autocomplete",
            name: "see",
            componentProps: {
                fullWidth: true,
                label: seeLabel,
                multiple: seeOnlyMultiple,
                variant: "standard",
                size: "small",
                options: seeOptions
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: invoiceStatus,
            component: "autocomplete",
            name: "invoiceStatus",
            componentProps: {
                fullWidth: true,
                multiple: true,
                label: "Estado de factura",
                variant: "standard",
                size: "small",
                options: invoiceStatusOptions
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: showOnly,
            component: "autocomplete",
            name: "showOnly",
            componentProps: {
                fullWidth: true,
                multiple: showOnlyMultiple,
                label: "Mostrar solo",
                variant: "standard",
                size: "small",
                options: showOnlyOptions
            },
            gridProps: {
                xs: 12
            }
        },
        {
            show: others,
            component: "autocomplete",
            name: "others",
            componentProps: {
                fullWidth: true,
                multiple: true,
                label: "Otros",
                variant: "standard",
                size: "small",
                options: [
                    { name: "Omitir rango de fecha", value: "omitDateRange" },
                    { name: "Ver archivados", value: "viewArchived" },
                    ...othersMoreOptions,
                ].map((n, index) => ({ ...n, _id: (index + 1) }))
            },
            gridProps: {
                xs: 12
            }
        },
    ]
}

export const removeArrayRepeats = (array, config = {}) => {
    const { type = "object", field = "_id" } = config
    let uniqueArray = [];
    if (type === "object") {
        const seenIds = {};
        for (const obj of array) {
            if (!seenIds[obj[field]]) {
                uniqueArray.push(obj);
                seenIds[obj[field]] = true;
            }
        }
    }
    if (type === "single") {
        uniqueArray = [...new Set(array)]
    }

    return uniqueArray
}

export const transformStringIntoArray = (query = "", separator = "-") => (query ?? "").split(separator)

export const transformArrayIntoString = (array = [], config = {}) => {
    const { mapFunction = (n) => n, separator = "-" } = config
    let arrayResult = array.map(mapFunction)
    arrayResult = arrayResult.join(separator)
    return arrayResult
}

export const populateArrayFromQuery = (array = [], config = {}) => {
    const { compare = () => null, query = "", separator = "-" } = config
    const currentArray = [...array]
    const queryArray = transformStringIntoArray(query, separator)
    const result = currentArray?.filter((n) => queryArray?.some((x) => x === compare(n)))
    return (result ?? [])
}

export const populateArray = (array = [], arrangementToPopulate = [], config = {}) => {
    const { populateIn = () => null } = config
    const currentArray = [...array]
    const currentArrangementToPopulate = [...arrangementToPopulate]
    const result = currentArray?.filter((n) => currentArrangementToPopulate?.some((x) => populateIn(x, n)))
    return result
}

export const asynchronousResponseHandler = (response = null, config = { successMessage: "", clearForm: () => null, closeForm: () => null, callback: () => null }) => {
    const { successMessage, clearForm = () => null, closeForm = () => null, callback = () => null } = config
    const resp = response
    if (resp?.status === 1) {
        Toast.fire({
            icon: 'success',
            text: successMessage
        })
        clearForm()
        closeForm()
        callback()
    } else {
        if ((resp?.payload?.type ?? "") === "customError") {
            Toast.fire({
                icon: 'error',
                text: i18n.t((resp?.payload?.dictionaryKey ?? ""))
            })
        } else {
            Toast.fire({
                icon: 'error',
                text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
            })
        }
    }
}

export const generatePriceList = (props = {}, config = {}) => {
    const { data = {} } = props
    const { match = "" } = config
    let newList = []
    if (Boolean(match)) {
        console.log(data)
        let dataKeys = Object.keys(data)
        const cost = (data?.cost ?? 0)
        dataKeys = dataKeys.filter((n) => match.test(n))
        newList = dataKeys.map((n) => ({
            profit: (data[n] - cost),
            sellPrice: data[n],
            percentageProfit: calculateProfitMargin(data[n], (data[n] - cost)),
        }))

    }

    console.log(newList)
}

export const createCookie = ({ name = "", value = "", expirationDate = dayjs().endOf('day'), convertJSONstringify = false }) => {
    const formattedExpiration = expirationDate.format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
    if (convertJSONstringify) {
        value = JSON.stringify(value)
    }
    const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${formattedExpiration};`;
    document.cookie = cookieString;
}

export const getCookie = (name, config = {}) => {
    const { convertJSONstringify = false } = config
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            let r = decodeURIComponent(cookie.substring(name.length + 1));
            return convertJSONstringify ? JSON.parse(r) : r
        }
    }
    return null;
}

export const groupValuesByDates = (array = [], props = {}) => {
    const { sd = "", ed = "", itemName = () => "", itemKey = () => "", defaultValues = {}, callback = () => null } = props
    const columnsDate = []
    const diffDays = dayjs(ed).diff(dayjs(sd).subtract(1, "day").toDate(), 'day')
    let currentArray = [...array]
    const newData = {};

    currentArray.forEach((value) => {
        const { createdAt } = value;
        const name = itemName(value)

        const date = dayjs(createdAt).format("DD-MM-YYYY")
        const key = `${name}_${itemKey(value)}`;

        if (!newData[key]) {
            newData[key] = {
                _id: itemKey(value),
                name,
            };
        }

        if (!newData[key][date]) {
            newData[key][date] = { ...defaultValues };
        }
        newData[key][date] = callback(newData[key][date], value)
    });

    let result = Object.values(newData);

    for (let i = 0; i < diffDays; i++) {
        columnsDate.push(dayjs(ed).subtract(i, "day").format("DD-MM-YYYY"))
    }
    return {
        result,
        columnsDate
    }
}

export const convertFilesToBase64 = (files) => {
    return Promise.all(
        files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(event.target.result.split(',')[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        })
    );
}

export const convertURLFileInObjectFile = async (files = []) => {
    try {
        let filesObject = []
        for (let file of files) {
            let fileName = file.split("/")
            fileName = fileName[fileName.length - 1]
            const response = await fetch(file);
            const blob = await response.blob();
            const fileObject = new File([blob], fileName, { type: blob.type });
            filesObject.push(fileObject)
        }
        return filesObject
    } catch (error) {
        console.error('Error al descargar la imagen:', error);
    }
}

export const replaceBy = ({ value = "", replace = "", condition = false }) => {
    return condition ? value : replace
}

export const rebuildArray = (base = [], object = {}) => {
    let newObject = {}
    for (let n of base) {
        if (Boolean(object[n])) {
            newObject[n] = object[n]
        }
    }
    return newObject
}

export const validateRealNumber = (val) => {
    return (
        typeof val === 'number' &&
        !isNaN(val) &&
        Number.isFinite(val) &&
        val >= 0
    );
}

export const roundNumber = (val = 0, fixed = 2) => {
    return parseFloat(val.toFixed(fixed))
}

export const calculateProfitMarginSale = ({ sale = 0, profit = 0 }) => {
    let margin = (profit / sale) * 100;
    margin = validateRealNumber(margin) ? margin : 0
    margin = roundNumber(margin)
    return margin
}

export const generatePropsQuickList = ({ list = [], primaryText = () => null, secondaryText = () => null, sx = () => ({}), onClick = () => null }) => {
    let resp = list.map((item) => ({
        primary: primaryText(item),
        secondary: secondaryText(item),
        sx: sx(),
        onListItemTextClick: onClick(item)
    }))
    return resp
}

export const arrayReducer = ({ list = [], reducer = () => null, reduceTo = 0 }) => {
    let reduceNumber = (acc, val) => (acc + reducer(val))
    let reduceArray = (acc, val) => ([...acc, ...reducer(val)])
    const executeReducer = Array.isArray(reduceTo) ? reduceArray : reduceNumber
    return list.reduce(executeReducer, reduceTo)
}

export const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
        r = g = b = l; // Escala de grises si la saturación es 0
    } else {
        const hueToRgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hueToRgb(p, q, h + 1 / 3);
        g = hueToRgb(p, q, h);
        b = hueToRgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export const rgbToHex = (r, g, b) => {
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


export const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 100);
    const lightness = Math.floor(Math.random() * 50) + 50;
    const rgbColor = hslToRgb(hue, saturation, lightness);

    const hexColor = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);

    return hexColor;
}

export const organizeDateArray = ({dates=[], by="asc"}) => {
    let ascFunction = (a, b) => dayjs(a).toDate() - dayjs(b).toDate()
    let desFunction = (a, b) => dayjs(b).toDate() - dayjs(a).toDate()
    const ordain = by === "asc" ? ascFunction : (by === "des" ? desFunction : () => null)
    return dates.sort(ordain)
}

export const validateExpiredDate = ({ date="", dueDate=dayjs().toDate() }) => {
    const isDefeated = dayjs( dayjs(date).startOf("day") ).diff(dayjs(dueDate).startOf("day"), "day")
    return (isDefeated <= 0)
}

export const getToken = () => {
    let access_token = localStorage.getItem("access_token") || null
    return access_token
}

export const delToken = () => {
    let access_token = getToken()
    if( Boolean(access_token) ){
        localStorage.removeItem("access_token")
    }
    return access_token
}

export const signOff = () => {
    delToken()
    window.location.reload(true)
}

export const deprecatedProps = ({ props=[] }) => {
    if( props.length >= 1 ){
        props.forEach((n) => {
            console.warn(`La propiedad ${n} sera removida en futuras versiones, se aconseja actualizar el código para evitar errores en futuras versiones.`)
        })
    }
}

export const getAppDomain = () => {
    return conf.environment === "development" ? "http://localhost:3000" : conf.domain
}

export const objectGetValue = (object={}, prop="") => {
    const props = prop.split(".")
    let val = null
    for(let i in props){
        let n = props[i]
        if( parseInt(i) === 0 ){
            if( Boolean(object?.[n]) ){
                val = object[n]
            }
        }else if(i >= 1){
            if( Boolean(val?.[n]) ){
                val = val[n]
            }
        }
    }
    return val
}

export const groupArrangement = ({ array=[], groupOuting=()=>"", firstExit=()=>({}), secondExit=()=>null }) => {
    let group = []
    for( let n of array ){
        const groupIndex = group.findIndex((b) => ((b?.groupName??"") === groupOuting(n) ) )
        if(groupIndex >= 0){
            secondExit(group, groupIndex, n)
        }else{
            group.push(firstExit(n))
        }
    }
    return group
}

export const convertToPackaging = (quantity=0, packaging=0) => {
    return roundNumber((quantity/packaging))
}