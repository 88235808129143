import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSalesOptions } from "./useSalesOptions"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import { Box } from "@component/UIComponents"
import { useSaleMenus } from "./useSales"
import { useTranslation } from "react-i18next"
import { currencyFormat } from "@util/currencyFormat"
import { organizeDateArray, roundNumber } from "@util/helpers"
import { useInvoiceController } from "@feature/Reports/Invoice/hooks/useInvoiceController"
import { useURLParams } from "@hook/useURLParams"
import { getInvoicePaymentType } from "../utils/getInvoicePaymentType"

export const useSalesController = () => {
    const { invoiceTypeList, groupSalesBy, groupTracking } = useSalesOptions()
    const toDay = useMemo(() => dayjs().format("MM-DD-YYYY"), [])
    const { getActiveFilters, groupArrangement } = usePanel()
    const { t } = useTranslation()
    const { calculateMargin } = useInvoiceController()
    
    const [filters, setFilters] = useState({
        typesOfCredit: "",
        groupBy: "",
        invoiceType: "sale",
        startDate: toDay,
        endDate: toDay,
        invoiceNumber: "",
        identificationNumber: "",
        customer: "",
        city: "",
        neighborhood: "",
        businessType: "",
        branch: "",
        zone: "",
        groupSales: "days",
    })
    const activeFilters = getActiveFilters({ currentFilters: Object.keys(filters) })

    const invoiceType = useMemo(() => {
        let currentInvoiceTypeList = [...invoiceTypeList()]
        if( !Boolean(filters?.invoiceType) ){
            return currentInvoiceTypeList.find((n) => (n?.value??"") === "sale") || null
        }else{
            return currentInvoiceTypeList.find((n) => (n?.value??"") === (filters?.invoiceType??"")) || null
        }
    }, [filters, invoiceTypeList])

    const groupBy = useMemo(() => {
        let currentGroupSalesBy = [...groupSalesBy()]
        return currentGroupSalesBy.find((n) => (n?.value??"") === (filters?.groupBy??"")) || null
    }, [filters, groupSalesBy])

    const groupSales = useMemo(() => {
        let currentGroupTracking = [...groupTracking()]
        return currentGroupTracking.find((n) => (n?.value??"") === (filters?.groupSales??"")) || null
    }, [filters, groupTracking])

    const setFiltersData = (fiel, value="") => setFilters((prevState) => ({...prevState, [fiel]: value}))

    const loadSelectedFilters = useCallback(() => {
        setFilters((prevState) => ({...prevState, ...activeFilters,}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadSelectedFilters()
    },[loadSelectedFilters])

    const groupedTrackingBy = useCallback((createdAt) =>{
        const groupSales = (activeFilters?.groupSales??"sales")
        if(groupSales === "sales"){
            return dayjs(createdAt).format("DD-MM-YYYY")
        }else if( groupSales === "months" ){
            return t(`month_${dayjs(createdAt).month()}`)
        }else if(groupSales === "years"){
            return dayjs(createdAt).format("YYYY")
        }else{
            return dayjs(createdAt).format("DD-MM-YYYY")
        }
    }, [t, activeFilters])

    const dateFormatBy = useMemo(() =>{
        const groupSales = (activeFilters?.groupSales??"sales")
        if(groupSales === "sales"){
            return "DD-MM-YYYY"
        }else if( groupSales === "months" ){
            return null
        }else if(groupSales === "years"){
            return "YYYY"
        }else{
            return "DD-MM-YYYY"
        }
    }, [activeFilters])

    const generateCustomerTracking = useCallback(({ sales=[] }) => {
        let columnsDate = []
        let currentSales = [...sales]
        currentSales = currentSales.filter((n) => Boolean((n?.customer?._id)) === true)
        const newData = {};
    
        currentSales.reverse().forEach((sale) => {
            const { customer, createdAt, total, totalProfitCurrency, totalProfitPercentage } = sale;
            const customerName = (customer?.name ?? "");
    
            const date = groupedTrackingBy(createdAt)
            const keyCustomer = `${customerName}_${customer._id}`;

            if (!newData[keyCustomer]) {
                newData[keyCustomer] = {
                    _id: (customer?._id ?? ""),
                    customer: customerName,
                    sale: 0,
                    profit: 0,
                    percentage: 0,
                    daysOfAbsence: 0,
                    totalPurchases: 0,
                    lastDay: "",
                    averageDaysBetweenPurchases : 0,
                };
            }
    
            if (!newData[keyCustomer][date]) {
                newData[keyCustomer][date] = {
                    sale: 0,
                    profit: 0,
                    percentage: 0,
                    results: []
                };
            }
            const isValidDate = dayjs(newData[keyCustomer]?.lastDay).isValid()
            let dateDuplicate = newData[keyCustomer]?.lastDay === dayjs(date, "DD-MM-YYYY").format("MM-DD-YYYY")
            let daysOfAbsence = dayjs(newData[keyCustomer].lastDay).diff(dayjs(date, "DD-MM-YYYY").format("MM-DD-YYYY"), 'days')
            daysOfAbsence = isValidDate ? (dateDuplicate ? (newData[keyCustomer][date]?.daysOfAbsence??0) : daysOfAbsence) : daysOfAbsence
            daysOfAbsence = Math.abs(daysOfAbsence)
            daysOfAbsence = Number.isNaN(daysOfAbsence) ? 0 : daysOfAbsence

            newData[keyCustomer].sale += total;
            newData[keyCustomer].profit += totalProfitCurrency;
            newData[keyCustomer].percentage = calculateMargin({ profit: newData[keyCustomer].profit, sale: newData[keyCustomer].sale });
            newData[keyCustomer].percentage = roundNumber(newData[keyCustomer].percentage)
            newData[keyCustomer].totalPurchases += 1
            newData[keyCustomer].daysOfAbsence += daysOfAbsence
            newData[keyCustomer].lastDay = dayjs(date, "DD-MM-YYYY").format("MM-DD-YYYY")
            newData[keyCustomer].averageDaysBetweenPurchases = roundNumber( (newData[keyCustomer].daysOfAbsence/newData[keyCustomer].totalPurchases) )
            newData[keyCustomer][date].daysOfAbsence = daysOfAbsence;
            newData[keyCustomer][date].sale += total;
            newData[keyCustomer][date].profit += totalProfitCurrency;
            newData[keyCustomer][date].percentage = totalProfitPercentage;
            newData[keyCustomer][date].results.push(sale)

            if( !columnsDate.includes(date) ){
                columnsDate.push(date)
            }

        });
    
        let customerTracking = Object.values(newData);
        if( Boolean(dateFormatBy) ){
            columnsDate = organizeDateArray({ dates: columnsDate.map((date) => dayjs(date, dateFormatBy).format("MM-DD-YYYY") ), by: "des" })
            columnsDate = columnsDate.map((date) => dayjs(date).format(dateFormatBy))
        }

        return {
            customerTracking,
            columnsDate
        }
    }, [groupedTrackingBy, dateFormatBy, calculateMargin])
    
    const totalizeReport = useCallback(({sales=[], groupBy="days"}) => {
        let resp = []
        if( groupBy === "days" ){
            resp = groupArrangement({
                results: sales,
                groupOuting:(n) => dayjs(n?.createdAt??"").format("MM-DD-YYYY"),
                firstExit: (n) => ({
                    groupName: dayjs(n?.createdAt??"").format("MM-DD-YYYY"),
                    total: (n?.total??0),
                    totalProfitCurrency: (n?.totalProfitCurrency??0),
                    totalProfitPercentage: (n?.totalProfitPercentage??0),
                }),
                secondExit: (group, index, n) => {
                    group[index].total += (n?.total??0)
                    group[index].totalProfitCurrency += (n?.totalProfitCurrency??0)
                    group[index].totalProfitPercentage = roundNumber(calculateMargin({ sale: group[index].total, profit: group[index].totalProfitCurrency }))
                }
            })
        }
        return resp
    }, [groupArrangement, calculateMargin])

    const totalSalesReport = useCallback(({ sales=[], groupBy="days", skipInvoiceCopies=true }) => {
        let resp = []
        if( groupBy === "days" ){
            resp = groupArrangement({
                results: sales,
                groupOuting:(n) => dayjs(n?.createdAt??"").format("MM-DD-YYYY"),
                firstExit: (n) => {
                    const total = (skipInvoiceCopies ?
                        ((n?.isCopy??false)) ? 0 : (n?.total??0)
                        :
                        ((n?.isCopy??false) && ((n?.diffTotal??0) >= 1)) ? (n?.diffTotal??0) : ((n?.isCopy??false) && ((n?.diffTotal??0) <= -1 )) ? (n?.diffTotal??0) : 0
                    )
                    const totalProfitCurrency = (skipInvoiceCopies ?
                        ((n?.isCopy??false)) ? 0 : (n?.totalProfitCurrency??0)
                        :
                        ((n?.isCopy??false) && ((n?.diffCurrency??0) >= 1)) ? (n?.diffCurrency??0) : ((n?.isCopy??false) && ((n?.diffCurrency??0) <= -1 )) ? (n?.diffCurrency??0) : 0
                    )

                    return {
                        groupName: dayjs(n?.createdAt??"").format("MM-DD-YYYY"),
                        total,
                        totalProfitCurrency,
                        totalProfitPercentage: roundNumber(calculateMargin({ sale: total, profit: totalProfitCurrency })),
                    }
                },
                secondExit: (group, index, n) => {
                    group[index].total += (skipInvoiceCopies ?
                        ((n?.isCopy??false)) ? 0 : (n?.total??0)
                        :
                        ((n?.isCopy??false) && ((n?.diffTotal??0) >= 1)) ? (n?.diffTotal??0) : ((n?.isCopy??false) && ((n?.diffTotal??0) <= -1 )) ? (n?.diffTotal??0) : 0
                    )
                    group[index].totalProfitCurrency += (skipInvoiceCopies ?
                        ((n?.isCopy??false)) ? 0 : (n?.totalProfitCurrency??0)
                        :
                        ((n?.isCopy??false) && ((n?.diffCurrency??0) >= 1)) ? (n?.diffCurrency??0) : ((n?.isCopy??false) && ((n?.diffCurrency??0) <= -1 )) ? (n?.diffCurrency??0) : 0
                    )
                    group[index].totalProfitPercentage = roundNumber(calculateMargin({ sale: group[index].total, profit: group[index].totalProfitCurrency }))
                }
            })
        }
        return resp
    }, [groupArrangement, calculateMargin])

    return {
        totalizeReport,
        totalSalesReport,
        generateCustomerTracking,
        activeFilters,
        groupSales,
        invoiceType,
        filters,
        setFiltersData,
        groupBy
    }
}

export const useSaleControllerDataGrid = ({ activeCustomerTracking=false, sales=[], modeList=null }) => {
    const { secondaryMenu } = useSaleMenus()
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const { activeFilters:filters, generateCustomerTracking, totalizeReport, totalSalesReport } = useSalesController()
    const { calculateMargin } = useInvoiceController()
    const { addQuery } = useURLParams()

    const list = useMemo(() => {
        let dates = []
        let rows = []
        if( activeCustomerTracking ){
            const { columnsDate, customerTracking } = generateCustomerTracking({ sales, sd: (filters?.startDate??""), ed: (filters?.endDate??""), })
            dates = columnsDate
            rows = customerTracking
        }else{
            rows = sales
        }
        return { rows, dates }
    }, [activeCustomerTracking, generateCustomerTracking, filters, sales])

    const columns = useCallback(({ selectedInvoice=()=>null, setCustomerSelected=()=>null, setCustomerInvoices=()=>null }) => {
        const Buttons = (props={}) => <ActionsList
            variant="mobileMenu"
            options={secondaryMenu(props, {
                onViewInvoice: (invoiceId) => selectedInvoice(invoiceId)
            })}
        />
        if(activeCustomerTracking){
            const { dates } = list
            let r = [
                { flex: 1, minWidth: 300, field: "customer", headerName: t('customer'), renderCell: ({row}) => {
                    return(
                        <Box onClick={() => setCustomerSelected((row?._id??null))} >
                            {`${t((row?.customer??"some"))}`}
                        </Box>
                    )
                }},
                { flex: 1, minWidth: 130, align: "center", field: "averageDaysBetweenPurchases", headerName: "Promedio de días", renderCell: ({row}) => (row?.averageDaysBetweenPurchases??0)},
                { flex: 1, minWidth: 140, field: "sale", headerName: "Venta", renderCell: ({row}) => currencyFormat((row?.sale??0))},
                { flex: 1, minWidth: 140, field: "profit", headerName: "$", renderCell: ({row}) => currencyFormat((row?.profit??0))},
                { flex: 1, minWidth: 100, field: "percentage", headerName: "%", renderCell: ({row}) => `${(row?.percentage??0)}%`},

            ]
            for( let column of dates ){
                r.push({
                    maxWidth: 250,
                    minWidth: 250,
                    field: column,
                    headerName: column,
                    renderCell: ({row}) => {
                        return(
                            <Box fontWeight={'500'} component={'span'} onClick={() => setCustomerInvoices((row[column]?.results??[]))} sx={{ color: (theme) => (row[column]?.sale??0) >= 1 ? theme.palette.success.main : undefined }} >
                                {`${currencyFormat((row[column]?.sale??0))} | ${currencyFormat((row[column]?.profit??0))} | ${(row[column]?.percentage??0)}%`}
                            </Box>
                        )
                    }
                })
            }
            return r
        }else{
            const column1 = [
                { maxWidth: 50, minWidth: 50, field: " ", headerName: ' ', renderCell: ({row}) => <Buttons {...row} /> },
                { maxWidth: 130, minWidth: 130, field: "seller", headerName: t('seller'), renderCell: ({row}) => `${t((row?.seller?.name??""))} ${t((row?.seller?.lastName??""))}`},
                { flex: 1, minWidth: 220, maxWidth: 350, field: "customer", headerName: t('customer'), renderCell: ({row}) => `${t((row?.customer?.name??"some"))}` },
                { maxWidth: 80, minWidth: 80, align: "center", field: "consecutive", headerName: ((filters?.invoiceType??"") === "price") ? "#C" : t('#FAC'), },
                { maxWidth: 120, minWidth: 120, field: "total", headerName: ((filters?.invoiceType??"") === "price") ? "Monto" : t('sale'), renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
                { maxWidth: 120, minWidth: 120, field: "totalProfitCurrency", headerName: t('$'), renderCell: ({row}) => `${currencyFormat((row?.totalProfitCurrency??0))}`},
                { maxWidth: 60, minWidth: 60, align: "center", field: "totalProfitPercentage", headerName: t('%'), renderCell: ({row}) => `${(row?.totalProfitPercentage??0)} %`},
                {
                    maxWidth: 120,
                    minWidth: 120,
                    align: "center",
                    field: "typePayment",
                    headerName: ((filters?.invoiceType??"") === "price") ? "Tipo de factura" : t('typePayment'),
                    renderCell: ({row}) => {
                        return ((filters?.invoiceType??"") === "price") ? "Cotización" : `${getInvoicePaymentType((row??null))}`
                    }},
                { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: t('billingDate'), renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).mmddyyyyhhmm}`},
                { maxWidth: 80, minWidth: 80, field: "isCopy", headerName: t('Etiqueta'), renderCell: ({row}) => `${ (row?.isCopy??false) ? "Copia" : "Original" }`},
            ]
            const column2 = [
                { flex: 1, field: "fullDate", headerName: "Fecha", renderCell: ({row}) => <Box sx={{ cursor: "pointer" }} component={'span'} onClick={() => addQuery({ startDate: (row?.fullDate??""), endDate: (row?.fullDate??""), list: "sales" })} > {`${getDateFormats((row?.fullDate??"")).fullDate}`} </Box>},
                { maxWidth: 120, minWidth: 120, field: "total", headerName: "Total", renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
                { maxWidth: 120, minWidth: 120, field: "totalProfitCurrency", headerName: "$", renderCell: ({row}) => `${currencyFormat((row?.totalProfitCurrency??0))}`},
                { maxWidth: 120, minWidth: 120, field: "totalProfitPercentage", headerName: "%", renderCell: ({row}) => `${(row?.totalProfitPercentage??0)}%`},
            ]
            return modeList === "sales" ? column1 : column2
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCustomerTracking, secondaryMenu, filters, getDateFormats, t, list, modeList])

    const rows = useMemo(() => {
        let { rows } = list
        rows = rows.map((item) => ({...item, typePayment: getInvoicePaymentType((item??null))}) )
        return rows
    }, [list])

    const quickList = useCallback((rows=[]) => {
        // const total = arrayReducer({ list: rows, reducer: (n) => (n?.total??0) })
        // const totalProfitCurrency = arrayReducer({ list: rows, reducer: (n) => (n?.totalProfitCurrency??0) })
        // const totalProfitPercentage = roundNumber(calculateMargin({ profit: totalProfitCurrency, sale: total }))
        // let results = totalizeReport({ sales: rows })

        let results = totalSalesReport({ sales: rows })
        const totalDifferenceInInvoices = totalSalesReport({ sales: rows, skipInvoiceCopies: false })

        results = results.map((n) => ({
            primary: `${currencyFormat((n?.total??0))} | ${currencyFormat((n?.totalProfitCurrency??0))} | ${(n?.totalProfitPercentage??0)}%`,
            secondary: getDateFormats(n.groupName).fullDate,
            sx: {
                minWidth: 340,
                '& .MuiListItemText-secondary': {
                    '&:hover': {
                        textDecoration: "underline",
                        cursor: "pointer"
                    }
                }
            },
            onListItemTextClick: () => addQuery({ startDate: (n?.groupName??""), endDate: (n?.groupName??""), list: "sales" }),
            values: {
                total: (n?.total??0),
                totalProfitCurrency: (n?.totalProfitCurrency??0),
                totalProfitPercentage: (n?.totalProfitPercentage??0),
            }
        }))
        results.unshift({
            primary: `${currencyFormat((totalDifferenceInInvoices[0]?.total??0))} | ${currencyFormat((totalDifferenceInInvoices[0]?.totalProfitCurrency??0))}`,
            secondary: "Diferencia en copias",
            sx: {
                minWidth: 340,
                '& .MuiListItemText-secondary': {
                    '&:hover': {
                        textDecoration: "underline",
                        cursor: "pointer"
                    }
                }
            },
        })
        return results
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateMargin, totalizeReport])

    const totalizeForDaysList = useCallback(() => {

        let results = totalizeReport({ sales: rows })
        results = results.map((n, index) => ({
            _id: (index+1),
            fullDate: (n?.groupName??""),
            total: (n?.total??0),
            totalProfitCurrency: (n?.totalProfitCurrency??0),
            totalProfitPercentage: (n?.totalProfitPercentage??0),
        }))
        return results

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateMargin, totalizeReport, rows])

    return {
        rows,
        columns,
        quickList,
        totalizeForDaysList
    }
}