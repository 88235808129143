import { useGetBoxesQuery } from "@query/Movements"
import { currencyFormat } from "@util/currencyFormat"
import { Toast } from "@util/swal"
import { useCallback, useMemo, useState } from "react"

export const usePaymentWindow = ({ index=undefined, onChange=()=>null, ProviderIDs="" }) => {
    const { data } = useGetBoxesQuery({ include: "cashiers,providers", ProviderIDs })
    const boxes = useMemo(() => (data?.payload??[]), [data])

    const [box, setBox] = useState(null)
    const [amount, setAmount] = useState(null)

    const SelectBox = useCallback((box) => {
        if( (box === null) ){
            setBox(null)
            setAmount(0)
            return
        }
        if( typeof box?.type !== "string" || (box?.type??"").trim() === "" ) {
            Toast.fire({
                icon: "error",
                text: "Falta el tipo de caja."
            })
            return
        }
        const elem = document.getElementById(Boolean(index) ? `paymentAmount${index}` : "paymentAmount")
        if( Boolean(elem) ){
            elem.focus()
        }
        setBox(box)
        setAmount(0)
        onChange({ box, amount: 0 })
    }, [index, onChange])

    const UpdateAmount = useCallback((amountVal) => {
        if( typeof amountVal !== "number" ) {
            Toast.fire({
                icon: "error",
                text: "El tipo de dato esperado debe ser numérico.",
            })
            return
        }
        if( amountVal < 0 ){
            Toast.fire({
                icon: "error",
                text: "No se permiten valores negativos.",
            })
            return
        }
        if( amountVal > (box?.balance??0) ){
            Toast.fire({
                icon: "error",
                text: `No puedes superar el monto disponible en la caja ${currencyFormat((box?.balance??0))}`
            })
            onChange({ box, amount: (box?.balance??0) })
            setAmount((box?.balance??0))
            return
        }
        setAmount(amountVal)
        onChange({ box, amount: amountVal })
    }, [box, onChange])

    return {
        SelectBox,
        box,
        UpdateAmount,
        amount,
        boxes
    }
}