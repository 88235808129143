import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetZones } from "../hooks/useCustomers"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const ZoneFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { isFetching, zones } = useGetZones()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: zones, queryValue, compare })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Zona"
                options={zones}
            />
        </>
    )
}

export default ZoneFinder