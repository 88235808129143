import { Tab, TabContext, TabList, TabPanel } from '@component/UIComponents'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@util/routes'
import { useTranslation } from 'react-i18next'
import Sales from './Sales'
import Credits from './Credits'
import Deliveries from './Deliveries'
import { useMemo } from 'react'
import { useValidatePermission } from '@hook/useValidatePermission'
import Statistics from './Statistics'
import Sellers from './Sellers'
import CreditNotes from './CreditNotes'
const { report } = routes

const Reports = () => {
    const { validateArrayByPermissionOrder } = useValidatePermission()
    const { t } = useTranslation()
    const tabs = useMemo(() => {
        let r = [
            { label: t('sales'), value: "sales", component: <Sales /> },
            { label: t('credits'), value: "credits", component: <Credits /> },
            { label: t('deliveries'), value: "delivery", component: <Deliveries /> },
            { label: t('Notas crédito'), value: "creditNotes", component: <CreditNotes /> },
            { label: t('Vendedores'), value: "sellers", component: <Sellers /> },
            { label: t('Estadísticas de ventas'), value: "statistics", component: <Statistics /> },
        ]
        r = validateArrayByPermissionOrder(["show_sales_report", "show_credits_report", "show_deliveries_report", "show_sales_report", "show_deliveries_report"], r)
        return r
    }, [t, validateArrayByPermissionOrder])
    const navigate = useNavigate()
    const { tab = "sales" } = useParams()
    const handleChange = (event, newValue) => navigate(report(newValue))
    return (
        <>
            { ((tabs.length >= 1) && Boolean(tab)) &&
                <TabContext value={tab} >
                    {tabs.map((item, index) => {
                        return(
                            <TabPanel key={index} value={(item?.value??"")}>
                                {item.component}
                            </TabPanel>
                        )
                    })}
                </TabContext>
            }
        </>
    )
}

export default Reports