import { useURLParams } from "@hook/useURLParams"
import { currencyFormat } from "@util/currencyFormat"
import { removeArrayRepeats } from "@util/helpers"
import { createBook } from "@util/xlsx"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useCallback } from "react"
import { useMemo, useState } from "react"

export const useSuperDataGrid = (params={}) => {
    const { columns=[], pageSize=undefined, rowHeight=30, autoHeight=true, height=undefined, title=false } = params
    const currentColumns = useMemo(() => columns, [columns])

    const numRows = useMemo(() => {
        if( Boolean(pageSize) && (pageSize >= 1) ){
            return pageSize
        }else{
            let r = Math.floor(((window.innerHeight-320)/rowHeight))
            r = (r >= 100) ? 100 : r
            return r
        }
    }, [pageSize, rowHeight])

    const pageSizeOptions = useMemo(() => {
        let options = [numRows, 10, 20, 50, 100]
        return options
    }, [numRows])

    const tableHeight = useMemo(() => {
        let n = { height: undefined }
        if( Boolean(autoHeight) ){
            n.height = undefined
        }else if( Boolean(height) && (height >= 1) ){
            n.height = height
        }else{
            n.height = (numRows >= 100) ? ((100*rowHeight)+226.5) : ((numRows*rowHeight)+97.5)
        }
        return n
    }, [autoHeight, height, rowHeight, numRows])

    const externalHeight = useMemo(() => {
        if( Boolean( tableHeight?.height >= 1 ) ){
            return (tableHeight.height+57)+(title ? 91 : 0)
        }else{
            return undefined
        }
    }, [tableHeight, title])

    return {
        currentColumns,
        numRows,
        pageSizeOptions,
        tableHeight,
        externalHeight,
    }
}

export const useSearchDataSuperDataGrid = (params={}) => {
    const { rows=[], filterBy=()=>null, onRowsDataMutation=()=>null } = params
    const [textMatch, setTextMatch] = useState("")

    const currentRows = useMemo(() => {
        let n = [...rows]
        n = n.filter((item) => filterBy(item).toUpperCase().includes(textMatch.toUpperCase()))
        return n
    }, [rows, textMatch, filterBy])

    useEffect(() => {
        if( Boolean(onRowsDataMutation) ){
            onRowsDataMutation(currentRows)
        }
    }, [onRowsDataMutation, currentRows])

    return {
        currentRows,
        textMatch,
        setTextMatch,
    }
}

export const useSelectionsFields = (params={}) => {
    const { columns=[], rows=[], excludeFieldsToFile=[] } = params
    const [ selectedFields, setSelectedFields ] = useState([])

    const options = useMemo(() => {
        let excludeFields = ['_id', 'image', 'images', ' ', ...excludeFieldsToFile]
        let resp = [...columns].map((n) => {
            return excludeFields.some((x) => x === n.field) ? false : { name: n.headerName, field: n.field }
        })
        resp = resp.filter((b) => Boolean(b))
        resp = resp.map((n, index) => ({...n, _id: (index+1)}))
        return resp
    }, [columns, excludeFieldsToFile])

    const loadAllFields = useCallback(() => {
        setSelectedFields(options)
    }, [options])

    useEffect(() => {
        loadAllFields()
    }, [loadAllFields])

    const downloadBook = ({ fileName="" }) => {
        let columns = []
        let currentRows = rows.map((row) => {
            const pairs = Object.entries(row)
            let newObject= {}
            
            pairs.forEach(([field, value]) => {
                if( selectedFields.some((n) => (n?.field === field) ) ){
                    let columnName = ((selectedFields.find((n) => n?.field === field))?.name??"")
                    columns.push(columnName)
                    newObject[field] = (value??"")

                    if( typeof value === "object" ){
                        if( (value?.sale??0) >= 1 ){
                            newObject[field] = `${currencyFormat((value?.sale??0))} | ${currencyFormat((value?.profit??0))} | ${(value?.percentage??0)}%`
                        }else{
                            newObject[field] = (value?.name??"")
                        }
                    }

                    if( field === "compound" ) newObject[field] = Boolean(value) ? "Compuesto" : "Simple"
                    if( /^\d{2}-\d{2}-\d{4}$/.test(field) ) newObject[field] = `${currencyFormat((value?.sale??0))} | ${currencyFormat((value?.profit??0))} | ${value.percentage}%`
                }
            })
            return newObject
        })
        columns = removeArrayRepeats(columns, { type: "single" })
        currentRows = currentRows.filter((n) => Object.keys(n).length >= 1)
        createBook({ columns, rows: currentRows, fileName: Boolean(fileName) ? fileName : `${dayjs().unix()}-${dayjs().format("DDMMYYYYhhmmss")}.xlsx` })
    }
    return {
        options,
        selectedFields,
        setSelectedFields,
        downloadBook,
    }
}

export const useDateRange = ({ onDateRangeChange=undefined }) => {
    const { addQuery } = useURLParams()
    const [linkDate, setLinkDate] = useState(true)

    const handleDateRangeChange = useCallback((field, date) => {
        if( linkDate ){
            if(Boolean(onDateRangeChange)){
                onDateRangeChange(["sd", "ed"], [dayjs(date).format("MM-DD-YYYY"), dayjs(date).format("MM-DD-YYYY")])
            }else{
                addQuery({ sd: dayjs(date).format("MM-DD-YYYY"), ed: dayjs(date).format("MM-DD-YYYY") })
            }
        }else{
            if(Boolean(onDateRangeChange)){
                onDateRangeChange([field], [dayjs(date).format("MM-DD-YYYY")])
            }else{
                addQuery({ [field]: dayjs(date).format("MM-DD-YYYY") })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkDate])

    return {
        handleDateRangeChange,
        setLinkDate,
        linkDate
    }
}