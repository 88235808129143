import { useState } from 'react'
import { usePOSMenu } from '../../Sales/hooks/usePOS'
import { Button } from '@component/UIComponents'
import ProductForm from '@feature/Inventory/Products/ProductForm'

const ButtonCrearProducto = () =>{
    const { optionActions } = usePOSMenu()
    const [showForms, setShowForms] = useState({ registerClient: false, registerProduct: false })
    const optionsActions = optionActions({
        onRecordCustomer: () => setShowForms((prevState) => ({...prevState, registerClient: true})),
        onRecordProduct: () => setShowForms((prevState) => ({...prevState, registerProduct: true})),
    })

    let crear = {}
    optionsActions.map(item=>{
        if(item._id === 0){
            crear = item
        }
    })
    return(
        <>
        <Button 
        endIcon={crear.icon} 
        onClick={crear.onClick}
        sx={{
            color:'black',
            padding: 0,
            margin: 0
        }}
        >
            {crear.title}
        </Button>
        <ProductForm
            open={showForms.registerProduct}
            onClose={() => setShowForms((prevState) => ({...prevState, registerProduct: false}))}
        />
        </>
    )
}

export default ButtonCrearProducto