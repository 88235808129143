import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import _ from 'lodash'

const InitialClosingSummary = {
    isPreviousClosing: false,
    PreviousClosing: undefined,
    ClosingDate: null
}

const slice = createSlice({
    name: 'ClosingOfTheDay',
    initialState: {
        TabControl: {
            position: "0",
        },
        ClosingSummary: {...InitialClosingSummary},
        SavedClosures: {
            Filters: {
                Date: dayjs().startOf("day").toDate()
            }
        }
    },
    reducers: {
        ChangeTabPosition: (state, action) => {
            const position = (action?.payload??null)
            if( typeof position !== "string" ){
                alert("El valor esperado debe ser un string.")
                return
            }
            if( position !== "0" ) state.ClosingSummary = {...InitialClosingSummary}
            state.TabControl.position = position
        },
        SelectPreviousClosure: (state, action) => {
            const previousClosing = (action?.payload??undefined)
            if( !_.isArray(previousClosing) ){
                alert("El valor esperado debe ser un arreglo.")
                return
            }
            if( previousClosing.length <= 0 ){
                alert("No hay un cierre para mostrar.")
                return
            }
            state.ClosingSummary.isPreviousClosing = true
            state.ClosingSummary.PreviousClosing = previousClosing
            state.ClosingSummary.ClosingDate = (previousClosing?.[0]?.createdAt??null)
            state.TabControl.position = "0"
        },
        ReturnToCurrentClosure: (state) => {
            state.ClosingSummary = {...InitialClosingSummary}
        },
        UpdateDateOfSavedClosings: (state, action) => {
            const date = (action?.payload??null)
            if( !dayjs(date).isValid() ){
                alert("El valor esperado debe ser una fecha valida.")
                return
            }
            state.SavedClosures.Filters.Date = dayjs(date).startOf("day").toDate()
        }
    },
})

export const { ChangeTabPosition, SelectPreviousClosure, ReturnToCurrentClosure, UpdateDateOfSavedClosings } = slice.actions
export default slice.reducer