import { ChevronLeftIconRounded, IconButton, Drawer as MuiDrawer, Toolbar, Typography, styled } from '@component/UIComponents'
import DrawerListItemMenu from './DrawerListItemMenu';
import { useDashboard } from '../hooks/useDashboard';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useCallback } from 'react';
import AppBarUserMenu from './AppBarUserMenu';

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' })(
    ({ theme, open, drawerWidth }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: "0px",
                [theme.breakpoints.up('sm')]: {
                    width: "0px",
                },
            }),
        },
    }),
);

const Drawer = ({ toggleDrawer = undefined, open = true, drawerWidth = 245, setOpen=()=>null }) => {
    const { drawerListDefault, settingList } = useDashboard()
    
    const { pathname } = useLocation()
    const suffixPathName = useMemo(() => {
        let resp = ""
        const pathnameSplit = pathname.split("/")
        const pathNameSuffix = pathnameSplit[1]
        if( Boolean(pathNameSuffix) ){
            resp = pathNameSuffix
        }
        return resp
    }, [pathname])
    
    const defaultDrawer = useMemo(() => {
        const availableMenus = ["settings"]
        let drawer = "default"
        let index = availableMenus.findIndex((n) => n === suffixPathName)
        if( index >= 0 ){
            drawer = availableMenus[index]
        }
        return drawer
    }, [suffixPathName])

    const menu = useCallback(() => {
        if( defaultDrawer === "default" ){
            return drawerListDefault()
        }
        if( defaultDrawer === "settings" ){
            return settingList()
        }
        return []
    }, [defaultDrawer, drawerListDefault, settingList])

    return (
        <DrawerStyled variant="permanent" open={open} drawerWidth={drawerWidth} >
            <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: [1] }} >
                {open && <AppBarUserMenu sx={{ display: 'flex', justifyContent:'center', width:'100%' }} /> }
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIconRounded />
                </IconButton>
            </Toolbar>
            {defaultDrawer === "settings" && <Typography px={1} fontWeight={'bold'} fontSize={'1.2rem'} >Configuraciones: </Typography>}
            <DrawerListItemMenu open={open} list={menu()} setOpen={setOpen} />
        </DrawerStyled>
    )
}

export default Drawer