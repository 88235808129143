import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid } from "@component/UIComponents"
import { useGetHauliers, useHauliersMenu } from "./hooks/useHauliers"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import haulier from '@style/images/haulier.svg'
import Img from "@component/Img"
import TransportedMerchandise from "./components/TransportedMerchandise"
import { useState } from "react"
import ComponentTitle from "@component/ComponentTitle"


const Hauliers = () => {
    const [ showWindows, setShowWindows ] = useState({ showWindowTransportedMerchandise: false })
    const { getDateFormats } = usePanel()
    const { hauliers, isFetching } = useGetHauliers()
    const { primaryMenu, secondaryMenu } = useHauliersMenu()

    const Buttons = (props) => <ActionsList
        variant="mobileMenu"
        options={primaryMenu({
            onTransportedMerchandise: () => setShowWindows(prevState => ({...prevState, showWindowTransportedMerchandise: (props?._id??"")}))
        })}
    />

    const columns = [
        {
            flex: 1,
            minWidth: 200,
            field: "name",
            headerName: "Transportista",
            renderCell: ({row}) => {
                return(
                    <>

                        <Box display={'flex'} alignItems={'center'} gap={1} >
                            <Img src={haulier} style={{ maxWidth: 20 }} />
                            <Buttons {...row} />
                            {row.name}
                        </Box>
                    </>
                )
            }
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "createdAt",
            headerName: "Fecha de registro",
            renderCell: ({row}) => getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm
        },
    ]

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Transportistas" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList options={secondaryMenu()} />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        loading={isFetching}
                        columns={columns}
                        rows={hauliers}
                    />
                </Grid>
            </Grid>
            <TransportedMerchandise
                open={Boolean(showWindows.showWindowTransportedMerchandise)}
                onClose={() => setShowWindows(prevState => ({...prevState, showWindowTransportedMerchandise: ""}))}
                refId={showWindows.showWindowTransportedMerchandise}
            />
        </>
    )
}

export default Hauliers