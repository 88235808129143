import { usePrintInvoice } from "@feature/Sales/hooks/usePrintInvoice"
import { useCleanBilling } from "./useCleanBilling"
import { useRegisterOrder } from "./useRegisterOrder"
import { useEditOrder } from "./useEditOrder"
import { useSaveForLater } from "./useSaveForLater"
import { useMemo } from "react"

export const usePointOfSaleActions = ({ onPayBill=()=>null, onCleanBilling=()=>null, products=[], customer=null, total=0, totalProfitCurrency=0, totalProfitPercentage=0, _id=null, user=null }) => {
    const saveForLater = useSaveForLater({ onCleanBilling })
    const cleanBilling = useCleanBilling({ onCleanBilling })
    const registerOrder = useRegisterOrder({ products, customer, total, totalProfitCurrency, totalProfitPercentage, onCleanBilling, onPayBill, user })
    const editOrder = useEditOrder({ products, customer, total, totalProfitCurrency, totalProfitPercentage, onCleanBilling, _id })
    const { PrintMyLastBill } = usePrintInvoice()
    const { typeUser="", billingMode=null } = (user??{})
    const showBillingMode = useMemo(() => {
        if( typeUser === "user" ){
            return (billingMode?.value)
        }else if(typeUser === "admin"){
            return "isAdmin"
        }
    }, [typeUser, billingMode])

    const isEditable = useMemo(() => Boolean(_id), [_id])

    return {
        ...saveForLater,
        ...cleanBilling,
        ...registerOrder,
        ...editOrder,
        PrintMyLastBill,
        showBillingMode,
        isEditable,
    }
}