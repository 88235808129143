import Autocomplete from "@component/Autocomplete"
import { Box, createFilterOptions, InputAdornment } from "@mui/material";
import Item from "./Item";
import dayjs from "dayjs";
import { SearchRounded } from "@mui/icons-material";

const ListItems = ({ list=[], maxHeightContainerList=false, onSelectBill=()=>null, onShow=()=>null, onPrint=()=>null, hideActions=false, hideButtons={print: false, show_bill: false, pay: false} }) => {
    const filterOptions = createFilterOptions({
        stringify: (option) => `${(option?.consecutive??0)} ${(option?.orderNumber??0)} ${(option?.balance??0)} ${(option?.customer?.name??"VARIOS")}`,
    });
    return (
        <>
            <Autocomplete
                filterOptions={filterOptions}
                sx={{ mt: 1 }}
                helperText={`${list.length} resultados`}
                placeholder="Buscar..."
                size="small"
                open={true}
                options={list}
                onChange={(event, item) => ((event?.type) === "keydown") ? onSelectBill((item?._id)) : null}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            position: "relative",
                            top: 20,
                            '& .MuiAutocomplete-listbox': {
                                padding: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                maxHeight: `${maxHeightContainerList}`,
                            },
                            '& .MuiAutocomplete-option': {
                                padding: "0px !important",
                                overflow: "unset !important"
                            },
                            '& .Mui-focusVisible': {
                                backgroundColor: "inherit !important",
                            },
                            '& .Mui-focused': {
                                backgroundColor: "inherit !important",
                                padding: "0px !important",
                                overflow: "unset !important",
                                '& .MuiPaper-root': {
                                    outline: ({ palette }) => `2px solid ${palette.primary.main}`,
                                    transform: "scale(1.02)"
                                }
                            },
                        }
                    }
                }}
                renderOption={(props, option, state, ownerState) => {
                    const { _id, seller = null, total = 0, customer = null, consecutive = 0, orderNumber = 0, invoiceType = "", createdAt = null } = (option ?? {})
                    const isOrder = (invoiceType === "order") ? true : false
                    const consecutiveNumber = isOrder ? `#P ${orderNumber}` : `#FAC ${consecutive}`
                    return (
                        <Box key={_id} {...props} onMouseDown={(e) => e.preventDefault()} >
                            <Item
                                onClickItem={() => onSelectBill(_id)}
                                key={_id}
                                customerName={(customer?.name ?? "VARIOS")}
                                consecutive={consecutiveNumber}
                                sellerName={`${(seller?.name ?? "")} ${(seller?.lastName ?? "")}`}
                                total={total}
                                isOrder={isOrder}
                                onPay={() => onSelectBill(_id)}
                                onPrint={() => onPrint({ BillID: _id, invoiceType })}
                                onShow={() => onShow(_id)}
                                date={dayjs(createdAt).format("HH:mm A")}
                                hideActions={hideActions}
                                hideButtons={hideButtons}
                            />
                        </Box>
                    )
                }}
                InputProps={{
                    startAdornment: (<InputAdornment position='start' >
                        <SearchRounded />
                    </InputAdornment>)
                }}
            />
        </>
    )
}

export default ListItems