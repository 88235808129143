import { Dialog, DialogContent } from '@component/UIComponents'

const CustomerPurchaseHistoryPopUp = ({ children, open=false, onClose=()=>null }) => {

    return(
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} transitionDuration={0} PaperProps={{ elevation: 0 }} >
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default CustomerPurchaseHistoryPopUp