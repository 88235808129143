import { AddCircleOutlineIconRounded, Button, Grid } from "@component/UIComponents"

const Toolbar = ({ AddNewRow=()=>null }) => {
    return(
        <Grid container >
            <Grid xs={12} >
                <Button size="small" startIcon={<AddCircleOutlineIconRounded />} onClick={AddNewRow} >
                    Añadir nueva fila
                </Button>
            </Grid>
        </Grid>
    )
}

export default Toolbar