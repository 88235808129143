import { api, mutationConfig } from "../splitApi";
import { ProductTag, CategoriesTag, PackagingTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerProduct: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product", body };
            },
            invalidatesTags: ProductTag
        }),
        registerProductCategory: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product/category", body };
            },
            invalidatesTags: CategoriesTag
        }),
        registerProductPackaging: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product/packaging", body };
            },
            invalidatesTags: PackagingTag
        }),
        archiveProductCategory: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/product/category", body };
            },
            invalidatesTags: CategoriesTag
        }),
        archiveProductPackaging: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/product/packaging", body };
            },
            invalidatesTags: PackagingTag
        }),
        massLoadingOfProducts: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/mass/loading/of/products", body };
            },
            invalidatesTags: ProductTag
        }),
        updateInventoryHistory: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/inventory/history", body };
            },
            invalidatesTags: ProductTag
        }),
        archiveProduct: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/product", body };
            },
            invalidatesTags: ProductTag
        }),
        massiveProductUpdate: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/massive/product/update", body };
            },
            invalidatesTags: ProductTag
        }),
        getProducts: build.query({
            query: (params=undefined) => ({
                url: `/get/products`,
                params
            }),
            providesTags: ProductTag
        }),
        getProduct: build.query({
            query: (params=undefined) => ({
                url: `/get/product`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductsByType: build.query({
            query: (params=undefined) => ({
                url: `/get/products`,
                params
            }),
            providesTags: ProductTag
        }),
        getPackaging: build.query({
            query: (params=undefined) => ({
                url: `/get/packaging`,
                params
            }),
            providesTags: [...ProductTag, ...PackagingTag]
        }),
        getCategories: build.query({
            query: (params=undefined) => ({
                url: `/get/categories`,
                params
            }),
            providesTags: [...ProductTag, ...CategoriesTag]
        }),
        getProductFamily: build.query({
            query: (params=undefined) => ({
                url: `/get/product/family`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductSalesHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/product/sales/history`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductPurchaseHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/product/purchase/history`,
                params
            }),
            providesTags: ProductTag
        }),
        getBestSellingProducts: build.query({
            query: (params=undefined) => ({
                url: `/get/best/selling/products`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductSalesRecord: build.query({
            query: (params=undefined) => ({
                url: `/get/product/sales/record`,
                params
            }),
            providesTags: ProductTag
        }),
        getInventoryHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/inventory/history`,
                params
            }),
            providesTags: ProductTag
        }),
        getCachedInventoryBulkUpdateOperations: build.query({
            query: (params=undefined) => ({
                url: `/get/cached/inventory/bulk/update/operations`,
                params
            }),
            providesTags: ProductTag
        }),
        GetAllInventoryMovementTracking: build.query({
            query: (params=undefined) => ({
                url: `/get/all/inventory/movement/tracking`,
                params
            }),
            providesTags: ProductTag
        }),
        GetClosingInventorySummary: build.query({
            query: (params=undefined) => ({
                url: `/get/closing/inventory/summary`,
                params
            }),
            providesTags: ProductTag
        }),
        GetProductTracking: build.query({
            query: (params=undefined) => ({
                url: `/get/product/tracking`,
                params
            }),
            providesTags: ProductTag
        }),
        InventoryTurnoverRate: build.query({
            query: (params=undefined) => ({
                url: `/inventory/turnover/rate`,
                params
            }),
            providesTags: ProductTag
        }),
        GetProductTurnoverRate: build.query({
            query: (params=undefined) => ({
                url: `/get/product/turnover/rate`,
                params
            }),
            providesTags: ProductTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterProductMutation,
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useGetPackagingQuery,
    useGetProductFamilyQuery,
    useGetProductSalesHistoryQuery,
    useGetProductPurchaseHistoryQuery,
    useGetProductsByTypeQuery,
    useGetCategoriesQuery,
    useGetProductQuery,
    useMassLoadingOfProductsMutation,
    useRegisterProductCategoryMutation,
    useArchiveProductCategoryMutation,
    useRegisterProductPackagingMutation,
    useArchiveProductPackagingMutation,
    useGetBestSellingProductsQuery,
    useGetInventoryHistoryQuery,
    useUpdateInventoryHistoryMutation,
    useArchiveProductMutation,
    useMassiveProductUpdateMutation,
    useGetProductSalesRecordQuery,
    useGetCachedInventoryBulkUpdateOperationsQuery,
    useGetAllInventoryMovementTrackingQuery,
    useGetClosingInventorySummaryQuery,
    useGetProductTrackingQuery,
    useInventoryTurnoverRateQuery,
    useLazyGetProductTurnoverRateQuery,
} = Endpoints;
