import DetailsWindow from "@component/DetailsWindow"
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { Box, Button, IconButton, ListItemIcon, MenuItem, PaymentsRoundedIcon, Tooltip, Typography } from "@component/UIComponents"
import { useRecordVariableExpense, useVariableExpensesFormData } from "@feature/Expenses/hooks/useExpenses"
import { useState } from "react"
import FormatPaymentMethod from "@component/FormatPaymentMethod"

const VariableExpense = ({ variantBox, fixedBox=undefined, variantListButton="button", onOpen=false, handleOpen=()=>{} }) => {
    const [ showVariableSpendingWindow, setShowVariableSpendingWindow ] = useState(false)
    const [ showVariableExpenseDetailWindow, setShowVariableExpenseDetailWindow ] = useState(false)


    const { values, setFieldValue, validateValues } = useVariableExpensesFormData({ openModal: showVariableSpendingWindow })
    const { payments,  } = values
    const { recordVariableExpense } = useRecordVariableExpense()

    const handleSubmitVariableExpense = () => {
        const newVariableExpense = FormatPaymentMethod(values)
        const { type } = validateValues(newVariableExpense)
        if(type === "errors") return
        recordVariableExpense(newVariableExpense, {
            closeForm: () => {
                handleOpen(false)
                setShowVariableExpenseDetailWindow(false)
            }
        })
    }

    const openDetailsVariableExpense = () => setShowVariableExpenseDetailWindow(true)

    return(
        <>
            {variantListButton === "button" &&
                <Button startIcon={<PaymentsRoundedIcon />} onClick={openDetailsVariableExpense} >
                    Crear gasto variable
                </Button>
            }
            {variantListButton === "iconButton" &&
                <Tooltip title="Crear gasto variable" arrow >
                    <IconButton onClick={openDetailsVariableExpense} >
                        <PaymentsRoundedIcon />
                    </IconButton>
                </Tooltip>
            }
            {variantListButton === "mobileMenu" &&
                <MenuItem onClick={openDetailsVariableExpense} >
                    <ListItemIcon>
                        <PaymentsRoundedIcon />
                    </ListItemIcon>
                    Crear gasto variable
                </MenuItem>
            }
            <SelectionOfPaymentMethod
                getOptionDisabled={false}
                title={`Gasto variable`}
                name1="Usuario"
                name2="Disponible"
                fixedBox={fixedBox}
                open={onOpen || showVariableExpenseDetailWindow}
                onClose={() => {
                    setShowVariableExpenseDetailWindow(false)
                    handleOpen(false)
                }}
                hideTabs
                noPayoutLimit
                paymentMethodsAvailable={{ cash: (!(variantBox === "bank") && !(variantBox === "cashier")) , consignment: (variantBox === "bank"), cashier: (variantBox === "cashier"), advance: false, credit: false }}
                onChange={(n, x, list) => setFieldValue("payments", list)}
                onDeletePayment={(n, x, list) => setFieldValue("payments", list)}
                payments={payments}
                onSubmit={handleSubmitVariableExpense}
            />
            
        </>
    )
}

export default VariableExpense