import { Box, Button, Card, CardContent, Dialog, DialogContent, Divider, Stack } from '@component/UIComponents'
import SelectBoxAndAmount from './components/SelectBoxAndAmount'
import { Fragment, useMemo } from 'react'
import { currencyFormat } from '@util/currencyFormat'
import { useSendPayment } from './hooks/useSendPayment'
import Form from '@component/Form'

const PaymentWindow = ({ purchases=[], open=false, onClose=()=>null, onClearPayments=()=>null }) => {
    const { UpdatePaymentList, totalPayments, SendPayment } = useSendPayment({
        onResetPayments: () => {
            onClearPayments()
            onClose()
        }
    })

    const totalizePurchases = useMemo(() => purchases.reduce((acc1, item) =>
        acc1+(item?.results??[]).reduce((acc2, purchase) => (acc2+(purchase?.balance??0)),0) ,0
    ) ,[purchases])

    return(
        <Dialog fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} >
            <DialogContent>
                <Form onSubmit={SendPayment} >
                    <Box textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} mb={1} textAlign={'center'} lineHeight={1.2} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={.5} >
                        <Box>
                            Pagar compras -
                        </Box>
                        <Box fontSize={'1rem'} color={'primary.main'} sx={{ WebkitTextStroke: '.4px' }} >
                            {currencyFormat(totalizePurchases)}
                        </Box>
                    </Box>
                    <Box overflow={'scroll'} maxHeight={'76vh'} px={1} >
                        { purchases.map((purchase, purchaseKey) => {
                            const { groupName="", results=[] } = (purchase??{})
                            const totalizePurchases = results.reduce((acc, item) => (acc+(item?.balance??0)), 0)
                            return(
                                <Fragment key={purchaseKey} >
                                    <Card elevation={0} variant='outlined' sx={{ mb: 1 }} >
                                        <CardContent>
                                            <Box fontWeight={'bold'} fontSize={'.875rem'} >
                                                {groupName}
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                            { results.map((item, index) => {
                                                const { _id=null, consecutive="", invoiceNumber="", balance=0, provider } = (item??{})
                                                return(
                                                    <Box key={_id} mb={1} >
                                                        <Stack direction={'row'} justifyContent={'space-between'} >
                                                            <Box fontWeight={'bold'} fontSize={'.875rem'} > #C{consecutive} { Boolean(invoiceNumber) ? <> - #FAC{invoiceNumber}</> : <></> } </Box>
                                                            <Box fontWeight={'bold'} fontSize={'.875rem'} > {currencyFormat(balance)} </Box>
                                                        </Stack>
                                                        <SelectBoxAndAmount
                                                            ProviderIDs={`${(provider?._id??"")}`}
                                                            index={index}
                                                            onChange={(payment) => UpdatePaymentList({ InvoiceID: _id, payment, ProviderID: (provider?._id??"") })}
                                                        />
                                                    </Box>
                                                )
                                            }) }
                                            <Box fontSize={'.875rem'} fontWeight={'bold'} textAlign={'end'} textTransform={'uppercase'} >
                                                pagar a <Box component={'span'} color={'primary.main'} > {groupName} </Box> {currencyFormat(totalizePurchases)}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Fragment>
                            )
                        }) }
                    </Box>
                    <Box>
                        <Button type='submit' size='large' disableElevation fullWidth variant='contained' disabled={(totalPayments <= 0)} >
                            Pagar - {currencyFormat(totalPayments)}
                        </Button>
                    </Box>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentWindow