import { Outlet } from "react-router-dom"

const Settings = () => {
    return(
        <>
            <Outlet />
        </>
    )
}

export default Settings