import { useTranslation } from "react-i18next"
import FixedCosts from "./FixedCosts"
import TabAppContext from "@component/TabAppContext"
import { useNavigate, useParams } from "react-router-dom"
import { routes } from "@util/routes"
import ComponentTitle from "@component/ComponentTitle"
// import PendingAccounts from "./PendingAccounts"
const { accountsPayable } = routes

const AccountsPayable = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { tab="fixed-costs" } = useParams()

    const tabs = [
        { label: t('Gastos fijos'), value: "fixed-costs", component: <FixedCosts /> },
        // { label: t('Cuentas por pagar'), value: "accounts-payable", component: <PendingAccounts /> },
    ]

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Gastos Fijos" />
            <TabAppContext
                onChange={(_, tab) => navigate(accountsPayable(tab))}
                tab={tab}
                tabs={tabs}
            />
        </>
    )
}

export default AccountsPayable