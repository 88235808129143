import ActionsList from '@component/ActionsList'
import { AddCircleOutlineIconRounded, Box, DashboardRoundedIcon, Grid, GroupsIconRounded, MoreVertRoundedIcon, TrendingUpRoundedIcon, useMediaQuery, useTheme } from '@component/UIComponents'
import Cashier from '@feature/Sales/components/Cashier'
import CustomerItem from '@feature/Sales/components/CustomerItem'
import SaleList from '@feature/Sales/components/SaleList'
import SearchBarPOS from '@feature/Sales/components/SearchBarPOS'
import TotalSales from '@feature/Sales/components/TotalSales'

const BackgroundPOS = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery( theme.breakpoints.down("sm") )
    return(
        <Box position={'absolute'} display={'flex'} width={'100vw'} height={'100vh'} >
            <Box padding={1} minHeight={'93vh'} width={'100vw'}>
                <Grid container spacing={2} >
                    {/* Start NavBar */}
                    <Grid xs={12} sx={{ padding: 0 }} >
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{ backgroundColor: (theme) => theme.palette.primary.main }} >
                            <Box display={'flex'} gap={1} alignItems={'center'} >
                                <Cashier balance={0} name={"Vendedor 1"} role={"Vendedor"} refUser={null} />
                            </Box>

                            <Box display={'flex'} alignItems={'center'} >
                                <ActionsList
                                    variant={isMobile ? 'mobileMenu' : 'button'}
                                    componentProps={{
                                        menuMobile: {
                                            props: {
                                                icon: <MoreVertRoundedIcon sx={{ color: "#FFFFFF" }} />
                                            }
                                        }
                                    }}
                                    options={[
                                        { title: "Crear producto", icon: <AddCircleOutlineIconRounded />, onClick: () => null, buttonProps: { variant: "contained", disableElevation: true } },
                                        { title: "Crear cliente", icon: <GroupsIconRounded />, onClick: () => null, buttonProps: { variant: "contained", disableElevation: true } },
                                        { title: "Informes", icon: <TrendingUpRoundedIcon />, onClick: () => null, buttonProps: { variant: "contained", disableElevation: true } },
                                        { title: "Menú principal", icon: <DashboardRoundedIcon />, onClick: () => null, buttonProps: { variant: "contained", disableElevation: true } },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    {/* End NavBar */}
                    {isMobile &&
                        <Grid xs={12} >
                            <TotalSales total={120000} totalProfitCurrency={24000} totalProfitPercentage={20} />
                            <CustomerItem customer={{ _id: 1, name: "Cliente", address: "Cra 128 #56-121 Espinal - Tolima" }} />
                        </Grid>
                    }

                    <Grid xs={isMobile ? 12 : 9} >
                        <SearchBarPOS open={false} />
                        <SaleList
                            list={[
                                {_id: 1, productId: 1, name: "COCA - COLA 3.5L", quantity: 1, salePrice: 68500, costPrice: 13700,},
                                {_id: 2, productId: 2, name: "POLO LA COSTE TALLA M", quantity: 1, salePrice: 21100, costPrice: 5064,},
                                {_id: 3, productId: 3, name: "TUBO PVC 3 PULGADAS", quantity: 1, salePrice: 30400, costPrice: 5472,},
                            ]}
                        />
                    </Grid>
                    {!isMobile &&
                        <Grid xs={3} >
                            <TotalSales total={120000} totalProfitCurrency={24000} totalProfitPercentage={20} />
                            <CustomerItem customer={{ _id: 1, name: "Cliente", address: "Cra 128 #56-121 Espinal - Tolima" }} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default BackgroundPOS