import NumericFormat from '@component/NumericFormat'
import { Box, Grid,  } from '@component/UIComponents'
import CitiesFinder from '@feature/Business/components/CitiesFinder'
import BranchFinder from '@feature/Customers/components/BranchFinder'
import BusinessTypeFinder from '@feature/Customers/components/BusinessTypeFinder'
import CustomerFinder from '@feature/Customers/components/CustomerFinder'
import NeighborhoodFinder from '@feature/Customers/components/NeighborhoodFinder'
import ZoneFinder from '@feature/Customers/components/ZoneFinder'
import QuickAccessDates from '@component/QuickAccessDates'
import DateFilter from '@component/Date'
import { transformArrayIntoString } from '@util/helpers'

const Filters = ({ branch="", businessType="", city="", customer="", endDate="", identificationNumber="", invoiceNumber="", neighborhood="", startDate="", zone="", setFiltersData=()=>null }) => {
    const transformArray = (value) => transformArrayIntoString(value, { mapFunction: (n) => (n?.name??""), separator: "," })
    const handleChangeDate = (n) => {
        if( Object.keys(n).length >= 1 ){
            Object.keys(n).forEach((key) => {
                setFiltersData(key, n[key])
            })
        }
    }

    return(
        <>
            <Box>
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <QuickAccessDates
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleChangeDate}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <DateFilter
                            size='small'
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleChangeDate}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NumericFormat
                            size="small"
                            value={invoiceNumber}
                            placeholder="Numero de factura"
                            fullWidth
                            prefix="#FAC "
                            thousandSeparator=""
                            onChange={({ floatValue }) => setFiltersData("invoiceNumber", floatValue)}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NumericFormat
                            size="small"
                            value={identificationNumber}
                            placeholder="N.I. Cliente"
                            fullWidth
                            prefix="#N.I: "
                            thousandSeparator=""
                            onChange={({ floatValue }) => setFiltersData("identificationNumber", floatValue)}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <CustomerFinder
                            size="small"
                            multiple
                            queryValue={customer}
                            onChange={(_, value) => setFiltersData("customer", transformArray(value))}
                        />
                    </Grid>

                    <Grid xs={6} >
                        <BranchFinder
                            size={"small"}
                            multiple
                            queryValue={branch}
                            onChange={(_, value) => setFiltersData("branch", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <BusinessTypeFinder
                            size={"small"}
                            multiple
                            queryValue={businessType}
                            onChange={(_, value) => setFiltersData("businessType", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NeighborhoodFinder
                            size="small"
                            multiple
                            queryValue={neighborhood}
                            onChange={(_, value) => setFiltersData("neighborhood", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <ZoneFinder
                            size="small"
                            multiple
                            queryValue={zone}
                            onChange={(_, value) => setFiltersData("zone", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <CitiesFinder
                            size="small"
                            multiple
                            queryValue={city}
                            onChange={(_, value) => setFiltersData("city", transformArray(value))}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default Filters