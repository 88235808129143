import Autocomplete from "@component/Autocomplete"
import NumericFormat from "@component/NumericFormat"
import { Box, Grid, Stack } from "@component/UIComponents"
import { currencyFormat } from "@util/currencyFormat"
import { useTranslation } from "react-i18next"
import { usePaymentWindow } from "../hooks/usePaymentWindow"

const SelectBoxAndAmount = ({ index=undefined, onChange=()=>null, ProviderIDs="" }) => {
    const { SelectBox, box, UpdateAmount, amount, boxes } = usePaymentWindow({ index, onChange, ProviderIDs })
    const { t } = useTranslation()


    return(
        <Grid container spacing={1} >
            <Grid xs={6} >
                <Autocomplete
                    size="small"
                    fullWidth
                    label="Cajas"
                    value={box}
                    onChange={(_, option) => SelectBox(option)}
                    options={boxes}
                    getOptionDisabled={(option) => ((option?.balance??0) < 1) }
                    renderOption={(props, option) => <li {...props} key={(option?._id??"")} >
                        <Stack fontWeight={'bold'} fontSize={'.800rem'} >
                            <Box> {t((option?.name??""))} </Box>
                            <Box> <Box color={'primary.main'} component={'span'} > {currencyFormat((option?.balance??0))} </Box> </Box>
                        </Stack>
                    </li>}
                />
            </Grid>
            <Grid xs={6} >
                <NumericFormat
                    automaticErase
                    id={Boolean(index) ? `paymentAmount${index}` : "paymentAmount"}
                    disabled={!Boolean(box?._id)}
                    size="small"
                    fullWidth
                    label="Monto"
                    value={amount}
                    onChange={({ floatValue }) => UpdateAmount((floatValue??0))}
                />
            </Grid>
        </Grid>
    )
}

export default SelectBoxAndAmount