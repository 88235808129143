
import { MenuIconRounded, Menu, Button, MenuItem, } from "@component/UIComponents"
import TransformOptions from "./hooks/TransformOptions"
import { usePOSMenu } from '../../hooks/usePOS'
import {useState} from 'react'
import CustomerForm from '@feature/Customers/CustomerForm'
import ProductForm from '@feature/Inventory/Products/ProductForm'

import * as React from 'react';
import CashTransfer from "@feature/CashTransfer"
import VariableExpense from "@feature/VariableExpense"
import useGetButtons from "./hooks/useGetButtons"

const CashierActions = ({ variantListButton="", refUser=null, balance=0, setFieldValue=()=>null }) => {
    const [showForms, setShowForms] = useState({ registerClient: false, registerProduct: false })
    const [onOpenTransfer, setOnOpenTransfer] = useState(false)
    const [onOpenExpense, setOnOpenExpense] = useState(false)
    const { optionActions } = usePOSMenu()
    const optionsActions = optionActions({
                        onRecordCustomer: () => setShowForms((prevState) => ({...prevState, registerClient: true})),
                        onRecordProduct: () => setShowForms((prevState) => ({...prevState, registerProduct: true})),
                    })
    const newOptionsActions = TransformOptions(optionsActions)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenTransfer = (value) =>{
        setOnOpenTransfer(value)
    } 
    const handleOpenExpense = (value) =>{
        setOnOpenExpense(value)
    } 

    const buttons = useGetButtons({ setOnOpenTransfer, onOpenTransfer, balance, setOnOpenExpense, onOpenExpense });
    const finalOptions = [...buttons, ...newOptionsActions]

    return(
        <>
        <Button
        onClick={handleClick}
        >
            <MenuIconRounded sx={{ color: "#FFFFFF", fontSize: ".875rem" }} />
        </Button>
        <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
            {finalOptions.map(item => {
                return(
                    <MenuItem onClick={()=>setAnchorEl(false)} >
                        {item.button}
                    </MenuItem>
                )
            })}
        </Menu>
        <VariableExpense variantListButton={''} onOpen={onOpenExpense} handleOpen={handleOpenExpense} variantBox={'cashier'} fixedBox={refUser??null} />
        <CashTransfer title={'Traslado de caja POS'} name1={'Caja Destino'} name2={'Disponible en caja: '} variantListButton={''} onOpen={onOpenTransfer} handleOpen={handleOpenTransfer} refUser={refUser} boxBalance={balance} boxRef={refUser}/>
            
            <CustomerForm
                open={showForms.registerClient}
                defaultValues={null}
                onClose={() => setShowForms((prevState) => ({...prevState, registerClient: false}))}
                actionWhenClosingFormWithSuccessfulRegistration={(customer) => setFieldValue("customer", customer)}
            />
            <ProductForm
                open={showForms.registerProduct}
                onClose={() => setShowForms((prevState) => ({...prevState, registerProduct: false}))}
            />
        </>
    )
}

export default CashierActions