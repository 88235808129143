import SuperDataGrid from "@component/SuperDataGrid"
import { useMassiveProductEditionDataGridController } from "./hooks/useMassiveProductEditionDataGridController"
import { useMassiveProductEditionController } from "./hooks/useMassiveProductEditionController"
import EditingWindow from "./components/EditingWindow"
import { Button, Grid, SaveRoundedIcon } from "@component/UIComponents"
import ConfirmationOfProductChanges from "./components/ConfirmationOfProductChanges"

const MassiveProductEdition = ({ data=[] }) => {
    const { componentProps, handleCellClick, uploadEditedProduct, onConfirmProductChanges, removeEditedProduct } = useMassiveProductEditionController()
    const { selectedCell, selectedProduct, editedProducts, confirmProductChanges } = componentProps
    const { columns, rows } = useMassiveProductEditionDataGridController({ list: data })

    return(
        <Grid container >
            { editedProducts.length >= 1 &&
                <Grid xs={12} >
                    <Button startIcon={<SaveRoundedIcon />} onClick={() => onConfirmProductChanges(true)} >
                        Guardar {editedProducts.length >= 2 ? `cambios en ${editedProducts.length} productos` : `cambio en ${editedProducts.length} producto`}
                    </Button>
                </Grid>
            }
            <Grid xs={12} >
                <SuperDataGrid
                    columns={columns({
                        onCellClick: (field, value) => handleCellClick(field, value)
                    })}
                    rows={rows()}
                    filterBy={(param) => `${(param?.name??"")}`}
                />
            </Grid>
            <EditingWindow
                field={selectedCell}
                onClose={() => handleCellClick()}
                open={Boolean(selectedCell)}
                product={selectedProduct}
                onSaveChanges={uploadEditedProduct}
            />
            <ConfirmationOfProductChanges
                open={confirmProductChanges}
                onClose={() => onConfirmProductChanges(false)}
                products={editedProducts}
                onRemoveEditedProduct={removeEditedProduct}
            />
        </Grid>
    )
}

export default MassiveProductEdition