import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useGetGeneralBalanceOfTheBusinessQuery } from "@query/Business"
import { currencyFormat } from "@util/currencyFormat"
import { useMemo } from "react"

const CashierReport = () => {
    const { data } = useGetGeneralBalanceOfTheBusinessQuery()
    const { assets={} } = useMemo(() => (data?.payload??{}), [data])
    const { cashiers={} } = (assets??{})
    const rows = useMemo(() => {
        let newRows = (cashiers?.results??[]).map((item, index) => {
            let currentItem = {...(item??{})}
            currentItem._id = (index+1)
            return currentItem
        })
        newRows.push({
            _id: (newRows.length+1),
            name: "TOTAL",
            amount: (cashiers?.amount??0)
        })
        return newRows
    }, [cashiers])
    return(
        <Grid container >
            <Grid xs={12} >
                <SuperDataGrid
                    useSearchBar={false}
                    columns={[
                        {maxWidth: 200, minWidth: 200, field: "name", headerName: "Cajero", renderCell: ({ row }) => (row?.name??"") },
                        {maxWidth: 200, minWidth: 200, field: "amount", headerName: "Saldo", renderCell: ({ row }) => currencyFormat((row?.amount??0)) },
                    ]}
                    rows={rows}
                />
            </Grid>
        </Grid>
    )
}

export default CashierReport