import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useURLParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const addQuery = (queries) => {
        setSearchParams(prevSearchParams => {
            const updatedSearchParams = new URLSearchParams(prevSearchParams);
            Object.entries(queries).forEach(([name, value]) => {
                updatedSearchParams.set(name, value);
            });
            return Object.fromEntries(updatedSearchParams.entries());
        });
    }
    const delQueries = (names) => {
        names.map((name) => searchParams.delete(name))
        setSearchParams(searchParams)
    }
    const delAllQueries = (names) => {
        let keys = Object.fromEntries(searchParams)
        keys = Object.keys(keys)
        for(let key of keys){
            searchParams.delete(key)
        }
        setSearchParams(searchParams)
    }

    const getQuery = (name) => searchParams.get(name)

    const getQueries = (keys=[]) => {
        let queries = {}
        keys.forEach((value) => {
            let currentQueries = {...queries}
            const getParam = searchParams.get(value)
            currentQueries[value] = Boolean(getParam) ? getParam : undefined
            queries = currentQueries
        })
        return queries
    }

    const getAllQueries = () => (Object.fromEntries(searchParams)??{})

    return {
        addQuery,
        delQueries,
        getQuery,
        getQueries,
        delAllQueries,
        getAllQueries,
    }
}