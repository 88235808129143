import Menu from '@component/Menu'
import { Box, List, ListItemText, MenuItem, styled } from '@component/UIComponents'
import { useGetCustomerDebts } from '@feature/Customers/hooks/useCustomers'
import DialogInvoice from '@feature/Reports/Invoice/components/DialogInvoice'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { useMemo, useState } from 'react'

const BoxStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== "quantity"
})(({ quantity, theme }) => ({
    display: "inline-flex",
    color: quantity >= 1 ? "#FFFFFF" : theme.palette.grey[700],
    ...((quantity >= 1) ? {
        '&: hover': {
            textDecoration: "underline",
            cursor: "pointer"
        }
    } : {})
}))

const InvoicesOwedClient = ({ customerId=null }) => {
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const { getDateFormats } = usePanel()
    const [openList, setOpenList] = useState(null)
    const { customerDebts=[], invoicesFound=0, totalDebt=0 } = useGetCustomerDebts({ customerId }, { refetchOnMountOrArgChange: true, skip: !Boolean(customerId) })
    const text = useMemo(() => {
        if( invoicesFound === 0 ){
            return "No debe facturas"
        }else if( invoicesFound === 1 ){
            return `Debe ${invoicesFound} factura por monto de ${currencyFormat(totalDebt)}`
        }else{
            return `Debe ${invoicesFound} facturas por un monto total de ${currencyFormat(totalDebt)}`
        }
    }, [invoicesFound, totalDebt])

    const handleOpenListClick = (target) => {
        if( invoicesFound >= 1 ){
            setOpenList(target)
        }
    }

    return(
        <>
            <BoxStyled quantity={invoicesFound} onClick={({ currentTarget }) => handleOpenListClick(currentTarget)} >
                {text}
            </BoxStyled>
            <Menu anchorEl={openList} open={Boolean(openList)} onClose={() => setOpenList(null)} >
                <List>
                    { (customerDebts??[]).map((invoice, index) => {
                        return(
                            <MenuItem key={index} onClick={() => setSelectedInvoice((invoice?._id??null))} >
                                <ListItemText
                                    primary={<>
                                        <Box fontSize={'.875rem'} lineHeight={1.2} fontWeight={'bold'} >
                                            <Box sx={{ color: (theme) => theme.palette.primary.main }} > #FAC{(invoice?.consecutive??0)} </Box>
                                            <Box> Registrada el: { getDateFormats((invoice?.createdAt??undefined)).ddmmyyyyhhmm} </Box>
                                            <Box> Total: {currencyFormat((invoice?.total??0))} </Box>
                                            <Box> Saldo: {currencyFormat((invoice?.balance??0))} </Box>
                                        </Box>
                                    </>}
                                />
                            </MenuItem>
                        )
                    }) }
                </List>
            </Menu>
            <DialogInvoice
                open={Boolean(selectedInvoice)}
                saleRef={selectedInvoice}
                onClose={() => setSelectedInvoice(null)}
            />
        </>
    )
}

export default InvoicesOwedClient