import Form from '@component/Form'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@component/UIComponents'
import { useEffect, useState } from 'react'
import ProductFinder from '../../components/ProductFinder'
import ProviderFinder from '@feature/Providers/components/ProviderFinder'
import PackagingFinder from '@feature/Inventory/Packaging/components/PackagingFinder'
import CategoriesFinder from '@feature/Inventory/Categories/components/CategoriesFinder'
import NumericFormat from '@component/NumericFormat'
import PriceListForm from '../../components/PriceListForm'

const EditingWindow = ({ open=false, field="", product=null, onClose=()=>null, onSaveChanges=()=>null }) => {
    const [newValue, setNewValue] = useState(null)

    const handleChangeValue = (val) => setNewValue(val)

    const handleSaveChanges = () => {
        let currentProduct = {...product}
        currentProduct[field] = newValue
        onSaveChanges(currentProduct)
        onClose()
    }

    useEffect(() => {
        if( open === false ){
            setNewValue(null)
        }
    }, [open])

    return(
        <Dialog open={open} onClose={onClose} fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} >
            <Form onSubmit={handleSaveChanges} >
                <DialogContent>
                    { (field === "name") &&
                        <TextField
                            fullWidth
                            value={(newValue??(product?.name??""))}
                            onChange={({ target }) => handleChangeValue((target?.value??""))}
                        />
                    }
                    { (field === "cost") &&
                        <NumericFormat
                            fullWidth
                            value={(newValue??(product?.cost??""))}
                            onChange={({floatValue}) => handleChangeValue((floatValue??0))}
                            automaticErase
                        />
                    }
                    { (field === "prices") &&
                        <>
                            {(Array.isArray(newValue) ? (newValue??[]) : (product?.prices??[])).map((item, index) => {
                                return(
                                    <PriceListForm
                                        key={index}
                                        cost={(product?.cost??0)}
                                        {...item}
                                        onChange={(price) => {
                                            const currentPrices = Array.isArray(newValue) ? [...newValue] : [...(product?.prices??[])]
                                            currentPrices[index] = price
                                            handleChangeValue((currentPrices??[]))
                                        }}
                                    />
                                )
                            })}
                        </>
                    }
                    { (field === "stock") &&
                        <NumericFormat
                            fullWidth
                            value={(newValue??(product?.stock??""))}
                            onChange={({floatValue}) => handleChangeValue((floatValue??0))}
                            automaticErase
                            prefix=""
                        />
                    }
                    { (field === "lowStockAlert") &&
                        <NumericFormat
                            fullWidth
                            value={(newValue??(product?.lowStockAlert??""))}
                            onChange={({floatValue}) => handleChangeValue((floatValue??0))}
                            automaticErase
                            prefix=""
                        />
                    }
                    { (field === "skuCode") &&
                        <TextField
                            fullWidth
                            value={(newValue??(product?.skuCode??""))}
                            onChange={({target}) => handleChangeValue((target?.value??""))}
                        />
                    }
                    { (field === "category") &&
                        <CategoriesFinder
                            size="small"
                            value={(newValue??(product?.category??null))}
                            onChange={(_, val) => handleChangeValue((val??null))}
                        />
                    }
                    { (field === "packaging") &&
                        <PackagingFinder
                            helperText=""
                            size="small"
                            value={(newValue??(product?.packaging??null))}
                            onChange={(_, val) => handleChangeValue((val??null))}

                        />
                    }
                    { (field === "provider") &&
                        <ProviderFinder
                            size="small"
                            value={(newValue??(product?.provider??null))}
                            onChange={(_, val) => handleChangeValue((val??null))}

                        />
                    }
                    { (field === "productParent") &&
                        <ProductFinder
                            label="Producto que lo compone"
                            variant="single"
                            size="small"
                            value={(newValue??(product?.productParent??null))}
                            inputVariant="outlined"
                            onChange={(_, val) => handleChangeValue((val??null))}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button type='submit' >
                        Guardar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default EditingWindow