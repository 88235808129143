import { AccountBalanceRoundedIcon, CurrencyExchangeRoundedIcon, LocalAtmRoundedIcon } from "@component/UIComponents"
import Consignment from "../components/TypesPaymentForms/Consignment"
import { useURLParams } from "@hook/useURLParams"
import { useMemo, useCallback } from "react"
import PayFullAmount from "@component/PayFullAmount"
import dayjs from "dayjs"
import { Swal } from "@util/swal"
import { currencyFormat } from "@util/currencyFormat"
import { useManageFormData, usePanel } from "@hook/usePanel"
import { yup } from "@hook/useFormik"
import VariableExpense from "@feature/VariableExpense"
import CashTransfer from "@feature/CashTransfer"
import { useValidatePermission } from "@hook/useValidatePermission"

export const useCashierMenu = () => {
    const { validateArrayByPermissionOrder } = useValidatePermission()

    let paymentTypes = [
        { title: "Efectivo", onClick: () => null, icon: <LocalAtmRoundedIcon />, value: "cash", component: (props={}) => <PayFullAmount {...props} id={'cash'} /> },
        { title: "Consignación", onClick: () => null, icon: <AccountBalanceRoundedIcon />, value: "consignment", component: (props={}) => <Consignment {...props} id={'consignment'} /> },
        { title: "Anticipo", onClick: () => null, icon: <CurrencyExchangeRoundedIcon />, value: "advance", component: (props={}) => <PayFullAmount {...props} id={'advance'} /> },
    ]
    paymentTypes = paymentTypes.map((n, index) => ({...n, _id: index}))

    const cashShares = useCallback((props={}) => {
        const { variantListButton=undefined, variantBox="cashier", fixedBox=undefined, boxBalance=0, refUser=null } = props
        let r = [
            { button: <VariableExpense variantListButton={variantListButton} variantBox={variantBox} fixedBox={fixedBox} /> },
            { button: <CashTransfer refUser={refUser} variantListButton={variantListButton} boxBalance={boxBalance} boxRef={fixedBox} title='Traslado de caja POS' name1='Caja de destino' name2='Disponible en caja POS:'/> },
            // { title: "Solicitar un traslado", onClick: () => null, icon: <CallReceivedRoundedIcon /> },
        ].map((n, index) => ({...n, _id: (index+1)}))
        r = validateArrayByPermissionOrder(["register_variable_expends", "register_cashier_transfer"], r)
        return r
    }, [validateArrayByPermissionOrder])

    return {
        paymentTypes,
        cashShares
    }
}

export const useCashierKeyboardShortcuts = (params={}) => {
    let { undefinedInvoiceList=[] } = params
    const { getQuery, addQuery } = useURLParams()
    const listPosition = getQuery("listPosition")
    let { paymentTypes } = useCashierMenu()
    paymentTypes = paymentTypes.map((n) => (n?.value??""))

    const keyboardShortcuts = useCallback((event) => {
        const { code="" } = event
        const currentUndefinedInvoiceList = [...undefinedInvoiceList]
        let FCode = [1, 2, 3, 4]
        let lockKeys = [ 'ArrowUp', 'ArrowDown', 'ControlLeft', 'ControlRight', 'Backquote', 'AltLeft', 'AltRight', ...FCode.map((number) => `F${number}`) ].some(n => (n === code))
        if( lockKeys ){
            event.preventDefault()
            event.stopPropagation()
        }
        let swal2 = document.getElementsByClassName("swal2-container")
        swal2 = (swal2[0]??null)
        let activeElement = document.activeElement
        let activeElementId = (activeElement?.id??"")
        let activeElementIsValid = paymentTypes.some(n => n === activeElementId )

        if( !Boolean(activeElementId) ){
            if( code === "ArrowUp" ){
                let newPosition = parseInt(listPosition)-1
                let exist = currentUndefinedInvoiceList[newPosition]
                addQuery({ listPosition: !Boolean(exist) ? 0 : newPosition })
            }
            if( code === "ArrowDown" ){
                let newPosition = parseInt(listPosition)+1
                let exist = currentUndefinedInvoiceList[newPosition]
                addQuery({ listPosition: !Boolean(exist) ? currentUndefinedInvoiceList.length-1 : newPosition })
            }
        }
        if( code === 'ControlLeft' || code === 'ControlRight' ){
            if( activeElementIsValid ){
                let elem = document.getElementById(activeElementId)
                if( Boolean(elem) ) elem.focus()
            }else{
                let elem = document.getElementById("cash")
                if( Boolean(elem) ) elem.focus()
            }
        }
        if( code === "AltLeft" || code === "AltRight" ){
            if( activeElementIsValid ){
                let elem = document.getElementById("isDelivery")
                if( Boolean(elem) ) elem.focus()
            }
        }
        if( code === 'Backquote' ){
            if( Boolean(activeElement) ){
                activeElement.blur()
            }
        }
        if( code === 'NumpadEnter' || code === 'Enter' ){
            setTimeout(() => {
                if( Boolean(swal2) ){
                    event.preventDefault()
                    event.stopPropagation()
                    let elem = document.getElementsByClassName("swal2-confirm")
                    elem = elem[0]??null
                    if( Boolean(elem) ) elem.click()
                }
            }, 150)
        }
        if( code === 'NumpadDecimal' ){
            if( Boolean(swal2) ){
                event.preventDefault()
                event.stopPropagation()
                let elem = document.getElementsByClassName("swal2-cancel")
                elem = elem[0]??null
                if( Boolean(elem) ) elem.click()
            }
        }
        for( let index in FCode ){
            const number = FCode[index]
            if( code === `F${number}` ){
                let elem = document.getElementById(`payment_${index}`)
                if( Boolean(elem) )elem.click()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listPosition])

    return {
        keyboardShortcuts
    }
}

export const useCashierPaymentFormik = (params={}) => {
    let { defaultValues=undefined } = params

    let transformDefaultValues = useMemo(() => {
        let currentDefaultValues = {...defaultValues}
        if( Boolean(currentDefaultValues?._id) ){
            return {
                saleId: (currentDefaultValues?._id??""),
                payments: [],
                delivery: (currentDefaultValues?.delivery?.delivery??null),
                deliveryPaymentMethod: (currentDefaultValues?.delivery?.deliveryPaymentMethod??""),
                deliveryAmount: (currentDefaultValues?.delivery?.deliveryAmount??0),
                observation: (currentDefaultValues?.observation??""),
            }
        }else{
            return undefined
        }
    }, [defaultValues])

    const initialValues = useMemo(() => ({ saleId: "", payments: [], delivery: null, deliveryPaymentMethod: "", deliveryAmount: 0, observation: "", creditExpirationDate: dayjs().add(1, "day").format("MM-DD-YYYY"), collectCreditNote: null }), [])
    const schema = {
        saleId: yup.string().required("El identificador del producto no se cargo de forma correcta, cierre la ventana e intente nuevamente."),
    }
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultIdentifier: "saleId",
        defaultValues: transformDefaultValues,
        initialValues,
        schema,
    })

    return { values, setFieldValue, resetValues, validateValues, schema }

    // const paymentSchema = yup.object().shape({
    //     amount: yup.number(),
    //     wallet: yup.string(),
    //     refId: yup.string().nullable(true),
    // })

    // const initialValues = useMemo(() => {
        // let val =  {
        //     saleId: "",
        //     payments: [],
        //     isDelivery: false,
        //     deliveryName: "",
        //     deliveryAmount: 0,
        //     observation: "",
        //     creditExpirationDate: dayjs().add(1, "day").format("MM-DD-YYYY"),
        // }
    //     if( Boolean(defaultValues?._id) ){
    //         val = {...defaultValues}
    //     }
    //     return val
    // },[defaultValues])

    // const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = useFormik({
    //     onSubmit,
    //     initialValues,
    //     schema: {
    //         saleId: yup.string().required("No se pudo encontrar el identificador de la factura, cierre la ventana he intente nuevamente."),
    //         payments: yup.array().of(paymentSchema),
    //         isDelivery: yup.boolean(),
    //         deliveryName: yup.string().when('isDelivery', {
    //             is: true,
    //             then: () => yup.string().required('El nombre del domiciliario es un campo obligatorio.'),
    //             otherwise: () => yup.string(),
    //         }),
    //         deliveryAmount: yup.string(),
    //         creditExpirationDate: yup.date().required("La fecha de expiración no se cargo correctamente, cierre la ventana he intente nuevamente."),
    //         observation: yup.string(),
    //     }
    // })

    // const addNewPayment = (payment) => {
    //     const { payments } = values
    //     let currentPayments = [...payments]
    //     currentPayments.push(payment)
    //     setFieldValue("payments", currentPayments)
    // }

    // const removePayment = (index) => {
    //     const { payments } = values
    //     let currentPayments = [...payments]
    //     currentPayments.splice(index, 1)
    //     setFieldValue("payments", currentPayments)
    // }

    // const paymentsData = useMemo(() => {
    //     const { payments } = values
    //     let currentPayments = [...payments]
    //     const totalPayments = currentPayments.reduce((acc, params) => acc+(params?.amount??0),0)
    //     let balance = (totalValueInvoice-totalPayments)
    //     balance = ((balance <= 0) ? 0 : balance)
    //     let returned = (totalPayments-totalValueInvoice)
    //     returned = ((returned <= 0) ? 0 : returned)
    //     currentPayments.unshift({ wallet: "Valor de la factura", amount: totalValueInvoice })
    //     currentPayments.push({ wallet: "Total abonos", amount: totalPayments })
    //     currentPayments.push({ wallet: "Saldo", amount: balance })
    //     currentPayments.push({ wallet: "Devuelve", amount: returned })
    //     currentPayments = currentPayments.map((n, index) => ({...n, _id: index}))
    //     return currentPayments
    // }, [values, totalValueInvoice])

    // return { errors, touched, setFieldValue, handleSubmit, values, addNewPayment, removePayment, paymentsData, resetForm }
}

export const useCashier = () => {
    const { getDateFormats } = usePanel()

    const alertBeforeSendingPayments = async (params={}) => {
        try {
            const { payments=[], invoiceTotalAmount=0, creditExpirationDate } = params
            const totalPayments = payments.reduce((acc, value) => (value?.amount??0)+acc,0)
            const quantityPayments = (payments.length >= 2) || (payments.length === 0) ? `${payments.length} pagos parciales` : "1 pago parcial"
            const balance = (invoiceTotalAmount-totalPayments)
            const title = balance <= 0 ? "PARCIAL" : `CRÉDITO`
            const text = totalPayments >= invoiceTotalAmount ? `Esta por registrar la factura con ${quantityPayments} por el monto total de la factura` : `Esta por registrar la factura con ${quantityPayments} dejando un saldo pendiente de ${currencyFormat(balance)} que debe pagarse antes del ${getDateFormats(creditExpirationDate).fullDate}`
            const swal = await Swal.fire({
                icon: "info",
                title: `${title}`,
                text: `${text}, ¿desea continuar con la operación?`,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showCancelButton: true,
            })
            if( swal.isConfirmed ){
                console.log(params)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        alertBeforeSendingPayments
    }
}
