import TabAppContext from "@component/TabAppContext"
import { Grid } from "@component/UIComponents"
import { useGetBanks } from "./hooks/useBanks"
import { useTranslation } from "react-i18next"
import { useCallback, useState, useEffect, useMemo } from "react"
import BoxesTemplate from "@feature/MasterBox/components/BoxesTemplate"

const Bank = () => {
    const [tab, setTab] = useState(undefined)
    const { t } = useTranslation()
    const { resp, isLoading } = useGetBanks()
    const box = useMemo(() => ((resp?.find((n) => n._id === tab)??null)), [tab, resp])

    let tabs = useMemo(() => {
        return resp.map((n) => ({ label: t(n?.name??""), value: n._id, component: <BoxesTemplate variant="bank" boxName={(box?.name??"")} refId={(box?._id??null)} boxBalance={(box?.balance??0)} title={'Traslado de caja'} name1={'Caja de destino'} name2={`Disponible en ${box ? box.name : ''}`} /> }))
    },[t, resp, box])

    const loadDefaultTab = useCallback(() =>{
        if( tabs.length >= 1 ){
            if( !Boolean(tab) || tab === "0" ){
                setTab((tabs[0]?.value??undefined))
            }
        }else{
            setTab("0")
        }
    }, [tabs, tab])

    useEffect(() => {
        loadDefaultTab()
    }, [loadDefaultTab])

    return(
        <>
            { isLoading ?
                <></>
                :
                <>
                    {tabs.length <= 0 ?
                        <>
                            <TabAppContext
                                tabs={[{ label: "No hay bancos creados", value: "0", component: <BoxesTemplate variant="bank" boxName={""} refId={null} boxBalance={0} /> }]}
                                tab={tab}
                                onChange={(event, value) => setTab(value)}
                            />
                        </>
                        :
                        <Grid container >
                            <Grid xs={12} >
                                {(Boolean(tab) && (tabs.length >= 1)) &&
                                    <TabAppContext
                                        tabs={tabs}
                                        tab={tab}
                                        onChange={(event, value) => setTab(value)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    }
                </>

            }
        </>
    )
}

export default Bank