import { AddCircleOutlineIconRounded, GroupsIconRounded, ShoppingCartRoundedIcon, TrendingUpRoundedIcon } from "@component/UIComponents"
import { useFormik, yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useValidatePermission } from "@hook/useValidatePermission"
import { useRecordInvoiceInformationAndPaymentsMutation, useRecordSaleMutation, useRegisterNewPaymentForSaleMutation, useUpdateInvoiceInformationMutation } from "@query/Sales"
import { calculateProfitMargin } from "@util/currencyFormat"
import { db } from "@util/db"
import { actionOnElement, asynchronousResponseHandler, createCookie, elementFocus, getCookie, preventAction } from "@util/helpers"
import { routes } from "@util/routes"
import { Toast } from "@util/swal"
import { Utils } from "@util/Utils"
import { Validate } from "@util/Validate"
import dayjs from "dayjs"
import { useLiveQuery } from "dexie-react-hooks"
import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
const { report } = routes

export const usePOS = (params={}) => {
    const { list=[] } = params
    const navigate = useNavigate()
    const { sales } = routes

    const delItemList = (itemId="", setFieldValue=undefined) => {
        const findIndex = list.findIndex((n) => n.productId === itemId)
        if( findIndex >= 0 ){
            let currentList = [...list]
            currentList.splice(findIndex, 1)
            if( Boolean(setFieldValue) ){
                setFieldValue("products", currentList)
            }
        }
    }

    const clearBillingArea = useCallback(({resetPOS=()=>null}) => {
        navigate(sales({ saleId: "" }))
        resetPOS()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    return {
        delItemList,
        clearBillingArea
    }
}

export const useProductPOSFormik = (params={}) => {
    const { defaultValues={}, onSubmit } = params
    const initialValues = useMemo(() => {
        let val =  {
            costPrice: 0,
            name: "",
            productId: "",
            quantity: 1,
            salePrice: 0,
            typePrice: "",
            observation: "",
            pricePosition: 0,
        }
        if( Boolean(defaultValues?.name) ){
            val = {...defaultValues}
        }
        return val
    },[defaultValues])

    const schema = {
        costPrice: yup.number().min(1, "El costo debe ser superior a 1").required("No fue posible cargar el costo del producto, cierre la ventana y vuelva a cargar el producto."),
        name: yup.string().required("No fue posible cargar el nombre del producto, cierre la ventana y vuelva a cargar el producto."),
        productId: yup.string().required("Ocurrió un problema al cargar el identificador del producto, cierre la ventana y vuelva a cargar el producto."),
        quantity: yup.number().min(0.001, "La cantidad de producto debe ser superior a 0.001.").required("La cantidad es un campo requerido."),
        salePrice: yup.number().required("El precio de venta es requerido.").test('', 'El precio de venta debe ser superior al costo.', function (value) {
            const costValue = this.resolve(yup.ref('costPrice'));
            return value >= costValue;
        }),
        pricePosition: yup.number().min(0, "Ocurrió un problema al determinar la posición del precio").max(2, "Ocurrió un problema al determinar la posición del precio").required("Ocurrió un problema al determinar la posición del precio"),
    }

    const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = useFormik({
        onSubmit,
        initialValues,
        schema
    })

    return { errors, touched, setFieldValue, handleSubmit, values, resetForm, schema }
}

export const usePOSFormik = (params={}, config={}) => {
    const { schema:productPOSSchema } = useProductPOSFormik()
    const { defaultValues=undefined } = config
    const initialValues = useMemo(() => ({ customer: null, products: [], total: 0, totalProfitPercentage: 0, totalProfitCurrency: 0, creditExpirationDate: dayjs().add(1, "day").format("MM-DD-YYYY") }), [])
    const schema = {
        customer: yup.object().nullable().shape({
            _id: yup.string()
        }),
        products: yup.array().of(yup.object().shape(productPOSSchema)).min(1, "Seleccione al menos un producto").required(),
        total: yup.number().required(),
        totalProfitPercentage: yup.number().required(),
        totalProfitCurrency: yup.number().required(),
    }

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema,
    })

    const { products=[] } = (values??{})

    const updateTotal = useCallback(() => {
        const total = Utils.sumArray(products, (item) => Utils.multiply((item?.salePrice??0), (item?.quantity??0)) )
        const totalCost = Utils.sumArray(products, (item) => Utils.multiply((item?.costPrice??0), (item?.quantity??0)) )
        const profit = (total-totalCost)
        let margin = Utils.margin(profit, total)
        margin = Validate.isNumber(margin) ? margin : 0
        setFieldValue("total", total)
        setFieldValue("totalProfitCurrency", profit)
        setFieldValue("totalProfitPercentage", margin)
    }, [products, setFieldValue])

    useEffect(() => {
        updateTotal()
    }, [updateTotal])
    return { values, setFieldValue, resetValues, validateValues }
}

export const usePOSMenu = (params={},) => {
    const { validateArrayByPermissionOrder, executeFunctionWithPermission } = useValidatePermission()
    const navigate = useNavigate()
    const { state } = useLocation()

    
    const { registerInvoice=undefined, invoiceRef=null, invoiceCharged=null } = params
    let lastInvoice = useLiveQuery(() => db.tmp.get(1))
    lastInvoice = (lastInvoice?.lastRegisteredSalesInvoice??null)
    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });

    let optionActions = useCallback((props={}) => {
        const { onRecordProduct=()=>null, onRecordCustomer=()=>null } = props
        let r = [
            { title: "Crear producto", onClick: () => onRecordProduct(), icon: <AddCircleOutlineIconRounded />, },
            { title: "Crear cliente", onClick: () => onRecordCustomer(), icon: <GroupsIconRounded /> },
        ].map((n, index) => ({...n, _id: index}))
        r = validateArrayByPermissionOrder(["register_product", "register_client"], r)
        r.push({
            title: "Ventas",
            onClick: () => navigate(report("sales")),
            icon: <TrendingUpRoundedIcon />
        })
        r.push({
            title: "Pedidos",
            onClick: () => navigate(`/report/sales?invoiceType=order&startDate=${dayjs().format("MM-DD-YYYY")}&endDate=${dayjs().format("MM-DD-YYYY")}&list=sales`),
            icon: <TrendingUpRoundedIcon />
        })
        r.push({
            title: "Pagar factura",
            onClick: () => navigate(`/purchase/report/purchases`),
            icon: <ShoppingCartRoundedIcon />
        })
        // r.push({ title: "Menú principal", onClick: () => navigate(home()), icon: <DashboardRoundedIcon /> },)
        return r
    }, [validateArrayByPermissionOrder, navigate])

    let footerActions = useCallback((props={}, config={}) => {
        const { productList=[], customer=null, total=0 } = props
        const { resetPOS=()=>null, loading=false, isMobile=false } = config
        
        const sx = {
            '&:hover':{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
            }
        }
        let resp = [
            {
                title: isMobile ? "Guardar" : "Guardar para después (F12)",
                onClick: () => {
                    if( productList.length <= 0 ){
                        Toast.fire({
                            icon: "warning",
                            text: "No es posible guardar una lista vacía."
                        })
                        return
                    }
                    if( Boolean(invoiceCharged) ){
                        Toast.fire({
                            icon: "warning",
                            text: "No es posible guardar una factura que ya se encuentra registrada."
                        })
                        return
                    }
                    let currentProductList = [...productList]
                    let savedProductList = (getCookie("savedProductList", { convertJSONstringify: true })??[])
                    savedProductList.push({ customer, products: currentProductList, total, createdAt: dayjs().toDate() })
                    savedProductList = savedProductList.map((n, index) => ({...n, _id: (index+1)}))
                    createCookie({
                        name: "savedProductList",
                        value: savedProductList,
                        convertJSONstringify: true
                    })
                    Toast.fire({
                        icon: "success",
                        text: "La factura se guardo exitosamente."
                    })
                    resetPOS()
                },
                sx,
                buttonProps: {
                    id: "saveInvoice",
                    disabled: (productList.length <= 0) || Boolean(invoiceCharged),
                    loading,
                }
            },
            ...(!isMobile ? [{
                title: isMobile ? "Limpiar" : "Limpiar (SUPR)",
                onClick: () => preventAction({
                    text: "¿Desea limpiar el area de facturación?",
                    onSubmit: () => {
                        resetPOS()
                    }
                }),
                sx,
                buttonProps: {
                    id: "clearBilling",
                    disabled: (productList.length <= 0),
                    loading,
                }
            }] : []),
            ...(isMobile ? [] : [
                {
                    title: isMobile ?  Boolean(invoiceCharged) ? "Actualizar" : "Pagar" : Boolean(invoiceCharged) ? "Actualizar (F4)" : "Registrar venta (F4)",
                    onClick: () => registerInvoice(),
                    sx,
                    buttonProps: {
                        id: "recordSale",
                        disabled: (productList.length <= 0),
                        loading,
                    }
                },
            ]),
            ...(!isMobile ? [] : []),
            {
                title: Boolean(invoiceCharged) ? (isMobile ? "Actualizar Imp." : "Actualizar e imprimir (F6)") : (isMobile ? "Guardar imp" : "Registrar venta e imprimir (F6)"),
                onClick: () => registerInvoice({ print: true }),
                sx,
                buttonProps: {
                    id: "recordSaleAndPrint",
                    disabled: (productList.length <= 0),
                    loading,
                }
            },
            {
                title: isMobile ? "Pedido" : "Guardar como cotización (F5)",
                onClick: () => {
                    if(Boolean(invoiceCharged)){
                        Toast.fire({
                            icon: "warning",
                            text: "No es posible cambiar el estado de la venta."
                        })
                    }else{
                        registerInvoice({ invoiceType: "price" })
                    }
                },
                sx,
                buttonProps: {
                    id: "recordQuote",
                    disabled: (productList.length <= 0) || Boolean(invoiceCharged) || (state?.sale?.isQuote??false),
                    loading,
                }
            },
            ...(!isMobile ? [{
                title: "Imprimir ultima factura (HOME)",
                onClick: () => lastInvoice ? handlePrint() : Toast.fire({ icon: "warning", text: "No existe una factura previa." }),
                sx,
                buttonProps: {
                    id: "printLastInvoice",
                    loading,
                }
            }] : []),
        ]
        resp = resp.map((n, index) => ({...n, _id: index}))

        return validateArrayByPermissionOrder(["temporarily_save_the_sale", "clean_billing_area", "register_sale", "register_sale", "register_quotation", "print_last_sale_invoice"], resp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerInvoice, handlePrint, lastInvoice, validateArrayByPermissionOrder, invoiceCharged, state])

    const keyboardShortcuts = useCallback((event) => {
        const { code="" } = event
        let FCode = [2, 3, 4, 5, 6, 12]
        let lockKeys = [ ...FCode.map((number) => `F${number}`) ].some(n => (n === code))
        if( lockKeys ){
            event.preventDefault()
            event.stopPropagation()
        }
        if( code === "Enter" || code === "NumpadEnter" ){
            let elem = document.activeElement
            let childNode = elem.childNodes[0]
            if( Boolean(childNode) ){
                let isValid = (childNode?.className??"").split(" ").some(n => n === "cell_dataContainer")
                if(isValid) childNode.click()
            }
        }
        if( code === "Home" ){
            executeFunctionWithPermission("print_last_sale_invoice", () => actionOnElement("printLastInvoice"))
        }
        if( code === "Delete" ){
            executeFunctionWithPermission("clean_billing_area", () => actionOnElement("clearBilling"))
            
        }
        if( code === "F2" ){
            elementFocus("searchBarPOS")
        }
        if( code === "F3" ){
            let elem = document.getElementsByClassName("cell_dataContainer")
            elem = elem[0]
            if( Boolean(elem) ){
                let parentNode = elem.parentNode
                parentNode.focus()
            }
        }
        if( code === "F4" ){
            executeFunctionWithPermission("register_sale", () => actionOnElement("recordSale"))
        }
        if( code === "F5" ){
            executeFunctionWithPermission("register_quotation", () => actionOnElement("recordQuote"))
        }
        if( code === "F6" ){
            executeFunctionWithPermission("register_sale", () => actionOnElement("recordSaleAndPrint"))
        }
        if( code === "F12" ){
            executeFunctionWithPermission("temporarily_save_the_sale", () => actionOnElement("saveInvoice"))
        }
        if( code === "Escape" ){
            if (window.history.state && window.history.state.idx >= 1) {
                navigate(-1);
            } else {
                let redirectByDefault = localStorage.getItem("redirectByDefault")
                if( Boolean(redirectByDefault) ){
                    navigate(redirectByDefault, { replace: true });
                }else{
                    navigate("/", { replace: true });
                }
            }
        }

    }, [executeFunctionWithPermission, navigate])

    return {
        optionActions,
        footerActions,
        keyboardShortcuts
    }
}

export const useRecordSale = () => {
    const [recordSaleMutation, { isLoading }] = useRecordSaleMutation()

    const recordSale = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, print=false, payBill=false, onPayBill=()=>null, onPrint=()=>null } = callback
                const resp = await recordSaleMutation(payload).unwrap()
                let invoice = (resp?.payload??null)
                asynchronousResponseHandler(resp, {
                    successMessage: "La venta se registro satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
                if(Boolean(invoice?._id)) await db.tmp.put({ id: 1, lastRegisteredSalesInvoice: invoice, })
                // createCookie({ name: "lastBill", value: invoice, convertJSONstringify: true })
                if( payBill ) onPayBill((invoice?._id??null))
                if( print && Boolean(invoice?._id) ){
                    setTimeout(() => {
                        onPrint()
                        // const elem = document.getElementById("printLastInvoice")
                        // if( Boolean(elem) ){
                        //     elem.click()
                        // }
                    }, 250)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordSale,
        isLoading
    }
}

export const useRecordInvoiceInformationAndPayments = () => {
    const [recordInvoiceInformationAndPaymentsMutation, { isLoading }] = useRecordInvoiceInformationAndPaymentsMutation()

    const recordInvoiceInformationAndPayments = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await recordInvoiceInformationAndPaymentsMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "La información de la venta se registro satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordInvoiceInformationAndPayments,
        isLoading
    }
}

export const useRegisterNewPaymentForSale = () => {
    const [registerNewPaymentForSaleMutation, { isLoading }] = useRegisterNewPaymentForSaleMutation()

    const registerNewPaymentForSale = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerNewPaymentForSaleMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El pago se añadió exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerNewPaymentForSale,
        isLoading
    }
}

export const useUpdateInvoiceInformation = () => {
    const [updateInvoiceInformationMutation, { isLoading }] = useUpdateInvoiceInformationMutation()

    const updateInvoiceInformation = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await updateInvoiceInformationMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "La información se actualizo satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateInvoiceInformation,
        isLoading
    }
}