import TabAppContext from "@component/TabAppContext"
import { useMemo, useState } from "react"
import ReportSettings from "./components/ReportSettings"

const SettingPointOfSale = () => {
    const [tab, setTab] = useState("reports-setting")
    const tabs = useMemo(() => {
        let tabArr = [
            { label: "Reportes", value: "reports-setting", component: <ReportSettings /> },
        ]
        return tabArr
    }, [])
    return (
        <>
            <TabAppContext
                tab={tab}
                tabs={tabs}
                onChange={(newTab) => setTab(newTab)}
            />
        </>
    )
}

export default SettingPointOfSale