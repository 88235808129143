import { Button, FileDownloadIconRounded } from "@component/UIComponents"
import { useExportData } from "./hooks/useExportData"

const ExportData =  ({ bookName="", headerNames=[], rows=[], sheetName="" }) => {
    const { onExport } = useExportData()
    return(
        <Button onClick={() => onExport({ bookName, headerNames, rows, sheetName })} size="small" startIcon={<FileDownloadIconRounded />} >
            Exportar
        </Button>
    )
}

export default ExportData