import SuperDataGrid from "@component/SuperDataGrid"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { routes } from '@util/routes'
import { Grid } from "@component/UIComponents"
import Filters from "@component/Filters"
import InventoryClosingFilters from "./components/InventoryClosingFilters"
import { useClosingInventory } from "./hooks/useClosingInventory"
const { inventory } = routes

const InventoryClosingReport = () => {
    const { summary, updateFilters } = useClosingInventory()
    const navigate = useNavigate()

    return (
        <Grid container>
            <Grid xs={12} >
                <Filters>
                    <InventoryClosingFilters onSubmit={updateFilters} />
                </Filters>
            </Grid>
            <Grid xs={12} >
                <SuperDataGrid
                    title={'Cierre del inventario'}
                    onRowClick={({ row }) => navigate(inventory(`bestSeller?InitialDate=${dayjs((row?.date??""), "DD-MM-YYYY").format("MM-DD-YYYY")}&FinalDate=${dayjs((row?.date??""), "DD-MM-YYYY").format("MM-DD-YYYY")}`))}
                    useSearchBar={false}
                    columns={[
                        { minWidth: 140, field: "InitInventory", headerName: "Inventario inicial", renderCell: ({ row }) => `${currencyFormat((row?.InitInventory??0))}` },
                        { minWidth: 130, field: "sale", headerName: "Venta", renderCell: ({ row }) => `${currencyFormat((row?.sale??0))}` },
                        { minWidth: 130, field: "cost", headerName: "Costo", renderCell: ({ row }) => `${currencyFormat((row?.cost??0))}` },
                        { minWidth: 130, field: "profit", headerName: "Ganancia", renderCell: ({ row }) => `${currencyFormat((row?.profit??0))}` },
                        { minWidth: 130, field: "shopping", headerName: "Compro", renderCell: ({ row }) => `${currencyFormat((row?.shopping??0))}` },
                        { minWidth: 180, field: "ReturnsCost", headerName: "Costo de devoluciones", renderCell: ({ row }) => `${currencyFormat((row?.ReturnsCost??0))}` },
                        { minWidth: 180, field: "EndInventory", headerName: "Inventario final", renderCell: ({ row }) => `${currencyFormat((row?.EndInventory??0))}` },
                        { minWidth: 160, field: "date", headerName: "Fecha", renderCell: ({ row }) => `${dayjs((row?.date??null), "DD-MM-YYYY").format("DD-MM-YYYY")}` },
                    ]}
                    rows={summary}
                    overlayText={'Estamos calculando el cierre del inventario, esto puede tardar unos segundos... '}
                />
            </Grid>
        </Grid>
    )
}

export default InventoryClosingReport