import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetCities } from "../hooks/useBusiness"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const CitiesFinder = ({ size=undefined, onChange=undefined, value=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { cities, isFetching } = useGetCities()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: cities, queryValue, compare })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Ciudad"
                options={cities}
            />
        </>
    )
}

export default CitiesFinder