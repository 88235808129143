import { useEditOrderMutation } from "@query/billing"
import { useCallback, useMemo } from "react"
import _ from 'lodash'
import { Toast } from "@util/swal"
import { usePrintInvoice } from "@feature/Sales/hooks/usePrintInvoice"

export const useEditOrder = ({ _id=null, products=[], customer=null, total=0, totalProfitCurrency=0, totalProfitPercentage=0, onCleanBilling=()=>null }) => {
    const [ editOrderMutation ] = useEditOrderMutation()
    const { PrintInvoice } = usePrintInvoice()

    const payload = useMemo(() => {
        return {
            _id,
            products,
            customer,
            total,
            totalProfitCurrency,
            totalProfitPercentage,
        }
    }, [products, customer, total, totalProfitCurrency, totalProfitPercentage, _id])

    const validateOrder = useCallback(() => {
        try {
            if( !Boolean(_id) ) throw "Se espera el identificador de la factura."
            if( !_.isArray(products) ) throw "Se esperaba una lista con los productos."
            if( products.length <= 0 ) throw "Añade al menos un producto antes de registrar el pedido."
            return true
        } catch (error) {
            throw error
        }
    }, [products, _id])

    const editOrder = useCallback(async (config={ print: false }) => {
        try {
            const isValid = validateOrder()
            if((isValid === true)){
                const { print=false } = (config??{})
                const resp = await editOrderMutation(payload).unwrap()
                const order = (resp.payload??{})
                const { _id, orderNumber, customer=null } = (order??{})
                if( Boolean(_id) ){
                    Toast.fire({
                        icon: "success",
                        text: `El pedido numero #P-${orderNumber} registrado a ${customer?.name??"VARIOS"} se actualizo exitosamente.`
                    })
                    onCleanBilling()
                    if(print === true){
                        PrintInvoice({ BillID: _id, invoiceType: "order" })
                    }
                }
            }
        } catch (error) {
            Toast.fire({ icon: "error", text: (error?.message??error) })
        }
    }, [validateOrder, payload, editOrderMutation, onCleanBilling, PrintInvoice])

    return {
        editOrder
    }
}