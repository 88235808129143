import { useGetDynamicBoxes } from "@feature/Boxes/hooks/useBoxes"
import Autocomplete from "./Autocomplete"
import NumericFormat from "./NumericFormat"
import { Box, Grid, Typography, TextField, Button } from "./UIComponents"
import { useTranslation } from "react-i18next"
import { forwardRef, useMemo, useState, useRef, useImperativeHandle, useEffect, useCallback } from "react"
import { currencyFormat } from "@util/currencyFormat"
import { Toast } from "@util/swal"
import { elementFocus } from "@util/helpers"
import { useGetBanks } from "@feature/Banks/hooks/useBanks"
import { useGetProvider } from "@feature/Providers/hooks/useProviders"
import { useGetCashiersEmployed } from "@feature/Employees/hooks/useEmployees"
import { useCashTransferFormData } from "@hook/useMovements"


const propsDefault = {
    numericFormat: {},
    autocomplete: {}
}

const paymentDefault = { box: "", amount: 0, bank: null, boxRef: "" }

const PayFromCashier = forwardRef(({ name1='', name2='', showMaxVal=true, getOptionDisabled=true, skipOptions=[], maxAmount=-1, size="small", componentProps=propsDefault, onSubmit=undefined, payments=[], typePayment="cash", paymentReference="", balance=undefined, fixedBox=undefined }, ref) => {
    const [observation, setObservation] = useState('')
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    useImperativeHandle(ref, () => ({
        cashToPayInputRef: ref1.current,
        boxToEnterAmountRef: ref2.current,
    }))
    const [payment, setPayment] = useState({...paymentDefault})
    const { t } = useTranslation()
    let { resp, isFetching } = useGetDynamicBoxes({ include: "masterBox" })
    let { resp:banks, isFetching:isFetchingBanks } = useGetBanks()
    let { cashiers, isFetching:isFetchingCashiers } = useGetCashiersEmployed()
    let { provider, isFetching:isFetchingProvider } = useGetProvider({ refId: paymentReference }, { skip: !Boolean(paymentReference) })
    
    let options = useMemo(() => {
        if( typePayment === "cash" ){
            return resp
        }
        if( typePayment === "consignment" ){
            return banks
        }
        if( typePayment === "cashier" ){
            let r = [...cashiers]
            r = r.map((n) => ({ _id: (n?._id??null), name: `${n.name} ${n.lastName}`, balance: (n?.balance??0) }))
            return r
        }
        return []
    }, [typePayment, resp, banks, cashiers])

    const boxSelected = useMemo(() => {
        let r = null
        if( Boolean(fixedBox) ){
            r = options.find((n) => (n?._id??null) === fixedBox) || null
        }else{
            r = options.find((n) => (n?._id??"") === (payment?.boxRef??"")) || null
        }
        return r
    }, [payment, options, fixedBox])
    const { numericFormat={}, autocomplete={} } = componentProps

    const handleKeyDown = (event) => {
        const { code } = event
        if( (code === "Enter") || (code === "NumpadEnter") ){
            event.preventDefault()
            event.stopPropagation()
            let currentPayment = {...payment}
            if( typePayment !== "advance" ){
                if( !Boolean((currentPayment?.box??"")) && !Boolean((currentPayment?.boxRef??"")) ){
                    Toast.fire({
                        icon: 'warning',
                        text: 'Seleccione una caja antes de continuar.'
                    })
                    return
                }
            }else{
                currentPayment.box = "advanceProvider"
                currentPayment.boxRef = (provider?._id??"")
            }
            if( (currentPayment?.amount??0) <= 0 ){
                Toast.fire({
                    icon: 'warning',
                    text: 'El monto debe ser un numero mayor a 0.'
                })
                return
            }
            if( Boolean(onSubmit) ){
                sessionStorage.setItem('observation', observation)
                onSubmit(currentPayment)
                if(Boolean(fixedBox)){
                    setPayment(prevState => ({ ...prevState, amount: 0, bank: null }))
                }else{
                    setPayment({...paymentDefault})
                }
            }
        }
    }
    const handleChangeBox = (param) => {
        if( !Boolean(fixedBox) ){
            if( (typePayment === "cash") || (typePayment === "cashier") ){
                setPayment((prevValue) => ({...prevValue, box: (param?.name??""), boxRef: (param?._id??"") }))
            }else if( typePayment === "consignment" ){
                setPayment((prevValue) => ({...prevValue, box: "bank", bank: (param?.name??""), boxRef: (param?._id??"") }))
            }
            if( Boolean(param) ) elementFocus(null, ref2.current)
        }
    }
    const maxVal = (maxAmount >= 0) ? maxAmount : (typePayment === "cash") ? (boxSelected?.amount??0) : ((typePayment === "consignment") || (typePayment === "cashier")) ? (boxSelected?.balance??0) : (provider?.balance??0)
    const loadDefaultBox = useCallback(() => {
        if( Boolean(fixedBox) ){
            const box = options.find((n) => (n?._id??null) === fixedBox) || null
            if( Boolean(box) ){
                setPayment((prevValue) => ({...prevValue, box: (box?.name??""), boxRef: (box?._id??"") }))
            }
        }
    }, [fixedBox, options])

    useEffect(()=>{
        if(Boolean(observation)){
            sessionStorage.setItem('observation', observation)
        }
    },[observation])

    useEffect(()=>{
        const {amount, blank, box, boxRef} = payment
        if(amount && box && boxRef){
            sessionStorage.setItem('payment', JSON.stringify(payment))
        }
    },[payment])

    useEffect(() => {
        loadDefaultBox()
    }, [loadDefaultBox])

    const currentOptions = useMemo(() => {
        let cOptions = [...options]
        cOptions = cOptions.filter((n) => !skipOptions.some((x) => n._id === x))
        return cOptions
    }, [options, skipOptions])

    return(
        <Grid container direction="column" style={{ height: '100%' }}>
            <Grid container justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Typography
                variant="body"
                fontWeight="bold"
                 >
                  {name1}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body"
                  fontWeight="bold"
                 >
                  {name2 + ` ${showMaxVal ? currencyFormat(maxVal) : ''}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
                {typePayment !== "advance" ? (
                    <Grid item xs={6}>
                        <Autocomplete
                            className={"_cashToPay"}
                            disabled={balance === undefined ? undefined : balance <= 0}
                            helperText={"Ctrl selecciona medio de pago diferente"}
                            id="cashToPay"
                            value={boxSelected}
                            onChange={(_, param) => handleChangeBox(param)}
                            getOptionLabel={(param) => t((param?.name ?? ""))}
                            loading={isFetching || isFetchingBanks || isFetchingProvider || isFetchingCashiers}
                            size={size}
                            placeholder={typePayment === "cash" ? "Seleccione una caja" : typePayment === "bank" ? "Seleccione un banco" : "Seleccione una caja"}
                            options={currentOptions}
                            getOptionDisabled={Boolean(getOptionDisabled) ? (param) => ((typePayment === "cash") ? ((param?.amount ?? 0) <= 0) : ((param?.balance ?? 0) <= 0)) || payments.some((n) => (n?.boxRef ?? "") === (param?._id ?? "")) : undefined}
                            renderOption={(props, option) => (
                                <li {...props} key={option._id}>
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <Typography variant="body2" fontWeight={'bold'}>{t((option?.name ?? ""))}</Typography>
                                        <Typography variant="body2" fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }}>
                                            {currencyFormat(typePayment === "cash" ? (option?.amount ?? 0) : (option?.balance ?? 0))}
                                        </Typography>
                                    </Box>
                                </li>
                            )}
                            {...autocomplete}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={3}>
                        <Box>
                            <Typography lineHeight={1} fontSize={'.875rem'} fontWeight={'bold'}>{(provider?.name ?? "")}</Typography>
                            <Typography lineHeight={1} fontSize={'.875rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }}>
                                {currencyFormat((provider?.balance ?? 0))}
                            </Typography>
                        </Box>
                    </Grid>
                )}

                <Grid item xs={typePayment === "advance" ? 9 : 6}>
                    <NumericFormat
                        className="_boxToEnterAmount"
                        ref={ref2}
                        disabled={balance === undefined ? undefined : balance <= 0}
                        id='boxToEnterAmount'
                        onChange={({ floatValue }) => setPayment((prevValue) => ({ ...prevValue, amount: floatValue }))}
                        value={(payment?.amount ?? 0)}
                        maxVal={maxVal}
                        fullWidth
                        size={size}
                        placeholder="Monto"
                        {...numericFormat}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption" gutterBottom style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                        ingresa detalles del traslado
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        InputProps={{
                            style: { height: '56px' }
                        }}
                     />
                </Grid>
            </Grid>
        </Grid>
    )
})

export default PayFromCashier