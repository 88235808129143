import { Box, Stack } from "../UIComponents"
import FilterButton from "./components/FilterButton";
import { componentProps as componentsProps } from './constants/defaultProps'
import { useActionListController } from "./hooks/useActionListController";
import ButtonVariant from "./components/ButtonVariant";
import IconButtonVariant from "./components/IconButtonVariant";
import MenuVariant from "./components/MenuVariant";
import PopoverWindow from "./components/PopoverWindow";
import { useRef } from "react";
import Filters from "./components/Filters";

const ActionsList = ({ children=undefined, componentProps={...componentsProps}, direction="row", options=[], sxButton={}, divider=undefined, variant="button" }) => {
    const { getProps, popoverProps, setPopoverProps, setFiltersAnchorEl, filtersAnchorEl } = useActionListController({ componentProps })
    const filterButtonRef = useRef()

    return(
        <>
            <Stack py={1} direction={direction} gap={1} divider={divider} >
                {(getProps("filter", "active") === true && variant !== "mobileMenu") && <FilterButton onClick={() => setFiltersAnchorEl((filterButtonRef?.current??null))} ref={filterButtonRef} {...getProps("filter", "props")} variant={variant} />}
                { variant === "button" && <ButtonVariant options={options} sxButton={sxButton} setPopoverProps={setPopoverProps} />}
                { variant === "iconButton" && <IconButtonVariant options={options} sxButton={sxButton} setPopoverProps={setPopoverProps} />}
                { variant === "mobileMenu" && <MenuVariant children={children} {...getProps("menuMobile", "props")} filters={ getProps("filter", "active") === true ? <FilterButton /> : <></> } options={options} setPopoverProps={setPopoverProps} /> }
                <PopoverWindow {...popoverProps} />
                { getProps("portal", "active") && <Box id="actionList-portal" display={'flex'} flexDirection={'row'} ></Box> }
                <Filters filtersAnchorEl={filtersAnchorEl} openFilters={Boolean(filtersAnchorEl)} onClose={() => setFiltersAnchorEl(null)} {...getProps("filter", "props")} />
            </Stack>
        </>
    )
}

export default ActionsList