import { AddCircleOutlineIconRounded } from "@component/UIComponents"
import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useGetCashBalancesQuery, useGetDynamicBoxesQuery, useRegisterNewPocketMutation } from "@query/Movements"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useGetDynamicBoxes = (filters={}) => {
    const { data, isFetching, isLoading } = useGetDynamicBoxesQuery(filters)
    let resp = (data?.payload??[])

    return {
        resp,
        isFetching,
        isLoading
    }
}

export const useGetCashBalances = (filters={}) => {
    const { data, isFetching } = useGetCashBalancesQuery(filters)
    let resp =  (data?.payload??{})

    return {
        resp,
        isFetching
    }
}

export const useBoxesMenu = () => {
    const boxesActions = [
        {
            title: "Crear nueva caja",
            onClick: () => null,
            icon: <AddCircleOutlineIconRounded />
        }
    ]
    return {
        boxesActions
    }
}

export const useRegisterNewPocket = () => {
    const [ registerNewPocketMutation, { isLoading } ] = useRegisterNewPocketMutation()

    let registerNewPocket = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await registerNewPocketMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ?
                        "El bolsillo se actualizo exitosamente."
                        :
                        "El nuevo bolsillo se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            
        }
    }

    return {
        registerNewPocket,
        isLoading
    }
}

export const usePocketFormData = (props={}) => {
    const { defaultValues=undefined, openModal=false } = props
    const initialValues = useMemo(() => ({ name: "" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre de la caja es requerido."),
        },
        openModal,
    })
    return { values, setFieldValue, resetValues, validateValues }
}
