import { Box, IconButton, MenuIconRounded, AppBar as MuiAppBar, Stack, Toolbar, styled } from '@component/UIComponents'
import AppBarUserMenu from './AppBarUserMenu';
import POSModuleButton from '@component/POSModuleButton';
import NotificationsMenu from '@component/NotificationsMenu';
import { useValidatePermission } from '@hook/useValidatePermission';

const AppBarStyled = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})(({ theme, open, drawerWidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    borderBottom: `1px solid ${theme.palette.divider}`
}));

const AppBar = ({ open = true, drawerWidth = 245, toggleDrawer = undefined }) => {
    const { havePermission } = useValidatePermission()

    return (
        <AppBarStyled position="absolute" open={open} drawerWidth={drawerWidth} elevation={0} color='primary' >
            <Toolbar sx={{ pr: '24px' }} variant='dense' >
                <Box display={'flex'} width={"100%"} alignItems={'center'} >
                    <Box>
                        <IconButton color="inherit"
                            onClick={toggleDrawer}
                            sx={{ mx: '10px', ...(open && { display: 'none' }) }}
                        >
                            <MenuIconRounded />
                        </IconButton>
                    </Box>
                    {!open && <AppBarUserMenu sx={open ? {} : { '& .MuiButtonBase-root': {color: "#FFFFFF"} }} />}
                    <Box id="auxiliary-portal" />
                </Box>

                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} >
                    <Stack direction={'row'} justifyContent={'start'}>
                        <Box>
                        </Box>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} >
                        {havePermission("show_sales_menu") &&
                            <Box>
                                <POSModuleButton />
                            </Box>
                        }
                        {havePermission("show_system_notifications") &&
                            <Box>
                                <NotificationsMenu />
                            </Box>
                        }
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBarStyled>
    )
}

export default AppBar