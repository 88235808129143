import { useMediaQuery, Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, DatePicker, ExpandMoreRoundedIcon, Grid, SaveRoundedIcon, Table, TableBody, TableCell, TableHead, TableRow, useTheme, Divider } from "@component/UIComponents"
import ClosingSummaryCard from "./ClosingSummaryCard"
import { useClosingOfTheDay } from "../hooks/useClosingOfTheDay"
import { currencyFormat } from "@util/currencyFormat"
import { Fragment, useEffect, useState } from "react"
import dayjs from "dayjs"
import useQueryParams from "@hook/useQueryParams"
import { useDispatch, useSelector } from "react-redux"
import { ReturnToCurrentClosure } from "../slice/sliceClosingOfTheDay"

const ClosingSummary = () => {
    const { closingDate = dayjs().format("MM-DD-YYYY"), CashierDaySale, ClosingOfTheDay, GeneralCashCollections, GeneralCashOutflows, onSaveClosingOfTheDayMutation, isLoadingSaveClosingOfTheDayMutation } = useClosingOfTheDay()
    const { setQueryParams } = useQueryParams()
    const dispatch = useDispatch()
    const closingOfTheDay = useSelector(state => state.ClosingOfTheDay)
    const { isPreviousClosing=false, ClosingDate=null } = (closingOfTheDay?.ClosingSummary??{})
    const [widths, setWidths] = useState({})
    const [cardWidth, setCardWidth] = useState('')
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    useEffect(()=>{
        if(isMobile){
            setWidths({
                minWidth: 160,
                maxWidth: 280
            })
            setCardWidth('100%')
        }
        else{
            setWidths({
                minWidth: 360,
                maxWidth: 400
            })
            setCardWidth('33%')
        }
    },[isMobile])

    return (
        <Box mt={2} sx={{maxWidth:'100vw'}}>
            <Grid container spacing={1} justifyContent={`${isMobile ? '' : 'center'}`}  >
                <Grid xs={12} >
                    { isPreviousClosing ?
                        <Box display={'flex'} alignItems={'center'} gap={2} >
                            <Button size="small" variant="contained" disableElevation onClick={() => dispatch(ReturnToCurrentClosure())} >
                                volver al cierre actual
                            </Button>
                            {"-"}
                            <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} sx={{ WebkitTextStroke: .3 }} >
                                Cierre correspondiente al <Box component={'span'} color={'primary.main'} > {dayjs(ClosingDate).format("DD-MM-YYYY HH:mm A")} </Box>
                            </Box>
                        </Box>
                        :
                        <Box display={'flex'} gap={1} >
                            <DatePicker
                                format="DD-MM-YYYY"
                                label="Cierre del"
                                value={dayjs(closingDate)}
                                onChange={(date) => setQueryParams({ closingDate: dayjs(date).format("MM-DD-YYYY") })}
                                disableFuture
                                slotProps={{
                                    textField: {
                                        size: "small"
                                    }
                                }}
                            />
                            <Button onClick={() => onSaveClosingOfTheDayMutation()} variant="contained" disableElevation startIcon={<SaveRoundedIcon />} loading={isLoadingSaveClosingOfTheDayMutation} >
                                Guardar cierre
                            </Button>
                        </Box>
                    }
                </Grid>
                <Grid width={`${cardWidth}`} >
                    <ClosingSummaryCard
                        title={"Venta del dia general"}
                        minWidth={widths.minWidth}
                        maxWidth={widths.maxWidth}
                        cashValue={(ClosingOfTheDay?.cashOfTheDay?.amount ?? 0)}
                        creditValue={(ClosingOfTheDay?.creditOfTheDay?.amount ?? 0)}
                        extendsSummary={
                            <>
                                {(ClosingOfTheDay.dailyConsignmentToBanks ?? []).map((consignment) => {
                                    const { name = "", amount = 0 } = (consignment ?? {})
                                    return (
                                        <TableRow >
                                            <TableCell>{name}</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat(amount)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </>
                        }
                        totalValue={(ClosingOfTheDay?.total ?? 0)}
                    />
                </Grid>
                <Grid width={`${cardWidth}`} >
                    <ClosingSummaryCard
                        title={<>Recaudo efectivo <Box component={'span'} color={'primary.main'} > General </Box></>}
                        minWidth={widths.minWidth}
                        maxWidth={widths.maxWidth}
                        disableCash
                        disableCredit
                        extendsSummary={<>
                            <TableRow >
                                <TableCell>Recaudo créditos</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashCollections?.paymentToCreditsFromPreviousDays?.amount ?? 0))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Efectivo dia</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashCollections?.cashOfTheDay?.amount ?? 0))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Caja anterior</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashCollections?.boxFromThePreviousDay ?? 0))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell></TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat(((GeneralCashCollections?.boxFromThePreviousDay ?? 0) + (GeneralCashCollections?.cashOfTheDay?.amount ?? 0) + (GeneralCashCollections?.paymentToCreditsFromPreviousDays?.amount ?? 0)))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Salidas</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashOutflows?.total ?? 0))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Traslado</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashCollections?.boxTransfers?.amount ?? 0))}</TableCell>
                            </TableRow>
                        </>}
                        totalValue={(GeneralCashCollections?.total ?? 0)}
                    />
                </Grid>
                <Grid sx={`${cardWidth}`} >
                    <ClosingSummaryCard
                        title={<>Salida de efectivo <Box component={'span'} color={'primary.main'} > General </Box></>}
                        minWidth={widths.minWidth}
                        maxWidth={widths.maxWidth}
                        disableCash
                        disableCredit
                        extendsSummary={<>
                            <TableRow >
                                <TableCell>Gastos varios</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashOutflows?.variableExpenses?.amount ?? 0))}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>Gastos fijos</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((GeneralCashOutflows?.fixedExpenses?.amount ?? 0))}</TableCell>
                            </TableRow>
                            {/* <TableRow >
                                    <TableCell>Salidas</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((totalizeCashOut ?? 0))}</TableCell>
                                </TableRow> */}
                        </>}
                        totalValue={(GeneralCashOutflows?.total ?? 0)}
                    />
                </Grid>
                <Grid xs={12} >
                    <Accordion variant="outlined" >
                        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />} id="panel1-header" >
                            <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >
                                Detalles generales:
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} >
                                <Grid width={`${cardWidth}`} >
                                    <Card variant="outlined" >
                                        <CardContent>
                                            <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Compras: </Box>
                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell> Registrado por </TableCell>
                                                        <TableCell> Proveedor </TableCell>
                                                        <TableCell> #FAC </TableCell>
                                                        <TableCell> Total </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(GeneralCashOutflows?.shopping?.results ?? []).map((item, index) => {
                                                        return (
                                                            <TableRow key={index} >
                                                                <TableCell> {`${(item?.purchaseId?.user?.name ?? "")} ${(item?.purchaseId?.user?.lastName ?? "")}`.trim()} </TableCell>
                                                                <TableCell> {`${(item?.purchaseId?.provider?.name ?? "")}`.trim()} </TableCell>
                                                                <TableCell> {`${(item?.purchaseId?.invoiceNumber ?? "")}`} </TableCell>
                                                                <TableCell> {`${currencyFormat((item?.amount ?? 0))}`} </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow sx={{ '& td': { fontWeight: 'bold' } }} >
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>TOTAL</TableCell>
                                                        <TableCell> {`${currencyFormat(GeneralCashOutflows?.shopping?.amount ?? 0)}`} </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid width={`${cardWidth}`} >
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Gastos varios: </Box>
                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell> Caja </TableCell>
                                                        <TableCell> Monto </TableCell>
                                                        <TableCell> Observación </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(GeneralCashOutflows?.variableExpenses?.results ?? []).map((item, index) => {
                                                        return (
                                                            <TableRow key={index} >
                                                                <TableCell> {`${(item?.outputRef?.name ?? "")}`.trim()} </TableCell>
                                                                <TableCell> {currencyFormat((item?.amount ?? 0))} </TableCell>
                                                                <TableCell> {`${(item?.note ?? "")}`.trim()} </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow sx={{ '& td': { fontWeight: 'bold' } }} >
                                                        <TableCell>TOTAL</TableCell>
                                                        <TableCell> {`${currencyFormat(GeneralCashOutflows?.variableExpenses?.amount ?? 0)}`} </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid width={`${cardWidth}`}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Traslados: </Box>
                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell> Registrado por </TableCell>
                                                        <TableCell> Monto </TableCell>
                                                        <TableCell> Observación </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(GeneralCashCollections?.boxTransfers?.results ?? []).map((item, index) => {
                                                        return (
                                                            <TableRow key={index} >
                                                                <TableCell> {`${(item?.user?.name ?? "")} ${(item?.user?.lastName ?? "")}`.trim()} </TableCell>
                                                                <TableCell> {currencyFormat((item?.amount ?? 0))} </TableCell>
                                                                <TableCell> {`${(item?.note ?? "")}`.trim()} </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow sx={{ '& td': { fontWeight: 'bold' } }} >
                                                        <TableCell>TOTAL</TableCell>
                                                        <TableCell> {`${currencyFormat(GeneralCashCollections?.boxTransfers?.amount ?? 0)}`} </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Divider sx={{margin: 2}}/>
            <Grid container spacing={1.5} >
                {(CashierDaySale ?? []).map((cashier) => {
                    const { _id = null, saleOfTheDay = {}, name = "", lastName = "", cashCollections = {}, cashOutflows = {}, } = (cashier ?? {})
                    const fullName = `${name} ${lastName}`.trim()
                    console.log(cashier)
                    const { cashOfTheDay = {}, creditOfTheDay = {}, dailyConsignmentToBanks = [], total = 0 } = (saleOfTheDay ?? {})
                    const { paymentToCreditsFromPreviousDays = {}, cashOfTheDay: B_cashOfTheDay = {}, boxFromThePreviousDay = 0, boxTransfers = {} } = (cashCollections ?? {})
                    const { variableExpenses = {}, fixedExpenses = {} } = (cashOutflows ?? {})
                    const totalizeCashOut = Object.entries(cashOutflows).reduce((acc, [nKey, value]) => !["cashPaymentsOnCreditPurchases", "cashPaymentsForPurchases"].includes(nKey) ? (acc + (value?.amount ?? 0)) : (acc + 0), 0)
                    return (
                        <Fragment key={_id} >
                            <Grid mt={1} container gap={3} justifyContent={`${isMobile ? '' : 'center'}`}  >
                            <Grid width={`${isMobile ? '100%' : '30%'}`} >
                                <ClosingSummaryCard
                                    title={<>Venta del dia <Box component={'span'} color={'primary.main'} > {fullName} </Box></>}
                                    minWidth={widths.minWidth}
                                    maxWidth={widths.maxWidth}
                                    cashValue={(cashOfTheDay?.amount ?? 0)}
                                    creditValue={(creditOfTheDay?.amount ?? 0)}
                                    extendsSummary={<>
                                        {(dailyConsignmentToBanks ?? []).map((consignment) => {
                                            const { name = "", amount = 0 } = (consignment ?? {})
                                            return (
                                                <TableRow >
                                                    <TableCell>{name}</TableCell>
                                                    <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat(amount)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </>}
                                    totalValue={total}
                                />
                            </Grid>
                            <Grid width={`${isMobile ? '100%' : '30%'}`}>
                                <ClosingSummaryCard
                                    title={<>Recaudo efectivo <Box component={'span'} color={'primary.main'} > {fullName} </Box></>}
                                    minWidth={widths.minWidth}
                                    maxWidth={widths.maxWidth}
                                    disableCash
                                    disableCredit
                                    extendsSummary={<>
                                        <TableRow >
                                            <TableCell>Recaudo créditos</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((paymentToCreditsFromPreviousDays?.amount ?? 0))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>Efectivo dia</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((B_cashOfTheDay?.amount ?? 0))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>Caja anterior</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((boxFromThePreviousDay ?? 0))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell></TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat(((B_cashOfTheDay?.amount ?? 0) + boxFromThePreviousDay + (paymentToCreditsFromPreviousDays?.amount ?? 0)))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>Salidas</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((totalizeCashOut ?? 0))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>Traslado</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((boxTransfers?.amount ?? 0))}</TableCell>
                                        </TableRow>
                                    </>}
                                    totalValue={((paymentToCreditsFromPreviousDays?.amount ?? 0) + (B_cashOfTheDay?.amount ?? 0) + (boxFromThePreviousDay ?? 0)) - (totalizeCashOut + (boxTransfers?.amount ?? 0))}
                                />
                            </Grid>
                            <Grid width={`${isMobile ? '100%' : '30%'}`} >
                                <ClosingSummaryCard
                                    title={<>Salida efectivo <Box component={'span'} color={'primary.main'} > {fullName} </Box></>}
                                    minWidth={widths.minWidth}
                                    maxWidth={widths.maxWidth}
                                    disableCash
                                    disableCredit
                                    extendsSummary={<>
                                        <TableRow >
                                            <TableCell>Gastos varios</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((variableExpenses?.amount ?? 0))}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>Gastos fijos</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((fixedExpenses?.amount ?? 0))}</TableCell>
                                        </TableRow>
                                        {/* <TableRow >
                                                <TableCell>CxP</TableCell>
                                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((cashPaymentsOnCreditPurchases?.amount??0))}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell>Compras</TableCell>
                                                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{currencyFormat((cashPaymentsForPurchases?.amount??0))}</TableCell>
                                            </TableRow> */}
                                    </>}
                                    totalValue={totalizeCashOut}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Accordion variant="outlined" >
                                    <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />} id="panel1-header" >
                                        <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >
                                            Detalles de {fullName}:
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} >
                                            <Grid width={`${isMobile ? '100vw' : '30vw'}`} >
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Gastos varios: </Box>
                                                        <Table size="small" >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell> Caja </TableCell>
                                                                    <TableCell> Monto </TableCell>
                                                                    <TableCell> Observación </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {(cashOutflows?.variableExpenses?.results ?? []).map((item, index) => {
                                                                    return (
                                                                        <TableRow key={index} >
                                                                            <TableCell> {`${(item?.outputRef?.name ?? "")}`.trim()} </TableCell>
                                                                            <TableCell> {currencyFormat((item?.amount ?? 0))} </TableCell>
                                                                            <TableCell> {`${(item?.note ?? "")}`.trim()} </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                                <TableRow sx={{ '& td': { fontWeight: 'bold' } }} >
                                                                    <TableCell>TOTAL</TableCell>
                                                                    <TableCell> {`${currencyFormat(cashOutflows?.variableExpenses?.amount ?? 0)}`} </TableCell>
                                                                    <TableCell></TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid width={`${isMobile ? '100vw' : '30vw'}`}>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Box fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Traslados: </Box>
                                                        <Table size="small" >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell> Registrado por </TableCell>
                                                                    <TableCell> Monto </TableCell>
                                                                    <TableCell> Observación </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {(cashCollections?.boxTransfers?.results ?? []).map((item, index) => {
                                                                    return (
                                                                        <TableRow key={index} >
                                                                            <TableCell> {`${(item?.user?.name ?? "")} ${(item?.user?.lastName ?? "")}`.trim()} </TableCell>
                                                                            <TableCell> {currencyFormat((item?.amount ?? 0))} </TableCell>
                                                                            <TableCell> {`${(item?.note ?? "")}`.trim()} </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                                <TableRow sx={{ '& td': { fontWeight: 'bold' } }} >
                                                                    <TableCell>TOTAL</TableCell>
                                                                    <TableCell> {`${currencyFormat(cashCollections?.boxTransfers?.amount ?? 0)}`} </TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            </Grid>
                        </Fragment>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ClosingSummary