import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
);

const LineChart = ({ data=undefined, title="" }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: Boolean(title), text: title },
        },
    };

    return (
        <>
            {Boolean(data) ?
                <Line data={data} options={options} />
                :
                <></>
            }
        </>
    )
}

export default LineChart