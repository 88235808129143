import { Box } from '@component/UIComponents'
import TabAppContext from "@component/TabAppContext"
import { useTranslation } from "react-i18next"
// import UndefinedSales from "./UndefinedSales"
import MySalesToday from "./MySalesToday"
import { useEffect, useMemo, useState } from "react"
import { getCookie } from "@util/helpers"
import SavedSales from "./SavedSales"
import { useValidatePermission } from "@hook/useValidatePermission"
import ListOfPendingInvoices from './ListOfPendingInvoices'
import { useGetPendingInvoicesQuery } from "@query/billing"
import dayjs from 'dayjs'
import { useGetMySalesPointOfSaleQuery } from '@query/billing'
const CashierLists = ({ onSetSelectPendingInvoice=()=>null, onRecoverSavedInvoice=()=>null, isEditable=false, selectPendingInvoice=null }) => {
    const { validateArrayByPermissionOrder } = useValidatePermission()
    const [tab, setTab] = useState("")
    const { t } = useTranslation()
    const savedProductList = getCookie("savedProductList", { convertJSONstringify: true })
    const { data } = useGetMySalesPointOfSaleQuery({
        InitDate: dayjs().startOf("day").toDate(),
        EndDate: dayjs().endOf("day").toDate(),
    })
    const { dataPending } = useGetPendingInvoicesQuery()
    const pending = useMemo(() => (dataPending?.payload ?? []), [dataPending]).length
    const MySales = useMemo(() => (data?.payload??[]), [data]).length
    const tabs = useMemo(() => {
        let r = [
            // { label: t('Pendientes'), value: "undefinedSale", component: <UndefinedSales refId={selectPendingInvoice} onChangeTab={setTab} onSetSelectPendingInvoice={onSetSelectPendingInvoice} /> },
            { label: t(`Pedidos (${(pending)}) `), value: "undefinedSale", component: <ListOfPendingInvoices isEditable={isEditable} onSelectBill={onSetSelectPendingInvoice} /> },
            { label: t(`Mis Ventas (${(MySales)}) `), value: "mySales", component: <MySalesToday isEditable={isEditable} /> },
            { label: t(`Guardadas (${(savedProductList??[]).length}) `), value: "saved", component: <SavedSales onChangeTab={setTab} onRecoverSavedInvoice={onRecoverSavedInvoice} /> },
        ]
        r = validateArrayByPermissionOrder(["show_undefined_sales_list", "my_sales", "temporarily_save_the_sale"], r)
        return r
    }, [onSetSelectPendingInvoice, onRecoverSavedInvoice, savedProductList, t, validateArrayByPermissionOrder, isEditable])

    useEffect(() => {
        if( !Boolean(tab) ){
            setTab(tabs[0]?.value)
        }
    }, [tabs, tab])

    return(
        <>
        <Box bgcolor={"#FFFFFF"} >
            {((tabs.length >= 1) && Boolean(tab)) &&
                <TabAppContext
                    tab={tab}
                    tabs={tabs}
                    onChange={(_, newTab) => setTab(newTab)}
                />
            }
        </Box>
        </>
    )
}

export default CashierLists