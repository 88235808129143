const prepareRoute = ({ route="", params={} }) => {
    const currentPath = route
    const pathWithParams = route.replace(/:\w+\??/g, match => {
        const marcador = match.replace(/[:?]/g, '');
        const val = params[marcador] || '';
        return val
    });
    return (Object.keys(params).length >= 1) ? pathWithParams : currentPath
}

export const routes = {
    home: () => `/`,
    inventory: (param=undefined) => Boolean(param) ? `/inventory/${param}` : `/inventory/:tab`,
    report: (param=undefined) => Boolean(param) ? `/report/${param}` : `/report/:tab`,
    purchaseReport: (param=undefined) => Boolean(param) ? `/purchase/report/${param}` : `/purchase/report/:tab`,
    login: () => `/login`,
    singIn: () => `/sign/in`,
    recoverPassword: () => `/recover/password/:tk`,
    masterBox: () => `/master/box`,
    boxes: () => `/boxes`,
    banks: () => `/banks`,
    enterPurchase: (param=undefined) => Boolean(param) ? `/enter/purchase/${param}` : `/enter/purchase/:purchase?`,
    sales: (params={}) => prepareRoute({ route: `/point/of/sale/:saleId?/:creditNote?`, params }),
    customers: () => `/customers`,
    providers: () => `/providers`,
    accountsPayable: (param=undefined) => Boolean(param) ? `/accounts/payable/${param}` : `/accounts/payable/:tab`,
    settings: () => `/settings`,
    roles: () => `/settings/roles`,
    settingPointOfSale: () => `/settings/point/of/sale`,
    updateBusinessInformation: () => `/settings/update/business/information`,
    updateUserInformation: () => `/settings/update/user/information`,
    employees: () => `/employees`,
    deliveries: () => `/deliveries`,
    hauliers: () => `/hauliers`,
    ordersToSuppliers: (params={}) => prepareRoute({ route: `/orders/suppliers/:orderPurchaseId?`, params }),
    closingOfTheDay: () => `/closing/of/the/day`,
    expenses: () => `/expenses`,
    registerFirstBusiness: () => `/register/first/business`,
    receiptOfSalesInvoices: () => `/receipt/of/sales/invoices`,
    branches: () => `/settings/branches`,
    welcome: () => `/welcome`,
    customerTracking: () => `/customer/tracking`,
    productHistory: () => `/product/history`,
    inventoryTurnoverRate: () => `/inventory/turnover/rate`,
    cashierReport: () => `/cashier/report`,
}