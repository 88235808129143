import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid } from "@component/UIComponents"
import { useCustomerTrackingController, useCustomerTrackingControllerDataGrid } from "./hooks/useCustomerTrackingController"
import ActionsList from "@component/ActionsList"
import HistoryOfSellingProductsToCustomers from "../Sales/components/HistoryOfSellingProductsToCustomers"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"
import CustomerInvoices from "../Sales/components/CustomerInvoices"
import DialogInvoice from "../Invoice/components/DialogInvoice"
import Filters from "./components/Filters"
import CustomerForm from "@feature/Customers/CustomerForm"
import ComponentTitle from "@component/ComponentTitle"
import CustomerWatchList from "./components/CustomerWatchList"
import SalesGraph from "./components/SalesGraph"
import CustomerPurchaseHistoryPopUp from "./components/CustomerPurchaseHistoryPopUp"
import TXDate from "@component/TXDate"

const CustomerTracking = () => {
    const { getDateFormats } = usePanel()
    const { t } = useTranslation()
    const { customerPurchaseTracking, componentProps, handleShowCustomerProductPurchaseHistory, toggleInformationTables, updateCustomerInvoices, updateSelectedInvoice, updateCustomerPurchaseTrackingFilter, activeFilters, setActiveFilters, updateSelectedClient, customer, updateCustomerSalesChart, trackingDetails } = useCustomerTrackingController()
    const { filterACustomerPurchaseHistory, showCustomerPurchaseHistoryWindow, customerPurchaseTrackingFilter, dataGrid, customerInvoices, selectedInvoice, selectedClient, customerSalesGraph } = componentProps
    const { startDate, endDate, groupClients } = (activeFilters??{})
    const { columns, rows, quickList, columnsDate } = useCustomerTrackingControllerDataGrid({
        groupRows: (groupClients?.value??null),
        dataGrid,
        customerPurchaseTracking,
        trackingDetails,
        onShowCustomerProductPurchaseHistory: ({ _id, name }) => handleShowCustomerProductPurchaseHistory({ customerId: _id, startDate, endDate, customerName: name }),
        onShowCustomerPurchaseHistory: ({ name }) => toggleInformationTables("customer_purchase_history", { customer: [name], startDate, endDate }),
        onShowCustomerPurchaseInvoices: (invoices) => updateCustomerInvoices(invoices),
        onEditCustomer: ({ _id }) => updateSelectedClient(_id),
        onShowCustomerSalesGraph: (history) => updateCustomerSalesChart(history)
    })

    const { lsCustomerPurchaseHistory, lsGeneralCustomerHistory } = rows
    const { clCustomerPurchaseHistory, clGeneralCustomerHistory } = columns

    return(
        <Grid container >
            <ComponentTitle activeNavigatorTabTitle={true} title="Seguimiento de clientes" />

            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <Box display={'flex'} alignItems={'center'} gap={1} >
                    {/* {(dataGrid === "customer_purchase_history") &&
                        <Button startIcon={<ArrowBackIosNewRoundedIcon />} onClick={() => toggleInformationTables("general_customer_history", {})} >
                            Volver
                        </Button>
                    } */}
                    <Box id="downloadXMLDataGrid" />
                    <ActionsList
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    component: () => <Filters dataGrid={dataGrid} {...customerPurchaseTrackingFilter} setFilters={updateCustomerPurchaseTrackingFilter} />,
                                    filters: {...customerPurchaseTrackingFilter,},
                                    onChangeFilters: (n) => setActiveFilters(n)
                                }
                            }
                        }}
                    />
                </Box>
                <Box id="portalAnchor" />
            </Grid>

            <Grid xs={12} >
                <TXDate startDate={(customerPurchaseTrackingFilter?.startDate??"")} endDate={(customerPurchaseTrackingFilter?.endDate??"")} />
            </Grid>

            <Grid xs={12} >
                <SuperDataGrid
                    autoHeight={false}
                    quickListPortalId="portalAnchor"
                    onQuickList={quickList}
                    downloadData
                    portalId="downloadXMLDataGrid"
                    useSearchBar={false}
                    columns={clGeneralCustomerHistory}
                    rows={lsGeneralCustomerHistory}
                    columnVisibilityModel={{
                        [(groupClients?.value??"")]: false,
                        daysOfAbsence: !Boolean((groupClients?.value??"")),
                    }}
                />
                <SalesGraph salesGraph={customerSalesGraph} open={Boolean(customerSalesGraph)} onClose={()=>updateCustomerSalesChart()} />
            </Grid>

            { (dataGrid === "customer_purchase_history") &&
                <CustomerPurchaseHistoryPopUp open={(dataGrid === "customer_purchase_history")} onClose={() => toggleInformationTables("general_customer_history", {})} >
                    <SuperDataGrid
                        useSearchBar={false}
                        replaceDataGrid={(ls) => (
                            <CustomerWatchList
                                list={ls}
                                dates={columnsDate}
                                onShowCustomerProductPurchaseHistory={({ _id, name }) => handleShowCustomerProductPurchaseHistory({ customerId: _id, startDate, endDate, customerName: name })}
                                onEditCustomer={({ _id }) => updateSelectedClient(_id)}
                                onShowCustomerPurchaseInvoices={(invoices) => updateCustomerInvoices(invoices)}
                            />
                        )}
                        downloadData
                        portalId="downloadXMLDataGrid"
                        columns={clCustomerPurchaseHistory}
                        rows={lsCustomerPurchaseHistory}
                        filterBy={(params) => `${(params?.customer?.name??"VARIOS")} ${(params?.seller?.name??"")} ${(params?.consecutive??"")} ${(params?.total??0)} ${t((params?.typePayment??""))} ${getDateFormats((params?.createdAt??"")).mmddyyyyhhmm}`}
                    />
                </CustomerPurchaseHistoryPopUp>
            }

            <HistoryOfSellingProductsToCustomers
                titleComplement={(filterACustomerPurchaseHistory?.customerName??"")}
                open={showCustomerPurchaseHistoryWindow}
                filters={filterACustomerPurchaseHistory}
                onClose={() => handleShowCustomerProductPurchaseHistory({})}
            />
            <CustomerInvoices
                invoices={customerInvoices}
                open={(customerInvoices.length >= 1)}
                onClose={() => updateCustomerInvoices([])}
                onSelectedInvoice={(invoiceId) => updateSelectedInvoice(invoiceId)}
            />
            <DialogInvoice
                open={Boolean(selectedInvoice)}
                saleRef={selectedInvoice}
                onClose={() => updateSelectedInvoice(null)}
            />
            <CustomerForm
                open={Boolean(selectedClient)}
                onClose={() => updateSelectedClient(null)}
                defaultValues={customer}
            />
        </Grid>
    )
}

export default CustomerTracking