import Autocomplete from "@component/Autocomplete"
import NumericFormat from "@component/NumericFormat"
import { Box, Grid, Stack } from "@component/UIComponents"
import { useGetCreditNotesQuery } from "@query/Sales"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useMemo } from "react"
import { usePaymentCreditNote } from "./hooks/usePaymentCreditNote"

const CreditNote = ({ CustomerID=null, AmountToPay=0, onChange=()=>null }) => {
    const { data } = useGetCreditNotesQuery({ CustomerID }, { refetchOnMountOrArgChange: true, skip: !Boolean(CustomerID) })
    const CustomerCreditNotes = useMemo(() => (data?.payload?.[0]?.results??[]), [data])
    const { creditNotePayment, SelectCreditNote, UpdateAmountToPay } = usePaymentCreditNote({ AmountToPay, onChange })
    const { _CreditNote, amount } = (creditNotePayment??{})

    return(
        <Grid container spacing={1} >
            <Grid xs={6} >
                <Autocomplete
                    fullWidth
                    label="Nota crédito"
                    options={CustomerCreditNotes}
                    size="small"
                    value={_CreditNote}
                    onChange={(_, option) => SelectCreditNote(option)}
                    getOptionLabel={(option) => `#FAC ${option?.invoiceId?.consecutive??0} - ${currencyFormat((option?.total??0))}` }
                    renderOption={(props, option) => {
                        const { invoiceId="", balance=0, _id=null, createdAt=null } = (option ?? {})
                        return (
                            <Box key={_id} {...props} onMouseDown={(e) => e.preventDefault()} >
                                <Stack sx={{
                                    fontSize: ".875rem",
                                    fontWeight: "bold",
                                    lineHeight: "1.5",
                                    width: '100%'
                                }} >
                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                        <Box>
                                            #FAC {(invoiceId?.consecutive??0)}
                                        </Box>
                                        <Box>
                                            { currencyFormat((balance??0)) }
                                        </Box>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} >
                                        {/* <Box>
                                            {(user?.name??"")} {(user?.lastName??"")}
                                        </Box> */}
                                        <Box>
                                            { dayjs((createdAt??null)).format("DD-MM-YYYY HH:mm A") }
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    }}
                />
            </Grid>
            <Grid xs={6} >
                <NumericFormat
                    automaticErase
                    maxVal={AmountToPay}
                    disabled={!Boolean((_CreditNote?._id))}
                    fullWidth
                    label="Monto"
                    size="small"
                    value={amount}
                    onChange={({ floatValue }) => UpdateAmountToPay( (floatValue??0) )}
                />
            </Grid>
        </Grid>
    )
}

export default CreditNote