import DateFilter from "@component/Date"
import QuickAccessDates from "@component/QuickAccessDates"
import { Grid } from "@component/UIComponents"
import CitiesFinder from "@feature/Business/components/CitiesFinder"
import BusinessTypeFinder from "@feature/Customers/components/BusinessTypeFinder"
import CustomerFinder from "@feature/Customers/components/CustomerFinder"
import CustomerTypeFinder from "@feature/Customers/components/CustomerTypeFinder"
import ZoneFinder from "@feature/Customers/components/ZoneFinder"
import dayjs from "dayjs"
import { useCallback } from "react"
import GroupClients from "./GroupClients"

const Filters = ({ dataGrid=null, startDate, endDate, customer=[], customerType=[], businessType=[], zone=[], city=[], groupClients=null, setFilters=()=>null }) => {
    const arrayToString = useCallback((val=[]) => val.map((n) => (n?._id??"")).join(","), [])

    return(
        <Grid container spacing={1} >
            <Grid xs={12} >
                <QuickAccessDates
                    startDate={dayjs(startDate)}
                    endDate={dayjs(endDate)}
                    onChange={(date) => {
                        setFilters("startDate", (date?.startDate??""))
                        setFilters("endDate", (date?.endDate??""))
                    }}
                />
            </Grid>
            <Grid xs={12} >
                <DateFilter
                    size='small'
                    startDate={dayjs(startDate)}
                    endDate={dayjs(endDate)}
                    onChange={(date) => {
                        if( Boolean(date?.startDate??"") ) setFilters("startDate", (date?.startDate??""))
                        if( Boolean(date?.endDate??"") ) setFilters("endDate", (date?.endDate??""))
                    }}
                />
            </Grid>
            { dataGrid === "general_customer_history" &&
                <>
                    <Grid xs={12} >
                        <CustomerFinder
                            size="small"
                            multiple
                            queryValue={customer}
                            onChange={(_, params) => setFilters("customer", arrayToString((params??[])))}
                            compare={(param) => (param?._id??"")}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <CustomerTypeFinder
                            size="small"
                            multiple
                            queryValue={ customerType }
                            onChange={(_, params) => setFilters("customerType", arrayToString((params??[])))}
                            compare={(param) => (param?._id??"")}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <BusinessTypeFinder
                            size={"small"}
                            multiple
                            queryValue={ businessType }
                            onChange={(_, params) => setFilters("businessType", arrayToString((params??[])))}
                            compare={(param) => (param?._id??"")}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <ZoneFinder
                            size="small"
                            multiple
                            queryValue={ zone }
                            onChange={(_, params) => setFilters("zone", arrayToString((params??[])))}
                            compare={(param) => (param?._id??"")}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <CitiesFinder
                            size="small"
                            multiple
                            queryValue={ city }
                            onChange={(_, params) => setFilters("city", arrayToString((params??[])))}
                            compare={(param) => (param?._id??"")}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <GroupClients
                            size="small"
                            value={ groupClients }
                            onChange={(_, params) => setFilters("groupClients", (params??null))}
                        />
                    </Grid>
                </>
            }
        </Grid>
    )
}

export default Filters