import { useCallback, useState } from "react"

export const useMassiveProductEditionController = () => {
    const [componentProps, setComponentProps] = useState({
        selectedCell: "",
        selectedProduct: null,
        editedProducts: [],
        confirmProductChanges: false,
    })

    const { editedProducts } = componentProps

    const handleCellClick = useCallback((field="", value=null) => {
        let p = null
        const currentEditedProducts = [...editedProducts]
        const existIndex = currentEditedProducts.findIndex((n) => ((n?._id??"") === (value?._id??"")))

        if( existIndex >= 0 ){
            p = currentEditedProducts[existIndex]
        }else{
            p = value
        }

        return setComponentProps((prevState) => {
            return {
                ...prevState,
                selectedCell: field,
                selectedProduct: p,
            }
        })

    }, [editedProducts])

    const uploadEditedProduct = useCallback((product=null) => {
        const currentEditedProducts = [...editedProducts]
        const existIndex = currentEditedProducts.findIndex((n) => ((n?._id??"") === (product?._id??"")))
        if( existIndex >= 0 ){
            currentEditedProducts[existIndex] = product
        }else{
            currentEditedProducts.push(product)
        }
        setComponentProps((prevState) => {
            return {
                ...prevState,
                editedProducts: currentEditedProducts,
            }
        })
    }, [editedProducts])

    const onConfirmProductChanges = useCallback((val=false) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                confirmProductChanges: val
            }
        })
    }, [])

    const removeEditedProduct = useCallback((product) => {
        const currentEditedProducts = [...editedProducts]
        const existIndex = currentEditedProducts.findIndex((n) => ((n?._id??"") === (product?._id??"")))

        if( existIndex >= 0 ){
            currentEditedProducts.splice(existIndex, 1)
        }
        setComponentProps((prevState) => {
            return {
                ...prevState,
                editedProducts: currentEditedProducts,
            }
        })
    }, [editedProducts])

    return {
        componentProps,
        handleCellClick,
        uploadEditedProduct,
        onConfirmProductChanges,
        removeEditedProduct,
    }
}