import { Badge, Button } from "@component/UIComponents"
import { Fragment } from "react"

const ButtonVariant = ({ options=[], sxButton={}, setPopoverProps=()=>null }) => {
    return(
        <>
            {options?.map((item, index) => {
                const { button=null, badge=0, buttonProps={}, icon=undefined, title="", onClick=()=>null, onPopover=undefined } = (item??{})
                return(
                    <Fragment key={index} >
                        {Boolean(button) ?
                            (button??<></>)
                            :
                            <Badge badgeContent={(badge??0)} color="primary" >
                                <Button sx={{...sxButton}} startIcon={icon} onClick={(event) => Boolean(onPopover) ? onPopover(event, setPopoverProps) :  onClick(event)} {...(buttonProps??{})} >
                                    {title}
                                </Button>
                            </Badge>
                        }
                    </Fragment>
                )
            })}
        </>
    )
}

export default ButtonVariant