import { Box, useMediaQuery, useTheme } from '@component/UIComponents';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Main from './components/Main';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { SocketProvider } from '@component/SocketContext';
import { routes } from '@util/routes';
const { sales } = routes

const drawerWidth = 240;

const Dashboard = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [open, setOpen] = useState( !isMobile );
    const toggleDrawer = () => setOpen(!open);
    const keyboardShortcuts = useCallback((event) => {
        const { code } = event
        if( code === "F1" ){
            event.preventDefault()
            event.stopPropagation()
            navigate(sales({ saleId: "" }))
        }
    }, [navigate])

    useEffect(() => {
        window.addEventListener("keydown", keyboardShortcuts)
        return () => {
            window.removeEventListener("keydown", keyboardShortcuts)
        }
    }, [keyboardShortcuts])

    return (
        <Box sx={{ display: 'flex' }}>
            <SocketProvider>
                <AppBar open={open} drawerWidth={drawerWidth} toggleDrawer={toggleDrawer} />
                <Drawer open={open} drawerWidth={drawerWidth} toggleDrawer={toggleDrawer} setOpen={setOpen} />
                <Main>
                    <Outlet />
                </Main>
            </SocketProvider>
        </Box>
    );
}

export default Dashboard