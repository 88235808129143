import Autocomplete from '@component/Autocomplete'
import { Button, Dialog, DialogActions, DialogContent } from '@component/UIComponents'
import { useGetCustomers } from '@feature/Customers/hooks/useCustomers'

const AddClientMobile = ({ onAddCustomer=()=>null, onCreateCustomer=()=>null, open=false, onClose=()=>null }) => {
    const { customers, isFetching } = useGetCustomers({ variant: 'pos' })

    return(
        <Dialog
            fullWidth
            PaperProps={{
                elevation: 0,
                sx: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 'auto',
                },
            }}
            transitionDuration={0}
            open={open}
            onClose={onClose}
         >
            <DialogContent>
                <Autocomplete
                    onChange={(event, params) => onAddCustomer((params??null))}
                    loading={isFetching}
                    options={customers}
                    value={null}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                        },
                        '& .MuiInputBase-input': {
                            fontWeight: '700'
                        },
                        '& .MuiAutocomplete-popper': {

                        }
                    }}
                    placeholder={"Buscar cliente..."}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCreateCustomer} >o crea un cliente +</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddClientMobile