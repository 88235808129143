import dayjs from 'dayjs';
import { useCallback } from 'react'
import * as XLSX from 'xlsx'

export const useExportData = () => {
    const timestamp = useCallback(() => `${dayjs().format("DDMMYYYY")}-${dayjs().unix()}` ,[])

    const createWorksheet = useCallback((rows) => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        return worksheet
    }, [])
    
    const createWorkBook = useCallback((worksheet=null, sheetName="") => {
        const workbook = XLSX.utils.book_new();
        sheetName = Boolean(sheetName) && sheetName.trim() !== "" ? sheetName : timestamp()
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        return workbook
    }, [timestamp])

    const onExport = useCallback(({ rows=[], sheetName="", headerNames=[], bookName="" }) => {
        const worksheet = createWorksheet(rows);
        if( Array.isArray(headerNames) && headerNames.length >= 1 ) XLSX.utils.sheet_add_aoa(worksheet, [headerNames], { origin: "A1" });
        const workbook= createWorkBook(worksheet, sheetName)
        bookName = (Boolean(bookName) && bookName.trim() !== "") ? bookName : timestamp()
        XLSX.writeFile(workbook, `${bookName}.xlsx`, { compression: true });
    }, [createWorksheet, createWorkBook, timestamp])

    return {
        onExport
    }
}