import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useMemo } from "react"
import { useArchiveEmployee, useEmployeesMenu, useGetEmployees } from "./hooks/useEmployees"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import EmployeeForm from "./components/EmployeeForm"
import { useState } from "react"
import EmployeeSettings from "./components/EmployeeSettings"
import ComponentTitle from "@component/ComponentTitle"

const Employees = () => {
    const [adjustUserSettings, setAdjustUserSettings] = useState(null)
    const [editEmployee, setEditEmployee] = useState(null)
    const [showEmployeeForm, setShowEmployeeForm] = useState(false)
    const { getDateFormats } = usePanel()
    const { employees, isFetching } = useGetEmployees()
    const { primaryMenu, secondaryMenu } = useEmployeesMenu()
    const { archiveEmployee } = useArchiveEmployee()

    const Buttons = (props) => <ActionsList
        title={`${(props?.name??"")} ${(props?.lastName??"")}`}
        variant="mobileMenu"
        options={primaryMenu(props, {
            onEditEmployee: (employeeId) => setEditEmployee(employeeId),
            onArchivedEmployee: (archived) => archiveEmployee(archived),
            onEmployeeSettings: (employeeId) => setAdjustUserSettings(employeeId)
        })}
    />
    const handleCloseEmployeeForm = () => {
        setEditEmployee(null)
        setShowEmployeeForm(false)
    }
    const columns = useMemo(() => {
        return [
            {
                flex: 1,
                field: "name",
                headerName: "Nombres",
                renderCell: ({row}) => {
                    return(
                        <>
                            <Buttons {...row} />
                            {`${(row?.name??"")} ${(row?.lastName??"")}`}
                        </>
                    )
                }
            },
            {maxWidth: 300, minWidth: 200, field: "email", headerName: "Correo electrónico", renderCell: ({row}) => `${(row?.email??"")}`},
            {maxWidth: 200, minWidth: 150, field: "numberPhone", headerName: "Teléfono", renderCell: ({row}) => `${(row?.numberPhone??"")}`},
            {maxWidth: 150, minWidth: 100, field: "status", headerName: "Estado", renderCell: ({row}) => `${(row?.status??"") === "active" ? "Activo" : "Bloqueado"}`},
            {maxWidth: 160, minWidth: 160, field: "lastConnection", headerName: "Ultima conexión", renderCell: ({row}) => `${ getDateFormats((row?.lastConnection??"")).ddmmyyyyhhmm }`},
            {maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({row}) => `${ getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm }`},
        ]
    }, [getDateFormats])

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Equipo" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList options={secondaryMenu({
                        onCreateEmployee: () => setShowEmployeeForm(true)
                    })} />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        loading={isFetching}
                        columns={columns}
                        rows={employees}
                    />
                </Grid>
            </Grid>
            <EmployeeForm open={Boolean(editEmployee) || showEmployeeForm} onClose={handleCloseEmployeeForm} employeeId={editEmployee} />
            <EmployeeSettings open={Boolean(adjustUserSettings)} onClose={() => setAdjustUserSettings(null)} employeeId={adjustUserSettings} />
        </>
    )
}

export default Employees