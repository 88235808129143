import { useGetCustomerTypesQuery } from "@query/Customers"
import { useMemo } from "react"

export const useGetCustomerTypes = () => {
    const { data, isLoading, isFetching } = useGetCustomerTypesQuery()
    const customerTypes = useMemo(() => (data?.payload??[]), [data])
    return {
        customerTypes,
        isLoadingGetCustomerTypes: isLoading,
        isFetchingGetCustomerTypes: isFetching,
    }
}