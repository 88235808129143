import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useRegisterCustomerMutation } from "@query/Customers"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useCustomerFormData = (config={}) => {
    const { defaultValues=undefined } = config
    const initialValues = useMemo(() => ({ email: "", address: "", businessCode: "", branch: null, businessName: "", businessType: null, city: null, identificationNumber: "", name: "", neighborhood: null, phoneNumber: null, zone: null, customerType: null, daysOfAbsence: 0, purchasePeriod: "" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del cliente es un campo obligatorio."),
        }
    })

    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterCustomer = () => {
    const [ registerCustomerMutation, {isLoading} ] = useRegisterCustomerMutation()

    const registerCustomer = async (payload={}, callback={}) => {
        if( isLoading === false ){
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await registerCustomerMutation(payload).unwrap()
            asynchronousResponseHandler(resp, {
                successMessage: Boolean(payload?._id) ?
                    "El cliente se actualizo exitosamente."
                    :
                    "El cliente se registro exitosamente.",
                clearForm: clearForm(),
                closeForm: closeForm((resp?.payload??null)),
            })
        }
    }

    return {
        registerCustomer,
        isLoading,
    }
}