import LoadProgress from '@component/LoadProgress'
import { useNewUserAndBusinessRegistration } from '../hooks/useNewUserAndBusinessRegistration'
import { useCallback, useEffect } from 'react'
import { Box, Button, Grid } from '@component/UIComponents'
import { routes } from '@util/routes'
import { getAppDomain } from '@util/helpers'
const { sales } = routes

const EndingRegistration = ({ payload=null, onGoBack=()=>null, clearData=()=>null }) => {
    const { userName, newUserAndBusinessRegistration, isError } = useNewUserAndBusinessRegistration()

    const registerData = useCallback(() => {
        if( Boolean(payload?.user) && Boolean(payload?.business) && !Boolean(userName) ){
            newUserAndBusinessRegistration(payload, {
                clearForm: () => clearData()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload])

    useEffect(() => {
        registerData()
    }, [registerData])

    const GoBackButton = () => {
        if(isError){
            return (
                <Grid container >
                    <Grid xs={12} >
                        <Button onClick={onGoBack} > Regresar al formulario </Button>
                    </Grid>
                </Grid>
            )
        }else{
            return <></>
        }
    }

    const forceNavigate = (path) => {
        const domain = getAppDomain()
        window.location.href = `${domain}${path}`
    }

    useEffect(() => {
        const elem = document.getElementsByClassName("MuiStepper-root")
        if( Boolean(elem[0]) ){
            elem[0].style.display = "none"
        }
    }, [])

    if( Boolean(userName) ){
        return(
            <>
                <Box fontSize={'3rem'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} lineHeight={1} mb={3} >
                    ¡Listo!
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box>
                        <Button size='large' fullWidth variant='contained' disableElevation onClick={() => forceNavigate(sales({ saleId: "" }))} >
                            realizar mi primera venta
                        </Button>
                    </Box>
                </Box>
            </>
        )
    }else{
        return(
            <>
                <LoadProgress
                    text='Finalizando el registro...'
                />
                <GoBackButton />
            </>
        )
    }
}

export default EndingRegistration