import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useSellerReportController, useSellerReportDataGridController } from "./hooks/useSellerReportController"
import ActionsList from "@component/ActionsList"
import Filters from "./components/Filters"
import { useSellerReportOptions } from "./hooks/useSellerReportOptions"
import DialogInvoice from "../Invoice/components/DialogInvoice"
import ComponentTitle from "@component/ComponentTitle"

const Sellers = () => {
    const { salesBySellers, componentProps, uploadListWithSales, updateFilters, updateActiveFilters, updateSelectedInvoice } = useSellerReportController()
    const { sales, dataGrid, filtersGetSale, selectedInvoice } = componentProps
    const { columns, quickList } = useSellerReportDataGridController({dataGrid})
    const { primaryMenu } = useSellerReportOptions({ dataGrid })

    return(
        <>
        <ComponentTitle activeNavigatorTabTitle={true} title="Vendedores" />
        <Grid container >
            <Grid xs={12} >
                <ActionsList
                    options={primaryMenu({
                        goBack: () => uploadListWithSales([])
                    })}
                    componentProps={{
                        filter: {
                            active: true,
                            props: {
                                filters: filtersGetSale,
                                component: () => <Filters {...filtersGetSale} setFiltersData={updateFilters} />,
                                onChangeFilters: (activeFilters) => updateActiveFilters(activeFilters)
                            }
                        },
                        portal: { active: true }
                    }}
                />
            </Grid>
            { (dataGrid === "seller_list") &&
                <Grid xs={12} >
                    <SuperDataGrid
                        useSearchBar={false}
                        columns={columns({
                            onSellerClick: (row) => uploadListWithSales((row?.results??[]))
                        })}
                        rows={salesBySellers}
                        onQuickList={quickList}
                    />
                </Grid>
            }
            { (dataGrid === "sales_list") &&
                <Grid xs={12} >
                    <SuperDataGrid
                        useSearchBar={false}
                        columns={columns({
                            onViewInvoice: (invoiceId) => updateSelectedInvoice(invoiceId)
                        })}
                        rows={sales}
                        onQuickList={quickList}
                    />
                </Grid>
            }

            <DialogInvoice
                open={Boolean(selectedInvoice)}
                saleRef={selectedInvoice}
                onClose={() => updateSelectedInvoice()}
            />
        </Grid>
        </>
    )
}

export default Sellers