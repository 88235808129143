import { useGetClosingInventorySummaryQuery } from "@query/Products"
import { useCallback, useMemo, useState } from "react"

export const useClosingInventory = () => {
    const [filters, setFilters] = useState({})
    const { InitialDate, FinalDate } = (filters??{})
    const { data, isFetching } = useGetClosingInventorySummaryQuery({ InitialDate, FinalDate })
    const summary = useMemo(() => (data?.payload??[]), [data])

    const WaitingForInventoryClosing = useMemo(() => isFetching, [isFetching])

    const updateFilters = useCallback((filters={}) => {
        setFilters(filters)
    }, [])

    return {
        summary,
        updateFilters,
        WaitingForInventoryClosing,
    }
}