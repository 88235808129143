import { Checkbox, FormControlLabel } from "@mui/material"
import { db } from "@util/db"
import { useLiveQuery } from "dexie-react-hooks"
import { useCallback } from "react"

const ToggleInvoice = () => {
    let { temporaryInvoice=false } = useLiveQuery(() => db.tmp.get(1)) || {}
    const toggleCheck = useCallback( async (val) => {
        try {
            await db.tmp.put({ id: 1, temporaryInvoice: val, })
        } catch (error) {
            console.log(error)
        }
    }, [])

    return(
        <>
            <FormControlLabel
                control={<Checkbox checked={temporaryInvoice} onChange={({ target }) => toggleCheck(target.checked)} />}
                label="Factura temporal"
            />
        </>
    )
}

export default ToggleInvoice