import { api, mutationConfig } from "../splitApi";
import { BoxTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        updateUserInformation: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/user/information", body };
            },
            // invalidatesTags: ProviderTag
        }),
        getUserSessionInformation: build.query({
            query: (params=undefined) => ({
                url: `/get/user/session/information`,
                params,
            }),
            providesTags: BoxTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetUserSessionInformationQuery,
    useUpdateUserInformationMutation
} = Endpoints;