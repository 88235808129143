import { Box } from '@component/UIComponents'
import { useMemo } from 'react'

const InvoiceInformationToEdit = ({ consecutive=0, orderNumber=0, invoiceType="", seller=null }) => {

    const InvoiceNumber = useMemo(() => {
        if(invoiceType === "order"){
            return `#P ${orderNumber}`
        }else if( invoiceType === "sale" ){
            return `#R ${consecutive}`

        }else if( invoiceType === "price" ){
            return `#C ${consecutive}`
        }else{
            return "SIN DEFINIR"
        }
    }, [invoiceType, consecutive, orderNumber,])

    return(
        <>
            <Box fontWeight={'bold'} fontSize={'1.7rem'} lineHeight={1} mb={1} p={2} borderRadius={1} sx={{ bgcolor: (theme) => theme.palette.warning.light, color: (theme) => theme.palette.grey[800] }} >
                <Box fontSize={'1rem'} mb={1} >
                    {(invoiceType === "sale") ? "Editando venta" : (invoiceType === "order") ? "Editando pedido" : (invoiceType === "price") ? "Editando cotización" : "" }
                </Box>
                {InvoiceNumber}
                <Box fontSize={'.875rem'} mt={1} >
                    Registrado por: {(seller?.name??"")} {(seller?.lastName??"")}
                </Box>
            </Box>
        </>
    )
}

export default InvoiceInformationToEdit