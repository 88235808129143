import { Box, DataGrid, DeleteIconRounded, IconButton, Stack, Typography, styled } from "@component/UIComponents"
import { useURLParams } from "@hook/useURLParams"
import { calculateProfitMargin, currencyFormat } from "@util/currencyFormat"
import { usePOS } from "../hooks/usePOS"
import { useGetUserSessionInformation } from "@feature/Users"

const DataGridStyled = styled(DataGrid)(() => ({
    border: "none",
    '& .MuiDataGrid-columnHeaders': {
        display: "none"
    }
}))

const Item = ({ quantity, salePrice, costPrice, name, productId, observation="" }) => {
    const { addQuery } = useURLParams()
    const { rolInternalName="" } = useGetUserSessionInformation()

    return(
        <>
        <Stack className="cell_dataContainer" width={'100%'} onClick={() => addQuery({ ref: productId })} >
            <Typography lineHeight={1} fontWeight={'bold'} > {name} </Typography>
            <Stack direction={'row'} width={'100%'} >
                <Stack direction={'column'} width={'100%'} >
                    <Box display={'flex'} gap={2} lineHeight={1} >
                        <Typography sx={{ color: (theme) => theme.palette.success.main, fontWeight: "bold" }} >
                            { currencyFormat(quantity, {applyCurrencyFormat: false}) } x
                        </Typography>
                        <Typography>
                            { currencyFormat(salePrice) }
                        </Typography>
                        {(rolInternalName !== "seller2") &&
                            <Typography>
                                { calculateProfitMargin(salePrice, (salePrice-costPrice)) } %
                            </Typography>
                        }
                    </Box>
                    { Boolean(observation) &&
                        <Box lineHeight={1} fontWeight={'500'} >
                            Observación:
                            <Box component={'span'} sx={{ textDecoration: "underline" }} > {observation} </Box>
                        </Box>
                    }
                </Stack>
                <Stack>
                    <Typography>
                        { currencyFormat((salePrice*quantity)) }
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}

const SaleList = ({ list=[], setFieldValue=undefined, apiRef=undefined }, ref) => {
    const { delItemList } = usePOS({ list })
    const columns = [
        {
            flex: 1,
            field: "name",
            headerName: " ",
            renderCell: ({ row }) => <Item {...row} />
        },
        {
            maxWidth: 50,
            minWidth: 50,
            align: "center",
            field: " ",
            headerName: " ",
            renderCell: ({ row }) => <>
                <IconButton color="error" onClick={() => delItemList((row?.productId??""), setFieldValue)} >
                    <DeleteIconRounded />
                </IconButton>
            </>
        },
    ]

    return(
        <Box mt={1} >
            <DataGridStyled
                apiRef={apiRef}
                rowHeight={70}
                getRowId={params => params.productId}
                localeText={{
                    noRowsLabel: ""
                }}
                autoHeight
                hideFooter
                rows={[...list].reverse()}
                columns={columns}
            />
        </Box>
    )
}

export default SaleList