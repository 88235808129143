import RightDrawer from "@component/RightDrawer"
import { Box, FilterListRoundedIcon } from "@component/UIComponents"

const Filters = ({ children }) => {
    return(
        <RightDrawer keepMounted={true} ButtonProps={{ name: "Filtros", startIcon: <FilterListRoundedIcon /> }} >
            <Box p={1.5} width={'100%'} >
                {children}
            </Box>
        </RightDrawer>
    )
}

export default Filters