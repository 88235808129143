import { createBrowserRouter, RouterProvider as Router } from "react-router-dom";
import { routes } from "@util/routes"; 
import App from "../App";
import Login from "@feature/Login";
import SingIn from "@feature/SignIn";
import RecoverPassword from "@feature/RecoverPassword";
import Inventory from "@feature/Inventory";
import Reports from "@feature/Reports";
import PurchaseReport from "@feature/PurchaseReport";
import MasterBox from "@feature/MasterBox";
import Boxes from "@feature/Boxes";
import Banks from "@feature/Banks";
import Sales from "@feature/Sales";
import EnterPurchase from "@feature/EnterPurchase";
import AccountsPayable from "@feature/AccountsPayable";
import Customers from "@feature/Customers";
import Providers from "@feature/Providers";
import Settings from "@feature/Settings";
import RolesAndPermissions from "@feature/Settings/RolesAndPermissions";
import Employees from "../features/Employees";
import ClosingOfTheDay from "@feature/ClosingOfTheDay";
import Panel from "@feature/Panel";
import Deliveries from "@feature/Deliveries";
import Hauliers from "@feature/Hauliers";
import PurchaseOrders from "@feature/PurchaseOrders";
import Expenses from "@feature/Expenses";
import SafeRedirect from "./SafeRedirect";
import RegisterFirstBusiness from "@feature/RegisterFirstBusiness";
import ReceiptOfSalesInvoices from "@feature/ReceiptOfSalesInvoices";
import Branches from "@feature/Business/components/Branches";
import Welcome from "@feature/Welcome";
import CustomerTracking from "@feature/Reports/CustomerTracking";
import BusinessInformation from "@feature/Settings/BusinessInformation";
import UserInformation from "@feature/Settings/UserInformation";
import ProductHistory from "@feature/Inventory/ProductHistory";
import InventoryTurnoverRate from "@feature/Inventory/InventoryTurnoverRate";
import SettingPointOfSale from "@feature/Settings/PointOfSale";
import CashierReport from "@feature/Cashiers/CashierReport";
const { cashierReport, settingPointOfSale, inventoryTurnoverRate, productHistory, updateBusinessInformation, updateUserInformation, customerTracking, welcome, branches, receiptOfSalesInvoices, registerFirstBusiness, expenses, ordersToSuppliers, deliveries, hauliers, closingOfTheDay, employees, roles, settings, singIn, login, home, recoverPassword, inventory, report, purchaseReport, masterBox, boxes, banks, sales, enterPurchase, accountsPayable, customers, providers } = routes

const router = createBrowserRouter([
    {
        path: home(),
        element: <SafeRedirect children={<App />} />,
        children: [
            {
                path: '/',
                element: <Panel />,
            },
            {
                path: inventory(),
                element: <Inventory />,
            },
            {
                path: report(),
                element: <Reports />,
            },
            {
                path: purchaseReport(),
                element: <PurchaseReport />,
            },
            {
                path: masterBox(),
                element: <MasterBox />,
            },
            {
                path: boxes(),
                element: <Boxes />,
            },
            {
                path: banks(),
                element: <Banks />,
            },
            {
                path: enterPurchase(),
                element: <EnterPurchase />,
            },
            {
                path: accountsPayable(),
                element: <AccountsPayable />,
            },
            {
                path: customers(),
                element: <Customers />,
            },
            {
                path: providers(),
                element: <Providers />,
            },
            {
                path: settings(),
                element: <Settings />,
                children: [
                    {
                        path: roles(),
                        element: <RolesAndPermissions />,
                    },
                    {
                        path: updateBusinessInformation(),
                        element: <BusinessInformation />,
                    },
                    {
                        path: updateUserInformation(),
                        element: <UserInformation />,
                    },
                    {
                        path: settingPointOfSale(),
                        element: <SettingPointOfSale />,
                    },
                ]
            },
            {
                path: branches(),
                element: <Branches />,
            },
            {
                path: employees(),
                element: <Employees />,
            },
            {
                path: closingOfTheDay(),
                element: <ClosingOfTheDay />,
            },
            {
                path: hauliers(),
                element: <Hauliers />,
            },
            {
                path: deliveries(),
                element: <Deliveries />,
            },
            {
                path: ordersToSuppliers(),
                element: <PurchaseOrders />,
            },
            {
                path: expenses(),
                element: <Expenses />,
            },
            {
                path: receiptOfSalesInvoices(),
                element: <ReceiptOfSalesInvoices />,
            },
            {
                path: customerTracking(),
                element: <CustomerTracking />,
            },
            {
                path: productHistory(),
                element: <ProductHistory />,
            },
            {
                path: inventoryTurnoverRate(),
                element: <InventoryTurnoverRate />,
            },
            {
                path: cashierReport(),
                element: <CashierReport />,
            },
        ]
    },
    {
        path: welcome(),
        element: <SafeRedirect children={<Welcome />} />,
    },
    {
        path: login(),
        element: <SafeRedirect children={<Login />} />,
    },
    {
        path: singIn(),
        element: <SafeRedirect children={<SingIn />} />,
    },
    {
        path: recoverPassword(),
        element: <RecoverPassword />,
    },
    {
        path: sales(),
        element: <SafeRedirect children={<Sales />} />,
    },
    {
        path: registerFirstBusiness(),
        element: <SafeRedirect children={<RegisterFirstBusiness />} />,
    },
]);

const RouterProvider = () => {
    return (
        <>
            <Router router={router} />
        </>
    )
}

export default RouterProvider