import { useCallback, useState } from "react"

export const usePaymentCreditNote = ({ AmountToPay=0, onChange=()=>null }) => {

    const [ creditNotePayment, setCreditNotePayment ] = useState({
        _CreditNote: null,
        amount: 0,
    })

    const SelectCreditNote = useCallback((creditNote=null) => {
        if( Boolean((creditNote?._id??null)) === true ){
            const { balance=0 } = (creditNote??{})
            const _balance = (balance > AmountToPay) ? AmountToPay : balance
            setCreditNotePayment((prevState) => {
                const newState = {...prevState, _CreditNote: creditNote, amount: _balance}
                onChange({ value: newState })
                return newState
            })
        }else{
            setCreditNotePayment((prevState) => {
                const newState = {...prevState, _CreditNote: null, amount: 0}
                onChange({ value: null })
                return newState
            })
        }
    }, [ AmountToPay, onChange ])

    const UpdateAmountToPay = useCallback((amount=0) => {
        setCreditNotePayment((prevState) => {
            const newState = { ...prevState, amount }
            onChange({ value: newState })
            return newState
        })
    }, [onChange])

    return {
        creditNotePayment,
        SelectCreditNote,
        UpdateAmountToPay,
    }
}